.list-item-box {
  @apply w-full;
  @apply pb-8;
  transition: transform $trns;

  .embedded-video {
    padding-bottom: 66.6666667%;
  }

  @screen sm {
    @apply w-1/2;

    &:nth-child(odd) {
      @apply pr-3;
    }
    &:nth-child(even) {
      @apply pl-3;
    }
  }

  @screen lg {
    @apply w-1/3;

    &:nth-child(n) {
      @apply pl-6 pr-0;
    }

    &:hover {
      transform: translateY(-1rem);
    }
  }

  img {
    @apply w-full;
  }

  .date {
    @apply text-sm text-gray;
  }

  h3 {
    @apply text-primary text-lg font-medium;
    @apply my-2;
  }
}

.focus-item-img-overlay {
  @apply absolute left-0 right-0 top-0 bottom-0;
  background: linear-gradient(
    90deg,
    theme('colors.primary') 40%,
    theme('colors.primary-a0') 100%
  );
}

.focus-item-img {
  @apply absolute right-0;
  @apply h-full w-3/5;
  @apply object-cover;
  left: 40%;
}

.list-container {
  @screen lg {
    @apply flex;
  }
}

.list-category-selector {
  @apply flex flex-wrap justify-center w-full;
  @apply mb-8;

  @screen lg {
    @apply block;
    width: 260px;
  }

  li {
    @apply text-primary text-center uppercase;
    @apply py-3 px-6;
    @apply w-full;
    @apply mb-4;

    @screen sm {
      @apply w-1/2;
    }

    @screen md {
      @apply w-1/3;
    }

    @screen lg {
      @apply text-left;
      @apply w-auto;
      @apply px-3 mb-0;
    }

    &.indented {
      @apply ml-10;
    }

    &.active {
      @apply font-bold;
      @apply border-b-4 border-blue;

      @screen lg {
        @apply border-l-4 border-b-0;
        @apply pl-4;
      }

      &::after {
        @apply hidden;
        content: url('../images/icons/arrow-category.svg');
        position: relative;
        top: 3px;
        left: -2px;
        width: 8px;
        height: 15px;
        float: right;

        @screen lg {
          @apply block;
        }
      }
    }
    a {
      &:hover {
        @apply underline;
      }
    }
  }
}
