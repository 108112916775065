.assembly-25 {
  .assembly-25-logo {
    width: 250px;

    @screen sm {
      width: 300px;
    }

    @screen lg {
      width: 370px;
    }
  }
  .positions-section {
    margin-top: -500px;

    @screen sm {
      margin-top: -450px;
    }

    @screen xl {
      margin-top: -500px;
    }
  }
  .candidate-bio-section {
    margin-top: -50px;

    @screen sm {
      margin-top: -100px;
    }
  }
  .bio-bg {
    background-size: 100% 700px;
    @screen md {
      background-size: 100% 900px;
    }
    background-repeat: no-repeat;
  }
}

.wjc-plenary-assembly-candidate-topics {
  ul {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    button {
      @apply w-full;
      @apply text-base text-left text-white;
      @apply px-4 py-3;
      @apply flex justify-between items-center outline-none;

      svg {
        width: 10%;
        @apply pr-3;
      }

      &:hover {
        @apply underline;
      }
    }
  }

  li {
    @apply border-l-4 border-transparent outline-none;

    svg {
      @apply opacity-0;
    }
  }

  li.active {
    @apply border-l-4 border-white outline-none;

    svg {
      @apply opacity-100;
    }

    button {
      @apply text-white font-bold no-underline;
    }
  }
}

.wjc-plenary-assembly-candidate-aside-title {
  @apply text-white w-full -top-16;
  background: #102956;
}

.wjc-plenary-assembly-candidate-link {
  @apply p-gutter-1/2;

  display: block;
  transform: translateY(0);
  transition: transform $trns, box-shadow $trns;

  &:hover {
    @apply shadow-lg-hover bg-white;
    transform: translateY(-1rem);

    p {
      @apply text-primary;
    }

    img {
      filter: grayscale(0);
    }
  }
  img {
    transition: filter $trns;
    filter: grayscale(1);
    margin-bottom: 0.5rem;
  }
}

.wjc-plenary-assembly-candidate-bio {
  @screen sm {
    min-height: 15rem;
  }
}

.has-state-fix {
  margin: 0 !important;
  padding: 0 !important;
}

.article-content.wjc-plenary-assembly-candidate-bio {
  .block-button {
    @apply flex items-center my-8;

    .btn {
      @apply rounded-7
        bg-transparent
        text-primary text-lg font-medium leading-none tracking-wide;

      display: inline-block;
      min-height: 48px;
      padding: 4px 28px;
      transition: all ease-in-out 200ms;
      border: 0;
      text-align: center;
      text-transform: uppercase;
      vertical-align: middle;
      user-select: none;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        @apply shadow-btn;
        outline: none;
      }

      &:not(:disabled) {
        cursor: pointer;
      }

      &:not(button) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    .btn-secondary {
      @apply bg-secondary text-primary;

      &:hover {
        @apply bg-secondary-hover;
      }

      &:focus {
        @apply shadow-btn-secondary;
      }
    }

    .btn-tertiary {
      @apply bg-blue text-white;

      &:hover {
        @apply bg-blue-hover;
      }

      &:focus {
        @apply shadow-btn-tertiary;
      }
    }

    .btn-outline-primary {
      @apply border border-solid border-primary bg-transparent text-primary;

      &:hover {
        @apply bg-primary text-white;
      }

      &:focus {
        @apply shadow-btn-primary;
      }
    }
  }
}
