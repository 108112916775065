@import 'swiper/css/swiper.css';
@import "lity/dist/lity.css";
@tailwind base;
@tailwind components;
.custom-swiper-container {
  position: relative;
}

.swiper-slide {
  box-sizing: border-box;
}
.swiper-slide img {
  width: 100%;
}

.swiper-nav-btn {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-radius: 50%;
  opacity: 1;
  background-image: none;
}
.swiper-nav-btn::after {
  content: none;
}
.swiper-nav-btn:focus {
  @apply shadow-btn-secondary;
  outline: none;
}
.swiper-nav-btn.swiper-button-disabled {
  pointer-events: auto;
}
.swiper-nav-btn.swiper-button-disabled:hover {
  opacity: 0.35;
}
.swiper-nav-btn.swiper-button-disabled:focus {
  box-shadow: none;
}
.swiper-nav-btn.-primary-theme:focus {
  @apply shadow-btn-primary;
}
.swiper-nav-btn.-primary-theme svg {
  @apply fill-primary;
}
.swiper-nav-btn.-big {
  width: 4rem;
  height: 4rem;
  margin-top: -2rem;
}
.swiper-nav-btn.-fixed {
  top: 112px;
}
@screen sm {
  .swiper-nav-btn.-fixed {
    top: 87px;
  }
}
@screen md {
  .swiper-nav-btn.-fixed {
    top: 115px;
  }
}
@screen lg {
  .swiper-nav-btn.-fixed {
    top: 97px;
  }
}
@screen xl {
  .swiper-nav-btn.-fixed {
    top: 90px;
  }
}

.swiper-button-prev {
  left: 12px;
}
.swiper-button-prev.-edge {
  left: -2rem;
}
@screen xxl {
  .swiper-button-prev {
    left: -72px;
  }
}
.swiper-button-prev.-white-arrows svg {
  @apply fill-white;
}

.swiper-button-next {
  right: 12px;
}
.swiper-button-next.-edge {
  right: -2rem;
}
@screen xxl {
  .swiper-button-next {
    right: -72px;
  }
}
.swiper-button-next.-white-arrows svg {
  @apply fill-white;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-bullet {
  @apply rounded bg-gray-light;
  display: block;
  width: 32px;
  height: 2px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.swiper-pagination-bullet:hover {
  @apply bg-secondary;
}
.swiper-pagination-bullet:focus {
  outline: none;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-secondary;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

.js-testimonial-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  @apply rounded bg-white border border-oval-gray;
  display: block;
  width: 8px;
  height: 8px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.js-testimonial-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  @apply bg-blue-dark;
}
.js-testimonial-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:focus {
  outline: none;
}
.js-testimonial-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-blue-dark border border-blue-dark;
}
.js-testimonial-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

.js-testimonial-durban-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
  @apply rounded-full bg-white border border-oval-gray;
  display: block;
  width: 12px;
  height: 12px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.js-testimonial-durban-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  @apply bg-blue-dark;
}
.js-testimonial-durban-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:focus {
  outline: none;
}
.js-testimonial-durban-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-blue-dark border border-blue-dark;
}
.js-testimonial-durban-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

.js-testimonial-words-elevate-swiper-container .swiper-pagination {
  justify-content: start;
  bottom: 0px;
}

.js-testimonial-words-elevate-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  border-radius: 0px;
  background-color: #c8c8d6;
  display: block;
  width: 54px;
  height: 8px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.js-testimonial-words-elevate-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  background-color: #295ba5;
}
.js-testimonial-words-elevate-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:focus {
  outline: none;
}
.js-testimonial-words-elevate-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #295ba5;
}
.js-testimonial-words-elevate-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

.swiper-news-header-display-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  @apply rounded-full bg-white border border-oval-gray;
  display: block;
  width: 16px;
  height: 16px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.swiper-news-header-display-container > .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  outline: #2b7de2 solid 4px;
}
.swiper-news-header-display-container > .swiper-pagination-bullets .swiper-pagination-bullet:focus {
  outline: none;
}
.swiper-news-header-display-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  outline: #2b7de2 solid 4px;
}
.swiper-news-header-display-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

.swiper-news-header-display-container .swiper-pagination-bullets {
  bottom: 40px !important;
}
.swiper-news-header-display-container .swiper-pagination-bullets.large-bottom-placement {
  bottom: 150px !important;
}
@screen md {
  .swiper-news-header-display-container .swiper-pagination-bullets.large-bottom-placement {
    bottom: 100px !important;
  }
}

.js-ec-zagreb-image-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
  @apply rounded-full bg-white border border-oval-gray;
  display: block;
  width: 12px;
  height: 12px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.js-ec-zagreb-image-container > .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  @apply bg-blue-dark;
}
.js-ec-zagreb-image-container > .swiper-pagination-bullets .swiper-pagination-bullet:focus {
  outline: none;
}
.js-ec-zagreb-image-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-blue-dark border border-blue-dark;
}
.js-ec-zagreb-image-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

.js-jdAcademy-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
  @apply rounded-full bg-white border border-oval-gray;
  display: block;
  width: 12px;
  height: 12px;
  transition: background-color 0.3s ease-in-out;
  opacity: 1;
}
.js-jdAcademy-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  @apply bg-blue-dark;
}
.js-jdAcademy-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet:focus {
  outline: none;
}
.js-jdAcademy-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-blue-dark border border-blue-dark;
}
.js-jdAcademy-swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:focus {
  outline: none;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir/Avenir-Book.eot");
  src: url("../fonts/avenir/Avenir-Book.woff2") format("woff2"), url("../fonts/avenir/Avenir-Book.woff") format("woff"), url("../fonts/avenir/Avenir-Book.ttf") format("truetype"), url("../fonts/avenir/Avenir-Book.svg#Avenir-Book") format("svg"), url("../fonts/avenir/Avenir-Book.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir/Avenir-Roman.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir/Avenir-Medium.eot");
  src: url("../fonts/avenir/Avenir-Medium.woff2") format("woff2"), url("../fonts/avenir/Avenir-Medium.woff") format("woff"), url("../fonts/avenir/Avenir-Medium.ttf") format("truetype"), url("../fonts/avenir/Avenir-Medium.svg#Avenir-Medium") format("svg"), url("../fonts/avenir/Avenir-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir/Avenir-Heavy.eot");
  src: url("../fonts/avenir/Avenir-Heavy.woff2") format("woff2"), url("../fonts/avenir/Avenir-Heavy.woff") format("woff"), url("../fonts/avenir/Avenir-Heavy.ttf") format("truetype"), url("../fonts/avenir/Avenir-Heavy.svg#Avenir-Heavy") format("svg"), url("../fonts/avenir/Avenir-Heavy.eot?#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir/Avenir-Black.otf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "AtlasProAAA";
  src: url("../fonts/atlasPro/AtlasProAAA-Regular.eot");
  src: url("../fonts/atlasPro/AtlasProAAA-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/atlasPro/AtlasProAAA-Regular.svg#AtlasProAAA-Regular") format("svg"), url("../fonts/atlasPro/AtlasProAAA-Regular.ttf") format("truetype"), url("../fonts/atlasPro/AtlasProAAA-Regular.woff") format("woff"), url("../fonts/atlasPro/AtlasProAAA-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/suisse-works/SuisseWorks-Medium.otf");
  src: url("../fonts/suisse-works/SuisseWorks-Medium.ttf") format("truetype");
  font-weight: 450;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/suisse-works/SuisseWorks-Bold.otf");
  src: url("../fonts/suisse-works/SuisseWorks-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/suisse-works/SuisseWorks-Regular.otf");
  src: url("../fonts/suisse-works/SuisseWorks-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/suisse-intl/SuisseIntl-Regular-WebM.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/suisse-intl/SuisseIntl-Medium-WebM.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/suisse-intl/SuisseIntl-SemiBold-WebM.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/suisse-intl/SuisseIntl-Bold-WebM.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Fondamento";
  src: url("../fonts/fondamento/Fondamento-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  @apply text-2xl;
}
@screen sm {
  h1 {
    @apply text-3xl;
  }
}
@screen lg {
  h1 {
    @apply text-4xl;
  }
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-xl;
}
@screen sm {
  h3 {
    @apply text-2xl;
  }
}

h4 {
  @apply text-lg;
}
@screen sm {
  h4 {
    @apply text-xl;
  }
}

h5 {
  @apply text-lg;
}

h6 {
  @apply text-sm;
}

b,
strong {
  @apply font-bold;
}

body {
  @apply bg-white font-primary text-primary text-base font-normal leading-normal;
}
@screen sm {
  body {
    @apply text-lg;
  }
}

.app-wrapper-countdown {
  padding-top: 64px;
}
@screen sm {
  .app-wrapper-countdown {
    padding-top: 50px;
  }
}
@screen md {
  .app-wrapper-countdown {
    padding-top: 100px;
  }
}
.app-wrapper-countdown .header {
  top: 64px;
}
@screen sm {
  .app-wrapper-countdown .header {
    top: 50px;
  }
}
@screen md {
  .app-wrapper-countdown .header {
    top: 100px;
  }
}
.app-wrapper-countdown .navigation {
  top: 195px;
  height: calc(100% - 195px);
}
@screen sm {
  .app-wrapper-countdown .navigation {
    top: 146px;
    height: calc(100% - 146px);
  }
}
@screen md {
  .app-wrapper-countdown .navigation {
    top: 222px;
    height: calc(100% - 222px);
  }
}
@screen lg {
  .app-wrapper-countdown .navigation {
    top: auto;
    height: auto;
  }
}
.app-wrapper-countdown .inner-hero-img {
  top: 64px;
}
@screen sm {
  .app-wrapper-countdown .inner-hero-img {
    top: 50px;
  }
}
@screen md {
  .app-wrapper-countdown .inner-hero-img {
    top: 100px;
  }
}
@screen lg {
  .app-wrapper-countdown .issues-section {
    min-height: calc(100vh - 100px);
  }
}
.app-wrapper-countdown .page-not-found {
  min-height: calc(100vh - 64px);
}
@screen sm {
  .app-wrapper-countdown .page-not-found {
    min-height: calc(100vh - 50px);
  }
}
@screen md {
  .app-wrapper-countdown .page-not-found {
    min-height: calc(100vh - 100px);
  }
}

img {
  vertical-align: top;
}

a,
button {
  -webkit-tap-highlight-color: theme("colors.black-a0");
}

.container {
  @apply px-gutter-1/2;
}
@screen md {
  .container {
    @apply px-gutter;
  }
}
@screen xxl {
  .container {
    max-width: 1200px;
  }
}

.container-half {
  @apply mx-auto px-gutter-1/2;
  width: 100%;
}
@screen sm {
  .container-half {
    max-width: theme("screens.sm");
  }
}
@screen md {
  .container-half {
    @apply px-gutter;
    max-width: theme("screens.md");
  }
}
@screen lg {
  .container-half {
    @apply mx-0;
    max-width: 496px;
  }
}
@screen xl {
  .container-half {
    max-width: 600px;
  }
}
@screen lg {
  .container-half.-left {
    align-self: flex-end;
    padding-right: 0;
  }
}
@screen lg {
  .container-half.-right {
    padding-left: 0;
  }
}

.triangle-bg {
  position: relative;
  overflow: hidden;
}
.triangle-bg::before, .triangle-bg::after {
  content: "";
  position: absolute;
  padding-top: 65%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@screen md {
  .triangle-bg.-square::before, .triangle-bg.-square::after {
    padding-top: 100%;
  }
}
.triangle-bg > .container {
  position: relative;
  z-index: 1;
}

.triangle-bg-top-right::before {
  top: 0;
  right: 0;
  left: 50%;
  background-image: linear-gradient(to bottom left, theme("colors.gray-300") 50%, theme("colors.transparent") 50%);
}
@screen md {
  .triangle-bg-top-right.-square::before {
    left: 0;
  }
}
.triangle-bg-top-right.-blue::before {
  background-image: linear-gradient(to bottom left, theme("colors.blue-a6") 50%, theme("colors.transparent") 50%);
}

.triangle-bg-top-left::before {
  top: 0;
  right: 50%;
  left: 0;
  background-image: linear-gradient(to bottom right, theme("colors.gray-300") 50%, theme("colors.transparent") 50%);
}
@screen md {
  .triangle-bg-top-left.-square::before {
    right: 0;
  }
}
.triangle-bg-top-left.-top-left-alpha::before {
  background-image: linear-gradient(to bottom right, theme("colors.gray-300-a1") 50%, theme("colors.transparent") 50%);
}

.triangle-bg-bottom-left::after {
  right: 50%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top right, theme("colors.gray-300") 50%, theme("colors.transparent") 50%);
}
@screen md {
  .triangle-bg-bottom-left.-square::after {
    right: 0;
  }
}

.triangle-bg-bottom-right::after {
  right: 0;
  bottom: 0;
  left: 50%;
  background-image: linear-gradient(to top left, theme("colors.gray-300") 50%, theme("colors.transparent") 50%);
}
@screen md {
  .triangle-bg-bottom-right.-square::after {
    left: 0;
  }
}
.triangle-bg-bottom-right.-bottom-right-alpha::after {
  background-image: linear-gradient(to top left, theme("colors.gray-300-a1") 50%, theme("colors.transparent") 50%);
}

.section-title {
  @apply text-2xl;
  text-align: center;
}
@screen md {
  .section-title {
    @apply text-3xl;
  }
}
@screen lg {
  .section-title {
    @apply text-4xl;
  }
}

.line-around {
  display: flex;
}
.line-around.aos-init span:first-child::before, .line-around.aos-init span:last-child::before {
  width: auto;
}
.line-around.aos-init span:first-child::before {
  right: 0;
  left: 100%;
}
.line-around.aos-init span:last-child::before {
  right: 100%;
  left: 0;
}
.line-around.aos-animate span:first-child::before {
  left: 0;
}
.line-around.aos-animate span:last-child::before {
  right: 0;
}
.line-around span:first-child, .line-around span:last-child {
  display: block;
  position: relative;
  flex: 1;
}
.line-around span:first-child::before, .line-around span:last-child::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  transition: right 1.5s ease, left 1.5s ease;
  background-color: currentColor;
}
.line-around span:first-child {
  margin-right: 1rem;
}
@screen sm {
  .line-around span:first-child {
    margin-right: 1.25rem;
  }
}
.line-around span:last-child {
  margin-left: 1rem;
}
@screen sm {
  .line-around span:last-child {
    margin-left: 1.25rem;
  }
}

.close-icon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  transition: opacity 0.3s ease-in-out;
  outline: 0;
  opacity: 1;
}
@screen md {
  .close-icon {
    width: 20px;
    height: 20px;
  }
}
.close-icon:hover {
  @apply opacity-icon;
}
.close-icon:focus {
  outline: none;
}
.close-icon::before, .close-icon::after {
  @apply bg-white;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 1px;
  margin-top: -0.5px;
  margin-left: -11px;
}
@screen md {
  .close-icon::before, .close-icon::after {
    width: 27px;
    margin-left: -13.5px;
  }
}
.close-icon::before {
  transform: rotate(45deg);
}
.close-icon::after {
  transform: rotate(-45deg);
}

.icon-hover {
  display: inline-block;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.icon-hover:hover {
  @apply opacity-icon;
}
.icon-hover.reverse {
  @apply opacity-icon;
}
.icon-hover.reverse:hover {
  opacity: 1;
}

.padding-hack {
  position: relative;
  overflow: hidden;
}
.padding-hack::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.padding-hack img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

[v-cloak] {
  display: none !important;
}

.page-not-found {
  display: flex;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

.page-content-wrapper {
  @apply bg-white;
  position: relative;
  z-index: 1;
}
.page-content-wrapper.no-header {
  padding-top: 56px;
}
@screen sm {
  .page-content-wrapper.no-header {
    padding-top: 72px;
  }
}
@screen md {
  .page-content-wrapper.no-header {
    padding-top: 90px;
  }
}
@screen lg {
  .page-content-wrapper.no-header {
    padding-top: 72px;
  }
}
@screen xl {
  .page-content-wrapper.no-header {
    padding-top: 90px;
  }
}

.accordion-item {
  @apply border-b border-gray-light;
}
.accordion-item.active .accordion-item-title::before {
  transform: rotate(180deg);
}
.accordion-item.active .accordion-item-content {
  max-height: 500px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-y: auto;
}

.accordion-item-title {
  @apply text-base;
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  cursor: pointer;
}
@screen md {
  .accordion-item-title {
    @apply text-xl;
    padding: 1rem 1.5rem;
  }
}
@screen lg {
  .accordion-item-title {
    @apply text-2xl;
    padding: 2rem 3rem;
  }
}
.accordion-item-title::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  transition: transform 0.3s ease-in-out;
  background-image: url("../images/icons/arrow-collapse.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
@screen md {
  .accordion-item-title::before {
    margin-right: 1.75rem;
  }
}
.accordion-item-title > div::first-letter {
  text-transform: uppercase;
}

.accordion-item-content {
  @apply text-base;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}
@screen md {
  .accordion-item-content {
    @apply text-lg;
    padding-left: 5rem;
  }
}
@screen lg {
  .accordion-item-content {
    padding-left: 6.5rem;
  }
}

.btn {
  @apply rounded-7
    bg-transparent
    text-primary text-lg font-medium leading-none tracking-wide;
  display: inline-block;
  height: 48px;
  padding: 2px 28px 0;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  user-select: none;
}
.btn:hover {
  text-decoration: none;
}
.btn:focus {
  @apply shadow-btn;
  outline: none;
}
.btn:not(:disabled) {
  cursor: pointer;
}
.btn:not(button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-primary {
  @apply bg-primary text-white;
}
.btn-primary:hover {
  @apply bg-primary-hover;
}
.btn-primary:focus {
  @apply shadow-btn-primary;
}

.btn-outline-primary {
  @apply border border-solid border-primary bg-transparent text-primary;
}
.btn-outline-primary:hover {
  @apply bg-primary text-white;
}
.btn-outline-primary:focus {
  @apply shadow-btn-primary;
}
.btn-outline-primary.light {
  border: 1px solid #295BA5;
  color: #295BA5;
  @apply text-sm font-600;
}
.btn-outline-primary.light svg {
  fill: #295BA5;
}
.btn-outline-primary.light:hover {
  @apply text-white;
  background: #295BA5;
}
.btn-outline-primary.light:hover svg {
  fill: white;
}

.btn-secondary {
  @apply bg-secondary text-primary;
}
.btn-secondary:hover {
  @apply bg-secondary-hover;
}
.btn-secondary:focus {
  @apply shadow-btn-secondary;
}

.btn-tertiary {
  @apply bg-blue text-white;
}
.btn-tertiary:hover {
  @apply bg-blue-hover;
}
.btn-tertiary:focus {
  @apply shadow-btn-tertiary;
}

.btn-white {
  @apply bg-white text-primary;
}
.btn-white:hover {
  @apply bg-white-hover;
}
.btn-white:focus {
  @apply shadow-input-white;
}

.btn-outline-white {
  @apply border border-solid border-white bg-transparent text-white;
}
.btn-outline-white:hover {
  @apply bg-white text-primary;
}
.btn-outline-white:focus {
  @apply shadow-input-white;
}

.btn-link {
  @apply rounded-none text-base leading-normal;
  position: relative;
  height: auto;
  padding: 0;
}
.btn-link.btn-sm, .btn-link.btn-lg {
  @apply rounded-none;
  height: auto;
  padding: 0;
}
@screen md {
  .btn-link {
    @apply text-lg;
  }
}
.btn-link:hover::after {
  right: 100%;
}
.btn-link::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  transition: right 0.3s ease-in-out;
  background-color: currentColor;
}
.btn-link.-invert-animation:hover::after, .btn-link.-invert-animation.active::after {
  right: 0;
}
.btn-link.-invert-animation::after {
  right: 100%;
}

.btn-sm {
  @apply rounded-lg text-xs;
  height: 24px;
  padding-right: 12px;
  padding-left: 12px;
}

.btn-md {
  @apply rounded-7 text-xs;
  height: 24px;
  padding-right: 12px;
  padding-left: 12px;
}
@screen md {
  .btn-md {
    @apply rounded-7 text-base;
    height: 32px;
    padding-right: 24px;
    padding-left: 24px;
  }
}
@screen xl {
  .btn-md {
    @apply text-lg;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.btn-lg {
  @apply rounded-8 text-lg;
  height: 64px;
  padding-right: 53px;
  padding-left: 53px;
}
@screen md {
  .btn-lg {
    @apply text-xl;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn-facebook {
  background-color: #517db1;
  @apply text-white px-10 font-medium text-lg;
  height: 54px;
}
@screen md {
  .btn-facebook {
    font-size: 20px;
  }
}
.btn-facebook:hover, .btn-facebook:active {
  background-color: #38577b;
}

.deep-link.highlight {
  position: relative;
  transition: outline, background 200ms;
  outline: 1px dashed #ff8b00 !important;
  outline-offset: -1px;
}
.deep-link.highlight.normal-offset {
  outline-offset: 0;
}
.deep-link.highlight .deep-link-edit-btn {
  display: flex;
}
.deep-link .deep-link-overlay {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(255, 139, 0, 0.1);
  pointer-events: none;
  transition: opacity 200ms;
  padding: 0;
  margin: 0;
}
.deep-link .deep-link-overlay .label {
  color: #000;
  float: right;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #ff8b00;
  pointer-events: none;
  margin: 0;
}
.deep-link .deep-link-edit-btn {
  display: none;
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  align-items: center;
  padding: 0.5rem;
  color: #000;
  font-size: 0.875rem;
  background-color: #ff8b00;
  border-bottom-right-radius: 0.1875rem;
  transition: transform 200ms;
  transform-origin: top left;
  width: 32px;
  height: 32px;
}
.deep-link .deep-link-edit-btn:hover {
  transform: scale(1.2);
}
.deep-link .deep-link-edit-btn:hover + .deep-link-overlay {
  opacity: 1;
}

ul.documents {
  @apply border-t border-gray-light text-base;
  @apply pl-0;
}
@screen md {
  ul.documents {
    @apply text-lg;
  }
}
ul.documents li {
  display: flex;
  align-items: center;
  padding: 0.5rem 0 0.5rem 2rem;
  border-bottom: 1px solid theme("colors.gray-light");
  background: url("../images/icons/document.svg") no-repeat;
  background-position: left center;
}
@screen md {
  ul.documents li {
    padding: 1rem 1.5rem 1rem 4rem;
    background-position: 1rem center;
  }
}
ul.documents div {
  flex: 1;
  margin-right: 1rem;
}
ul.documents a {
  flex-shrink: 0;
}

.dropdown {
  position: relative;
}
.dropdown.open .dropdown-trigger::after {
  transform: rotate(180deg);
}
.dropdown .trigger {
  width: 100%;
  height: 100%;
}

.dropdown-trigger {
  display: inline-block;
  position: relative;
  width: 100%;
}
.dropdown-trigger::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  transform: rotate(0);
  transform-origin: center;
  transition: all 0.3s ease-in-out;
  border-top: 5px solid theme("colors.white");
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.dropdown-menu {
  display: block;
  z-index: 1000;
}
.dropdown-menu[aria-hidden=true] {
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: 0;
}
.dropdown-menu[aria-hidden=false] {
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.dropdown-menu-1 {
  @apply rounded bg-white;
  min-width: 150px;
}
.dropdown-menu-1 .popover-arrow {
  @apply border-white;
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
}
.dropdown-menu-1[x-placement^=top] {
  margin-bottom: 7px;
}
.dropdown-menu-1[x-placement^=top] .tooltip-arrow {
  right: 12px;
  bottom: -7px;
  margin-top: 0;
  margin-bottom: 0;
  border-width: 7px 7px 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.dropdown-menu-1[x-placement^=bottom] {
  margin-top: 7px;
}
.dropdown-menu-1[x-placement^=bottom] .tooltip-arrow {
  top: -7px;
  right: 12px;
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0 7px 7px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.dropdown-menu-1 li.active a {
  @apply bg-blue-a2;
}
.dropdown-menu-1 li:first-child a {
  @apply rounded-t;
}
.dropdown-menu-1 li:last-child a {
  @apply rounded-b;
}
.dropdown-menu-1 a {
  @apply text-primary text-xs;
  display: block;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.3s ease-in-out;
}
.dropdown-menu-1 a:hover {
  @apply bg-blue-a2;
}

.dropdown-menu-2 {
  @apply rounded-b-sm bg-white;
  top: 100% !important;
  min-width: 100%;
  max-height: 400px;
  padding: 6px 0;
  overflow-y: auto;
  transform: translate3d(0, 0, 0) !important;
}
.dropdown-menu-2 .popover-arrow {
  display: none;
}
.dropdown-menu-2 a {
  @apply bg-transparent text-primary;
  display: block;
  padding: 0.75rem 1.25rem 0.75rem 1.5rem;
  transition: background-color 0.3s ease-in-out;
}
.dropdown-menu-2 a.affiliated {
  @apply text-blue;
}
.dropdown-menu-2 a:hover {
  @apply bg-blue-a2;
}

.dropdown-select.open .dropdown-trigger {
  @apply rounded-b-none border-primary bg-light;
}
.dropdown-select .dropdown-trigger {
  padding-right: 2rem;
  padding-left: 1rem;
}
.dropdown-select .dropdown-trigger:focus {
  @apply bg-light;
}
.dropdown-select .dropdown-trigger::after {
  right: 1rem;
  border-width: 7px 7px 0;
  border-color: theme("colors.gray-dark") transparent transparent;
}

.dropdown-select-menu {
  @apply border-primary rounded-b-sm bg-light;
  top: 100% !important;
  width: 100%;
  transform: translate3d(0, 0, 0) !important;
  border-width: 0 1px 1px;
}
.dropdown-select-menu .popover-arrow {
  display: none;
}
.dropdown-select-menu li.active a {
  @apply bg-blue-a2 font-medium;
}
.dropdown-select-menu li:last-child a {
  @apply rounded-b-sm;
}
.dropdown-select-menu a {
  @apply text-gray-dark;
  display: block;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.3s ease-in-out;
}
.dropdown-select-menu a:hover {
  @apply bg-blue-a2;
}

.embedded-video, .embed-code-online-center, .women_in_contemporary_judaism .pretty-content figure, .un_antisemitism .stream-section-content figure {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
}
.embedded-video iframe, .embed-code-online-center iframe, .women_in_contemporary_judaism .pretty-content figure iframe, .un_antisemitism .stream-section-content figure iframe,
.embedded-video video,
.embed-code-online-center video,
.women_in_contemporary_judaism .pretty-content figure video,
.un_antisemitism .stream-section-content figure video {
  border: none;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.form-group {
  margin-bottom: 1.5rem;
}
.form-group.-error .form-control, .form-group.has-error .form-control {
  @apply border-error;
}
.form-group.-error .forms-field-multichoice > input, .form-group.has-error .forms-field-multichoice > input {
  outline: 2px solid #ff8080;
}
.form-group.-error::after, .form-group.has-error::after {
  content: attr(data-error);
  color: #ff0000;
  @apply text-sm;
}

.form-label {
  @apply text-primary text-sm font-bold;
  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.form-label.-required::after, .form-label.required::after {
  @apply text-error;
  content: " *";
}

.form-control {
  @apply border border-light rounded-sm bg-light text-gray-dark font-medium;
  display: block;
  width: 100%;
  padding: 1rem;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.form-control:not(textarea) {
  height: 46px;
  padding-top: 4px;
  padding-bottom: 0;
}
@screen md {
  .form-control:not(textarea) {
    height: 54px;
  }
}
.form-control:focus {
  @apply border-primary bg-white;
  outline: none;
}
.form-control::placeholder {
  @apply text-gray;
  opacity: 1;
}

.invalid-feedback,
.error {
  @apply text-error text-sm font-medium;
  margin-top: 0.75rem;
}

.custom-select {
  padding: 4px 2rem 0 1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' xmlns='http://www.w3.org/2000/svg' fill='%234A4A4A'%3E%3Cpath d='M0 .07h14l-7 7z'/%3E%3C/svg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  appearance: none;
}

.form-collection-block .custom-control:not(:last-child) {
  margin-bottom: 0.5rem;
}

.custom-control {
  display: block;
  position: relative;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control label,
.custom-control-label {
  @apply text-gray-dark text-sm font-medium;
  display: inline-block;
  position: relative;
  padding-left: 2rem;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}
.custom-control label::before, .custom-control label::after,
.custom-control-label::before,
.custom-control-label::after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  width: 18px;
  height: 18px;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  pointer-events: none;
  user-select: none;
}
.custom-control label::after,
.custom-control-label::after {
  z-index: 1;
  opacity: 0;
}

.custom-control.checkbox label::before,
.custom-control-label-checkbox::before {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zm0 2H2v14h14V2z'/%3E%3C/svg%3E");
}
.custom-control.checkbox label::after,
.custom-control-label-checkbox::after {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM7 14l9-9-1.41-1.42L7 11.17 3.41 7.59 2 9l5 5z'/%3E%3C/svg%3E");
}

.custom-control.radio label::before,
.custom-control-label-radio::before {
  @apply rounded-full;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M9 0c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 16.2c3.978 0 7.2-3.222 7.2-7.2S12.978 1.8 9 1.8A7.198 7.198 0 0 0 1.8 9c0 3.978 3.222 7.2 7.2 7.2z'/%3E%3C/svg%3E");
}
.custom-control.radio label::after,
.custom-control-label-radio::after {
  @apply rounded-full;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M9 0c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 4.5c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5A4.502 4.502 0 0 1 4.5 9c0-2.484 2.016-4.5 4.5-4.5zm0 11.7c3.978 0 7.2-3.222 7.2-7.2S12.978 1.8 9 1.8A7.198 7.198 0 0 0 1.8 9c0 3.978 3.222 7.2 7.2 7.2z'/%3E%3C/svg%3E");
}

.custom-control-input:focus ~ label::before,
.custom-control-input:focus ~ label::after,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::after {
  @apply shadow-btn;
}

.custom-control-input:checked ~ label::after,
.custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
}

.form {
  width: 100%;
  padding: 3rem 0;
}
@screen sm {
  .form {
    max-width: 460px;
    margin: 0 auto;
  }
}
.form .btn[type=submit] {
  display: block;
  width: 100%;
}
@screen sm {
  .form .btn[type=submit] {
    width: auto;
    margin-left: auto;
  }
}

fieldset.date .form-label {
  width: 100%;
}
fieldset.date .form-control {
  display: inline-block;
  width: auto;
}

.forms-form,
.forms-success-message {
  @apply px-6 py-8;
  @apply rounded;
  @apply my-6;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
}

.forms-form .forms-field-hint {
  @apply text-primary-a7 text-sm pt-1;
  @apply mb-0;
}
.forms-form .forms-field-description {
  @apply mb-8;
}
.forms-form .forms-field-description * {
  @apply text-base;
}
.forms-form .forms-field-description p {
  @apply mb-2;
}
.forms-form .forms-field-description ul,
.forms-form .forms-field-description ol {
  @apply mb-6;
}
.forms-form .forms-field-radio,
.forms-form .forms-field-multichoice,
.forms-form .forms-field-checkbox {
  @apply flex items-center;
  @apply text-gray-dark font-medium;
  @apply mb-3;
}
.forms-form .forms-field-radio > input,
.forms-form .forms-field-multichoice > input,
.forms-form .forms-field-checkbox > input {
  @apply mr-1;
}
.forms-form .forms-field-checkbox.-required::after {
  display: contents;
  @apply text-error;
  content: " *";
}
.forms-form input[type=file] {
  @apply block;
}
.forms-form input[type=submit] {
  @apply mt-6;
}

.forms-success-message {
  @apply text-primary text-xl font-medium;
}

.related-news-swiper-nav-btn {
  top: 0;
  margin-top: calc(33.3327586207% - 12px);
}
@screen sm {
  .related-news-swiper-nav-btn {
    top: 87px;
    margin-top: -12px;
  }
}
@screen md {
  .related-news-swiper-nav-btn {
    top: 115px;
  }
}
@screen lg {
  .related-news-swiper-nav-btn {
    top: 97px;
  }
}
@screen xl {
  .related-news-swiper-nav-btn {
    top: 120px;
  }
}
@screen xxl {
  .related-news-swiper-nav-btn:focus {
    @apply shadow-btn-primary;
  }
  .related-news-swiper-nav-btn svg {
    @apply fill-primary;
  }
}

.related-news-box {
  display: block;
}
.related-news-box .date {
  @apply text-primary text-sm;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}
.related-news-box .title {
  @apply text-black-a7;
}

.tabs {
  display: flex;
  overflow-x: auto;
}
@screen lg {
  .tabs {
    justify-content: center;
  }
}
@screen xl {
  .tabs {
    justify-content: flex-start;
  }
}
.tabs:hover .active:not(:hover) {
  border-color: transparent;
}
.tabs li {
  flex: 1;
}
@screen lg {
  .tabs li {
    flex: 0 1 auto;
  }
}
.tabs a {
  @apply text-primary text-sm;
  display: inline-block;
  width: 100%;
  padding: 0.25rem 1rem;
  transition: border-color 0.3s ease-in-out;
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
}
@screen sm {
  .tabs a {
    @apply text-base;
    padding: 0.625rem 1rem;
  }
}
@screen md {
  .tabs a {
    @apply text-lg;
  }
}
@screen lg {
  .tabs a {
    padding: 0.625rem 5.75rem;
  }
}
.tabs a.active, .tabs a:hover {
  border-color: theme("colors.primary");
}
@screen lg {
  .tabs.even-tabs li {
    flex: 1;
  }
}
@screen lg {
  .tabs.even-tabs a {
    padding: 0.625rem 1rem;
  }
}
.tabs.uneven-tabs {
  @apply justify-start;
}
.tabs.uneven-tabs li {
  @apply flex-grow-0;
}
@screen lg {
  .tabs.uneven-tabs a {
    padding: 0.625rem 1rem;
  }
}

.cookie-notice-wrapper {
  @apply bg-black text-white;
  display: flex;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: auto;
}
@screen md {
  .cookie-notice-wrapper {
    background-color: transparent;
  }
}

.cookie-notice {
  padding-bottom: 1rem;
}
@screen md {
  .cookie-notice {
    @apply bg-black;
    order: 1;
    padding-bottom: 0;
  }
}
.cookie-notice .cookie-notice-text {
  @apply text-sm font-medium;
  margin-bottom: 1rem;
  padding-top: 1rem;
}
.cookie-notice .cookie-notice-text p {
  display: inline;
}
@screen md {
  .cookie-notice .cookie-notice-text {
    @apply py-5 text-xs;
    margin-bottom: 0;
  }
}
.cookie-notice a {
  @apply text-white underline;
}
.cookie-notice .btn {
  min-width: 165px;
}

.cookie-notice-settings {
  padding: 1rem 0;
}
@screen sm {
  .cookie-notice-settings {
    display: flex;
  }
}
.cookie-notice-settings:not(:last-child) {
  border-bottom: 1px solid theme("colors.white-a3");
}
.cookie-notice-settings > div:first-child {
  @apply flex-shrink-0;
  width: 165px;
  margin-right: 30px;
  margin-bottom: 0.75rem;
}
@screen sm {
  .cookie-notice-settings > div:first-child {
    margin-bottom: 0;
  }
}
.cookie-notice-settings > div:last-child {
  @apply text-xs;
  opacity: 0.8;
}
.cookie-notice-settings .custom-control-label-checkbox::before {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zm0 2H2v14h14V2z'/%3E%3C/svg%3E");
}
.cookie-notice-settings .custom-control-label-checkbox::after {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM7 14l9-9-1.41-1.42L7 11.17 3.41 7.59 2 9l5 5z'/%3E%3C/svg%3E");
}
.cookie-notice-settings .custom-control-label {
  @apply text-white font-bold;
}

.newsletter-section {
  @apply bg-dark;
  position: relative;
  text-align: center;
}
@screen md {
  .newsletter-section {
    text-align: left;
  }
}
.newsletter-section .triangle-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.newsletter-form {
  text-align: center;
}
@screen sm {
  .newsletter-form {
    @apply border-light rounded-7 bg-light;
    display: flex;
    max-width: 360px;
    height: 54px;
    margin: 0 auto;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-width: 3px;
  }
}
@screen md {
  .newsletter-form {
    margin: 0;
  }
}
@screen lg {
  .newsletter-form {
    max-width: 440px;
  }
}
@screen sm {
  .newsletter-form:focus-within {
    @apply border-white bg-white shadow-input-white;
  }
}
.newsletter-form .newsletter-email {
  @apply rounded-7 bg-light text-gray-dark text-base font-medium;
  width: 100%;
  min-width: 0;
  height: 48px;
  margin-bottom: 1rem;
  padding: 0 1.125rem;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: none;
}
@screen sm {
  .newsletter-form .newsletter-email {
    height: 100%;
    margin-bottom: 0;
    padding-right: 0.5rem;
    padding-left: 1.375rem;
  }
}
@screen lg {
  .newsletter-form .newsletter-email {
    padding-left: 1.75rem;
  }
}
.newsletter-form .newsletter-email:focus {
  @apply bg-white shadow-input-white;
}
@screen sm {
  .newsletter-form .newsletter-email:focus {
    @apply shadow-none;
  }
}
.newsletter-form .newsletter-email::placeholder {
  @apply text-primary-a5;
  opacity: 1;
}

.newsletter-sidebar {
  @apply pt-8 pb-14 px-6 rounded-sm bg-primary shadow-lg text-white text-center;
  background-image: url("../images/newsletter-sidebar-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}
.newsletter-sidebar .newsletter-form {
  display: block;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
@screen sm {
  .newsletter-sidebar .newsletter-form:focus-within {
    @apply border-none shadow-none;
  }
}
@screen sm {
  .newsletter-sidebar .newsletter-form .newsletter-email {
    height: 54px;
    margin-bottom: 2.5rem;
    padding-right: 1.375rem;
  }
}
@screen lg {
  .newsletter-sidebar .newsletter-form .newsletter-email {
    padding-right: 1.75rem;
  }
}

.triangle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.triangle::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  transition: border-color 0.3s ease-in-out;
  border-width: 8px 0 8px 13.9px;
  border-color: transparent transparent transparent theme("colors.primary");
}

.list-item-box {
  @apply w-full;
  @apply pb-8;
  transition: transform 0.3s ease-in-out;
}
.list-item-box .embedded-video, .list-item-box .embed-code-online-center, .list-item-box .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .list-item-box figure, .list-item-box .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .list-item-box figure {
  padding-bottom: 66.6666667%;
}
@screen sm {
  .list-item-box {
    @apply w-1/2;
  }
  .list-item-box:nth-child(odd) {
    @apply pr-3;
  }
  .list-item-box:nth-child(even) {
    @apply pl-3;
  }
}
@screen lg {
  .list-item-box {
    @apply w-1/3;
  }
  .list-item-box:nth-child(n) {
    @apply pl-6 pr-0;
  }
  .list-item-box:hover {
    transform: translateY(-1rem);
  }
}
.list-item-box img {
  @apply w-full;
}
.list-item-box .date {
  @apply text-sm text-gray;
}
.list-item-box h3 {
  @apply text-primary text-lg font-medium;
  @apply my-2;
}

.focus-item-img-overlay {
  @apply absolute left-0 right-0 top-0 bottom-0;
  background: linear-gradient(90deg, theme("colors.primary") 40%, theme("colors.primary-a0") 100%);
}

.focus-item-img {
  @apply absolute right-0;
  @apply h-full w-3/5;
  @apply object-cover;
  left: 40%;
}

@screen lg {
  .list-container {
    @apply flex;
  }
}

.list-category-selector {
  @apply flex flex-wrap justify-center w-full;
  @apply mb-8;
}
@screen lg {
  .list-category-selector {
    @apply block;
    width: 260px;
  }
}
.list-category-selector li {
  @apply text-primary text-center uppercase;
  @apply py-3 px-6;
  @apply w-full;
  @apply mb-4;
}
@screen sm {
  .list-category-selector li {
    @apply w-1/2;
  }
}
@screen md {
  .list-category-selector li {
    @apply w-1/3;
  }
}
@screen lg {
  .list-category-selector li {
    @apply text-left;
    @apply w-auto;
    @apply px-3 mb-0;
  }
}
.list-category-selector li.indented {
  @apply ml-10;
}
.list-category-selector li.active {
  @apply font-bold;
  @apply border-b-4 border-blue;
}
@screen lg {
  .list-category-selector li.active {
    @apply border-l-4 border-b-0;
    @apply pl-4;
  }
}
.list-category-selector li.active::after {
  @apply hidden;
  content: url("../images/icons/arrow-category.svg");
  position: relative;
  top: 3px;
  left: -2px;
  width: 8px;
  height: 15px;
  float: right;
}
@screen lg {
  .list-category-selector li.active::after {
    @apply block;
  }
}
.list-category-selector li a:hover {
  @apply underline;
}

.next-gen-swiper .swiper-wrapper {
  max-height: 520px;
}
.next-gen-swiper .swiper-slide img {
  min-height: 260px;
}
@screen lg {
  .next-gen-swiper .swiper-slide img {
    min-height: 520px;
  }
}

.next-gen-header {
  top: -150px;
}
@screen sm {
  .next-gen-header {
    top: -200px;
  }
}
@screen md {
  .next-gen-header {
    top: -250px;
  }
}
@screen lg {
  .next-gen-header {
    top: -300px;
  }
}

.advisory-column img {
  max-width: 264px;
  max-height: 350px;
}

.advisory-column-staff img {
  width: 206px;
  height: 275px;
}
@media screen and (max-width: 576px) {
  .advisory-column-staff img {
    width: 264px;
    height: 300px;
  }
}

.next-gen-pagination .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply text-lg;
}
.next-gen-pagination .pagination .next-page {
  @apply ml-4;
}
.next-gen-pagination .pagination .previous-page {
  @apply mr-4;
}
.next-gen-pagination .pagination div {
  @apply flex;
}
.next-gen-pagination .pagination div li {
  @apply py-1 px-3 ml-1;
}
.next-gen-pagination .pagination div li.active,
.next-gen-pagination .pagination div li.focus,
.next-gen-pagination .pagination div li.hover {
  @apply text-primary;
}

/*! purgecss start ignore */
.text-image-block {
  @apply mb-8;
}
@screen md {
  .text-image-block {
    @apply grid grid-cols-2 gap-8;
  }
  .text-image-block.-left .text-image-block-image-wrapper {
    order: -1;
  }
}
@screen lg {
  .text-image-block {
    @apply gap-12;
  }
}
.text-image-block .text-image-block-text {
  @apply flex flex-col;
  @apply py-4;
}
@screen lg {
  .text-image-block .text-image-block-text {
    @apply py-0;
  }
}
.text-image-block .text-image-block-title {
  @apply text-primary font-bold;
  @apply text-xl;
}
@screen lg {
  .text-image-block .text-image-block-title {
    @apply text-2xl;
  }
}
.text-image-block .text-image-block-body {
  @apply text-gray-dark text-base;
}
@screen lg {
  .text-image-block .text-image-block-body {
    @apply text-lg;
  }
}
.text-image-block .text-image-link {
  @apply text-white mr-auto mt-4;
  width: max-content;
  text-decoration: none;
}

.columns-block {
  @apply mb-4;
}
.columns-block.-opaque {
  @apply bg-primary-a9;
}
.columns-block.-opaque .columns-block-col {
  @apply px-4;
}
.columns-block.-opaque .columns-block-title,
.columns-block.-opaque .columns-block-body {
  @apply text-white;
}
@screen lg {
  .columns-block {
    @apply grid grid-cols-2 gap-4;
  }
}
.columns-block .columns-block-col {
  @apply py-4;
}
.columns-block .columns-block-title {
  @apply text-2xl;
}

.fancy-list-block {
  @apply mb-8;
}
@screen md {
  .fancy-list-block {
    @apply grid grid-cols-2 gap-4;
  }
}
@screen lg {
  .fancy-list-block {
    @apply gap-12;
  }
}
.fancy-list-block .fancy-list-block-title {
  @apply my-auto;
  @apply text-primary font-bold;
  @apply text-xl;
}
@screen lg {
  .fancy-list-block .fancy-list-block-title {
    @apply text-2xl;
  }
}
.fancy-list-block .fancy-list-block-list {
  @apply pl-6 my-0;
}
.fancy-list-block .fancy-list-block-list li + li {
  @apply mt-3;
}

.list-block {
  @apply pl-6;
}
.list-block li + li {
  @apply mt-3;
}

.iframe-block.wf-tune,
.iframe-block .twitter-tweet.-rendered {
  @apply text-center;
}
@screen sm {
  .iframe-block.wf-tune.-max-16\%,
  .iframe-block .twitter-tweet.-rendered.-max-16\% {
    @apply w-1/6;
  }
}
@screen sm {
  .iframe-block.wf-tune.-max-25\%,
  .iframe-block .twitter-tweet.-rendered.-max-25\% {
    @apply w-1/4;
  }
}
@screen sm {
  .iframe-block.wf-tune.-max-33\%,
  .iframe-block .twitter-tweet.-rendered.-max-33\% {
    @apply w-1/3;
  }
}
@screen sm {
  .iframe-block.wf-tune.-max-50\%,
  .iframe-block .twitter-tweet.-rendered.-max-50\% {
    @apply w-1/2;
  }
}
@screen sm {
  .iframe-block.wf-tune.-max-66\%,
  .iframe-block .twitter-tweet.-rendered.-max-66\% {
    @apply w-2/3;
  }
}
.iframe-block.wf-tune.-max-100\%,
.iframe-block .twitter-tweet.-rendered.-max-100\% {
  @apply flex-col;
  @apply w-full;
}
.iframe-block.wf-tune.-center,
.iframe-block .twitter-tweet.-rendered.-center {
  @apply mx-auto;
}
.iframe-block.wf-tune.-rigth,
.iframe-block .twitter-tweet.-rendered.-rigth {
  margin-left: auto;
}
.iframe-block.wf-tune.-wrap,
.iframe-block .twitter-tweet.-rendered.-wrap {
  @apply mt-4 mb-6;
}
@screen sm {
  .iframe-block.wf-tune.-wrap.-left,
  .iframe-block .twitter-tweet.-rendered.-wrap.-left {
    @apply mr-4;
    @apply float-left;
  }
}
@screen sm {
  .iframe-block.wf-tune.-wrap.-rigth,
  .iframe-block .twitter-tweet.-rendered.-wrap.-rigth {
    @apply ml-4;
    @apply float-right;
  }
}
.iframe-block.wf-tune.-wrap.-twitter,
.iframe-block .twitter-tweet.-rendered.-wrap.-twitter {
  @apply my-3;
}

.iframe-block-caption {
  @apply font-bold;
  @apply mt-2;
}

.delimiter-block {
  @apply my-8;
  @apply border-t border-gray-300;
}

.facebook-video-block {
  margin: 1rem 0;
}

.facebook-video-block-caption {
  @apply font-bold;
  @apply mt-4;
}

.boilerplate-block {
  @apply p-8;
  @apply relative;
  background-color: #f7f7f7;
}
.boilerplate-block .boilerplate-block-title {
  @apply text-lg text-primary font-bold;
  @apply mb-8;
}
.boilerplate-block .boilerplate-block-contact-title {
  @apply text-base text-primary;
  @apply mb-1;
}
@screen lg {
  .boilerplate-block .boilerplate-block-contact-title {
    @apply w-3/4;
  }
}
.boilerplate-block .boilerplate-block-body {
  @apply mb-6;
}
@screen lg {
  .boilerplate-block .boilerplate-block-body {
    @apply mb-0;
  }
}
.boilerplate-block .boilerplate-block-body a,
.boilerplate-block .boilerplate-block-contact-body a {
  color: #0257d5;
}
@screen lg {
  .boilerplate-block.-one-column .boilerplate-block-columns {
    @apply grid-cols-1 gap-0;
  }
}

.boilerplate-block-logo {
  @apply mb-6;
}
@screen lg {
  .boilerplate-block-logo {
    @apply absolute right-0 top-0;
    @apply m-8;
  }
}

.boilerplate-block-columns {
  @apply flex flex-col;
}
@screen lg {
  .boilerplate-block-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

.boilerplate-block-socials {
  @apply pt-6 flex;
}
.boilerplate-block-socials a + a {
  @apply ml-6;
}

.image-block {
  @apply text-center;
}
.image-block .image-block-caption {
  @apply font-normal text-sm text-gray;
  @apply text-center;
  @apply italic;
  @apply mt-1;
}
@screen sm {
  .image-block.-max-16\% {
    @apply flex-col;
    @apply w-1/6;
  }
}
@screen sm {
  .image-block.-max-25\% {
    @apply flex-col;
    @apply w-1/4;
  }
}
@screen sm {
  .image-block.-max-33\% {
    @apply flex-col;
    @apply w-1/3;
  }
}
@screen sm {
  .image-block.-max-50\% {
    @apply flex-col;
    @apply w-1/2;
  }
}
@screen sm {
  .image-block.-max-66\% {
    @apply flex-col;
    @apply w-2/3;
  }
}
.image-block.-max-100\% {
  @apply flex-col;
  @apply w-full;
}
.image-block.-center {
  @apply mx-auto;
}
.image-block.-right {
  margin-left: auto;
}
.image-block.-wrap {
  @apply mt-4 mb-6;
}
@screen sm {
  .image-block.-wrap.-left {
    @apply mr-4;
    @apply float-left;
  }
}
@screen sm {
  .image-block.-wrap.-right {
    @apply ml-4;
    @apply float-right;
  }
}

.raw-block iframe,
.article-content .raw-block iframe {
  display: block;
  margin: 0 auto;
}

.block-button {
  @apply flex items-center my-4;
}
.block-button .btn {
  text-decoration: none;
}
.block-button .btn:hover {
  text-decoration: none;
}
.block-button .btn-tertiary {
  @apply text-white;
}

.no-link-style {
  text-decoration: none !important;
}

.gallery-block-photo {
  @apply transition-all duration-150;
  @apply border-4 border-transparent;
}
.gallery-block-photo:hover {
  @apply z-10;
  @apply transform scale-110;
  @apply border-white;
  @apply rounded;
  @apply shadow-md;
}

.gallery-block-photo-link {
  @apply block w-full;
}
.gallery-block-photo-link img {
  @apply w-full;
}

.gallery-block-caption {
  display: block;
  margin-top: 4px;
}

.newsletter-block {
  @apply py-8 rounded-sm bg-primary shadow-lg text-white text-center;
  @apply flex flex-col;
  @apply px-3 my-10;
  @apply rounded;
  position: relative;
  z-index: 1;
  background-image: url("../images/banner_bg.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
@media screen and (min-width: 340px) {
  .newsletter-block {
    @apply px-6;
  }
}
@screen lg {
  .newsletter-block {
    @apply grid grid-cols-10 gap-x-8 items-center;
  }
}
@screen xl {
  .newsletter-block {
    @apply px-12;
  }
}
@screen lg {
  .newsletter-block .newsletter-block-about-text {
    @apply col-span-5 text-left;
  }
}
@screen xl {
  .newsletter-block .newsletter-block-about-text {
    @apply col-span-6 col-start-1;
  }
}
.newsletter-block .newsletter-block-about-text h4 {
  @apply text-white text-xl;
}
@screen lg {
  .newsletter-block .newsletter-block-about-text h4 {
    @apply text-2xl;
  }
}
.newsletter-block .newsletter-block-about-text p {
  @apply text-white text-lg font-medium mb-0;
}
.newsletter-block .newsletter-block-form-wrapper {
  @apply mt-6 mx-auto;
}
@screen lg {
  .newsletter-block .newsletter-block-form-wrapper {
    @apply col-span-5 text-left mt-0 mx-0;
  }
}
@screen xl {
  .newsletter-block .newsletter-block-form-wrapper {
    @apply col-span-5 col-start-7;
  }
}
.newsletter-block .newsletter-form {
  @apply border-light rounded-7 bg-light;
  display: flex;
  height: 54px;
  transition: all;
  border-width: 3px;
}
@screen md {
  .newsletter-block .newsletter-form {
    width: 100%;
  }
}
.newsletter-block .newsletter-form:focus-within {
  @apply border-white bg-white shadow-input-white;
}
.newsletter-block .newsletter-form .newsletter-email {
  @apply rounded-7 bg-light text-gray-dark text-base font-medium;
  @apply border-none;
  width: 100%;
  min-width: 0;
  height: 48px;
  margin-bottom: 1rem;
  padding: 0 1.125rem;
  transition: all;
  outline: none;
}
@screen sm {
  .newsletter-block .newsletter-form .newsletter-email {
    height: 100%;
    margin-bottom: 0;
    padding-right: 0.5rem;
    padding-left: 1.375rem;
  }
}
@screen lg {
  .newsletter-block .newsletter-form .newsletter-email {
    padding-left: 1.75rem;
  }
}
.newsletter-block .newsletter-form .newsletter-email:focus {
  @apply bg-white shadow-input-white;
  @apply outline-none;
}
@screen sm {
  .newsletter-block .newsletter-form .newsletter-email:focus {
    @apply shadow-none;
  }
}
.newsletter-block .newsletter-form .btn {
  @apply px-2 text-white rounded-xl text-base uppercase whitespace-nowrap bg-dark-blue;
}
@media screen and (min-width: 375px) {
  .newsletter-block .newsletter-form .btn {
    @apply text-sm;
  }
}
@screen sm {
  .newsletter-block .newsletter-form .btn {
    @apply rounded-xl text-lg;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.newsletter-block.wf-tune {
  @apply flex flex-col px-5 py-8;
  background-image: url("../images/side_banner_bg.svg");
}
.newsletter-block.wf-tune .newsletter-form {
  @apply flex-col mx-auto bg-transparent border-transparent;
}
.newsletter-block.wf-tune .newsletter-form .newsletter-email {
  @apply mb-2;
}
.newsletter-block.wf-tune .newsletter-form .btn,
.newsletter-block.wf-tune .newsletter-form .newsletter-email {
  @apply text-center;
  min-height: 48px;
}
.newsletter-block.wf-tune .newsletter-form .btn {
  @apply text-primary bg-very-light-blue;
  @apply font-primary text-lg font-bold;
}
.newsletter-block.wf-tune .newsletter-form:focus-within {
  @apply border-transparent bg-transparent shadow-input-white;
}
.newsletter-block.wf-tune .newsletter-block-form-wrapper {
  @apply w-full mt-6 mb-10;
  min-height: 80px;
}
.newsletter-block.wf-tune .newsletter-block-about-text {
  @apply text-center;
}
.newsletter-block.wf-tune .newsletter-block-about-text h4 {
  @apply text-white text-xl font-medium;
}
.newsletter-block.wf-tune .newsletter-block-about-text p {
  @apply text-white text-lg font-medium mb-0;
}
@screen sm {
  .newsletter-block.wf-tune.-max-16\% {
    @apply w-1/6;
  }
}
@screen sm {
  .newsletter-block.wf-tune.-max-25\% {
    @apply w-1/4;
  }
}
@screen sm {
  .newsletter-block.wf-tune.-max-33\% {
    @apply w-1/3;
  }
}
@screen sm {
  .newsletter-block.wf-tune.-max-50\% {
    @apply w-1/2;
  }
}
@screen sm {
  .newsletter-block.wf-tune.-max-66\% {
    @apply w-2/3;
  }
}
.newsletter-block.wf-tune.-max-100\% {
  @apply flex-col;
  @apply w-full;
}
.newsletter-block.wf-tune.-center {
  @apply mx-auto;
}
.newsletter-block.wf-tune.-rigth {
  margin-left: auto;
}
.newsletter-block.wf-tune.-left {
  margin-right: auto;
}
.newsletter-block.wf-tune.-wrap {
  @apply mt-2 mb-2;
}
@screen sm {
  .newsletter-block.wf-tune.-wrap.-left {
    @apply mr-4;
    @apply float-left;
  }
}
@screen sm {
  .newsletter-block.wf-tune.-wrap.-rigth {
    @apply ml-4;
    @apply float-right;
  }
}

.banner-block {
  @apply my-8 p-6;
  @apply bg-cover bg-center;
}
@screen md {
  .banner-block {
    @apply p-12;
  }
}

.footnote-block .footnote-container {
  @apply flex text-base font-normal;
}
.footnote-block .footnote-text {
  @apply ml-1;
}

.raw-block ul,
.nested-list-block ul {
  list-style-type: disc !important;
}
.raw-block ul > li > ul,
.nested-list-block ul > li > ul {
  list-style-type: circle !important;
}
.raw-block ul > li > ul > li > ul,
.nested-list-block ul > li > ul > li > ul {
  list-style-type: square !important;
}
.raw-block ol,
.nested-list-block ol {
  list-style-type: none;
  counter-reset: item;
  margin-bottom: 0;
}
.raw-block ol > li,
.nested-list-block ol > li {
  display: table;
  counter-increment: item;
}
.raw-block ol > li:before,
.nested-list-block ol > li:before {
  content: counters(item, ".") ". ";
  padding-right: 0.6em;
}
.raw-block li ol > li:before,
.nested-list-block li ol > li:before {
  content: counters(item, ".") " ";
}

.table-block ol {
  list-style-type: none;
  counter-reset: item;
  margin-bottom: 0;
}
.table-block ol > li {
  display: table;
  counter-increment: item;
}
.table-block ol > li:before {
  content: counters(item, ".") ". ";
  padding-right: 0.6em;
}
.table-block li ol > li:before {
  content: counters(item, ".") " ";
}

/*! purgecss end ignore */
.header-social-nav {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  padding: 0 1.5rem;
  border-right: 1px solid theme("colors.white");
  border-left: 1px solid theme("colors.white");
}
@screen sm {
  .header-social-nav {
    margin-right: 1.5rem;
  }
}
.header-social-nav a {
  display: block;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  font-size: 0;
  line-height: 1;
}
.header-social-nav a:not(:first-child) {
  margin-left: 0.7rem;
}
@screen sm {
  .header-social-nav a:not(:first-child) {
    margin-left: 1.5rem;
  }
}
.header-social-nav a:hover {
  @apply opacity-icon;
}
.header-social-nav a img {
  width: 100%;
}

.language-menu {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 32px;
}
.language-menu.open .dropdown-trigger {
  @apply text-secondary;
}
.language-menu.open .dropdown-trigger::after {
  border-top-color: theme("colors.secondary");
}
.language-menu .dropdown-trigger {
  @apply text-white text-sm font-medium leading-none;
  display: block;
  height: 100%;
  padding-right: 1.125rem;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
}
.language-menu .dropdown-trigger:hover, .language-menu .dropdown-trigger:focus {
  @apply text-secondary;
  outline: none;
}
.language-menu .dropdown-trigger:hover::after, .language-menu .dropdown-trigger:focus::after {
  border-top-color: theme("colors.secondary");
}

.navigation {
  @apply bg-primary;
  display: flex;
  position: fixed;
  top: 100px;
  right: 0;
  left: 100%;
  height: 100%;
  padding: 1rem;
  transition: left 0.3s ease-in-out;
  text-align: center;
}
.navigation.-jdcorps {
  top: 50px;
  height: calc(100% - 50px);
  padding: 1.5rem;
}
@screen sm {
  .navigation {
    top: 115px;
    height: calc(100% - 96px);
    padding: 1.5rem;
  }
  .navigation.-jdcorps {
    top: 60px;
    height: calc(100% - 60px);
    padding: 2.5rem;
  }
}
@screen md {
  .navigation {
    top: 120px;
    height: calc(100% - 120px);
  }
  .navigation.-jdcorps {
    padding: 1.5rem;
  }
}
@screen lg {
  .navigation {
    top: 88px;
    height: calc(100% - 104px);
  }
}
@screen xl {
  .navigation {
    @apply bg-transparent;
    position: static;
    height: auto;
    padding: 0;
  }
}
.navigation.-jdcorps {
  @apply px-2;
}

.menu {
  flex: 1;
  overflow: hidden;
}
@screen xl {
  .menu {
    display: flex;
    align-items: center;
    overflow: unset;
  }
}

.nav-item {
  padding: 0.5rem 0;
}
@screen md {
  .nav-item {
    padding: 1rem;
  }
}
@screen xl {
  .nav-item {
    padding: 0;
    padding-right: 6px;
    padding-left: 6px;
  }
}
@screen xl {
  .nav-item {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.nav-link {
  @apply text-white font-bold leading-loose;
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
}
@screen xl {
  .nav-link {
    @apply text-xs;
    padding-top: 1px;
  }
}
@screen xl {
  .nav-link {
    font-size: 13px;
  }
}
.nav-link:hover::after, .nav-link.active::after {
  right: 0;
}
.nav-link::after {
  right: 100%;
}

.nav-item.-more {
  @apply relative rounded-t;
  @apply transition ease-in-out delay-100;
  @apply cursor-pointer;
  @apply justify-center flex flex-col items-center;
}
@screen xl {
  .nav-item.-more {
    @apply block;
  }
}
@screen md {
  .nav-item.-more .btn-link::after {
    display: none;
  }
}
.nav-item.-more svg {
  @apply hidden;
}
@screen md {
  .nav-item.-more svg {
    @apply block;
    stroke: white;
    transform: rotate(180deg);
    transition: all 0.4s ease;
  }
}
@screen xl {
  .nav-item.-more:hover {
    @apply bg-white;
  }
  .nav-item.-more:hover .nav-link {
    color: #02080f;
  }
}
.nav-item.-more:hover svg {
  stroke: currentColor;
  transform: rotateZ(360deg);
}
@screen md {
  .nav-item.-more:hover + .nav-submenu,
  .nav-item.-more:hover .nav-submenu {
    @apply block;
  }
}
@screen xl {
  .nav-item.-more:hover + .nav-submenu,
  .nav-item.-more:hover .nav-submenu {
    @apply visible;
    opacity: 1;
  }
}

.nav-item.-more.-programs .btn-link::after {
  display: none;
}
.nav-item.-more.-programs svg {
  @apply block;
  stroke: white;
  transform: rotate(180deg);
  transition: all 0.4s ease;
}
.nav-item.-more.-programs .nav-submenu {
  text-align: left !important;
}
@screen md {
  .nav-item.-more.-programs .nav-submenu {
    text-align: center !important;
  }
}
.nav-item.-more.-programs:hover svg {
  stroke: currentColor;
  transform: rotateZ(360deg);
}
.nav-item.-more.-programs:hover + .nav-submenu,
.nav-item.-more.-programs:hover .nav-submenu {
  display: block !important;
}
.nav-item.-more.-programs .nav-sub-link {
  font-size: 12px !important;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
@screen md {
  .nav-item.-more.-programs .nav-sub-link {
    font-size: 14px !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.nav-submenu {
  @apply hidden;
  transition: all ease-in-out 100ms;
  padding-top: 0rem;
  overflow: hidden;
  top: 3rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
@screen md {}
@screen xl {
  .nav-submenu {
    @apply block invisible;
    @apply absolute z-10 right-0 top-auto left-auto;
    @apply rounded-l rounded-br;
    @apply bg-white;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.15);
    text-align: left;
  }
}

.nav-sub-link {
  @apply block;
  @apply uppercase text-white px-4 py-2;
  @apply transition ease-in-out delay-100;
  overflow: hidden;
  @apply text-sm;
}
@screen xl {
  .nav-sub-link {
    color: #02080f;
    @apply px-4 py-3 text-left;
  }
  .nav-sub-link:hover {
    background-color: #e7ecf0;
  }
  @screen xl {
    .nav-sub-link {
      font-size: 13px;
    }
  }
}

.search-trigger {
  @apply outline-none;
  @apply relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  z-index: -1;
}
.search-trigger:hover, .search-trigger:focus {
  @apply opacity-icon outline-none;
}
.search-trigger img {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
@screen md {
  .search-trigger img {
    width: 24px;
    height: 24px;
  }
}
@screen xl {
  .search-trigger img {
    width: 18px;
    height: 18px;
  }
}

.search-trigger-arrow {
  @apply absolute;
  margin-top: 2px;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.tooltip[x-placement^=bottom] {
  margin-top: 5px;
}
.tooltip[x-placement^=bottom] .tooltip-arrow {
  border-width: 0 13px 14px 13px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.search-form {
  display: flex;
  align-items: center;
  width: 100%;
}
@screen sm {
  .search-form {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;
    left: 100%;
    width: auto;
    height: 32px;
    margin-top: -16px;
    overflow: hidden;
    transition: left 0.3s ease-in-out;
  }
}
.search-form.open {
  left: 0;
  position: fixed;
  top: 63px;
  background: white;
  padding: 35px;
}
.search-form input {
  @apply bg-transparent text-black;
  flex: 1;
  height: 100%;
  padding: 0 1rem;
}
.search-form input:focus {
  @apply border-transparent outline-none;
}
.search-form input::placeholder {
  @apply text-black;
  opacity: 1;
}

.header-height-space {
  margin-top: 90px;
}
@screen sm {
  .header-height-space {
    margin-top: 95px;
  }
}
@screen md {
  .header-height-space {
    margin-top: 120px;
  }
}
@screen lg {
  .header-height-space {
    margin-top: 100px;
  }
}
@screen xl {
  .header-height-space {
    margin-top: 120px;
  }
}

.header {
  @apply bg-primary shadow-lg;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  transition: background 0.3s ease-in-out;
}
@screen md {
  .header {
    @apply bg-transparent shadow-none;
  }
}

.position-option {
  background-position: 55% 50px;
}
@screen md {
  .position-option {
    background-position: 70% 50px;
  }
}

.header-text-shadow {
  text-shadow: 1px 1px 8px #021934;
}

.topbar-newsletter-link {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.topbar-newsletter-link:hover {
  @apply opacity-icon;
}
@screen sm {
  .topbar-newsletter-link {
    margin-right: 1.5rem;
  }
}

.support-our-work-link {
  @apply text-yellow;
  margin-right: 1rem;
  border-right: 1px solid theme("colors.white");
  padding: 0 1.5rem;
  transition: color 0.3s ease-in-out;
}
.support-our-work-link:hover span {
  @apply text-white-hover;
}
@screen sm {
  .support-our-work-link {
    margin-right: 1.5rem;
  }
}

.navigation-wrapper {
  padding-top: 18px;
  padding-bottom: 18px;
}
@screen md {
  .navigation-wrapper {
    padding-top: 18px;
    transition: background 0.3s ease-in-out;
    background: linear-gradient(180deg, theme("colors.black-a3") 47.75%, theme("colors.black-a0") 100%);
  }
}

.logo {
  display: block;
  flex-shrink: 0;
  width: 70px;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
@screen sm {
  .logo {
    width: 80px;
  }
}
@screen md {
  .logo {
    width: 150px;
  }
}
@screen lg {
  .logo {
    width: 80px;
  }
}
@screen xl {
  .logo {
    width: 150px;
  }
}
.logo:hover {
  @apply opacity-icon;
}

.toggle-mobile-nav {
  position: relative;
  width: 18px;
  height: 12px;
  margin-left: 1.125rem;
}
@screen md {
  .toggle-mobile-nav {
    width: 36px;
    height: 24px;
    margin-left: 2rem;
  }
}
@screen xl {
  .toggle-mobile-nav {
    display: none;
  }
}
.toggle-mobile-nav:focus {
  outline: none;
}
.toggle-mobile-nav span {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  opacity: 1;
  background-color: theme("colors.white");
}
@screen md {
  .toggle-mobile-nav span {
    height: 4px;
  }
}
.toggle-mobile-nav span:nth-child(1) {
  top: 0;
}
.toggle-mobile-nav span:nth-child(2), .toggle-mobile-nav span:nth-child(3) {
  top: 5px;
}
@screen md {
  .toggle-mobile-nav span:nth-child(2), .toggle-mobile-nav span:nth-child(3) {
    top: 10px;
  }
}
.toggle-mobile-nav span:nth-child(4) {
  top: 10px;
}
@screen md {
  .toggle-mobile-nav span:nth-child(4) {
    top: 20px;
  }
}
@screen xl {
  .toggle-mobile-nav.-side {
    display: block;
  }
}
.toggle-mobile-nav.-side span {
  background-color: #28528e;
}

.mobile-menu-opened {
  @apply bg-primary;
}
.mobile-menu-opened .navigation-wrapper {
  background: transparent;
}
.mobile-menu-opened .navigation {
  left: 0;
}
.mobile-menu-opened [data-holiday-banner] ~ .navigation-wrapper .navigation {
  transform: translateY(60px);
}
.mobile-menu-opened .toggle-mobile-nav span:nth-child(1) {
  top: 10px;
  left: 50%;
  width: 0%;
}
.mobile-menu-opened .toggle-mobile-nav span:nth-child(2) {
  transform: rotate(45deg);
}
.mobile-menu-opened .toggle-mobile-nav span:nth-child(3) {
  transform: rotate(-45deg);
}
.mobile-menu-opened .toggle-mobile-nav span:nth-child(4) {
  top: 10px;
  left: 50%;
  width: 0%;
}

@screen xl {
  .navigation {
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.3s;
    opacity: 1;
  }
}

.footer {
  @apply bg-dark text-white;
  position: relative;
  z-index: 1;
  padding: 2rem 0;
}
@screen md {
  .footer {
    padding: 4rem 0;
  }
}
.footer .newsletter-form {
  @apply border-light rounded-7 bg-light;
  display: flex;
  height: 54px;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-width: 3px;
}
@screen md {
  .footer .newsletter-form {
    width: 100%;
    max-width: 360px;
  }
}
.footer .newsletter-form:focus-within {
  @apply border-white bg-white shadow-input-white;
}
@media (max-width: 575px) {
  .footer .newsletter-form .newsletter-email {
    @apply text-sm;
    height: 100%;
    margin-bottom: 0;
    padding-right: 0.5rem;
  }
  .footer .newsletter-form .newsletter-email:focus {
    @apply bg-white shadow-none;
  }
}
@media (max-width: 575px) {
  .footer .newsletter-form .btn {
    @apply rounded-xl text-sm;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.footer-logo-col {
  width: 195px;
  margin: 0 auto 2rem;
}
@screen md {
  .footer-logo-col {
    @apply flex-shrink-0;
    margin-right: 2rem;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 2.75rem;
  }
}
@screen lg {
  .footer-logo-col {
    margin-right: 6rem;
  }
}

@screen md {
  .footer-menu {
    display: flex;
    flex-wrap: wrap;
  }
}
.footer-menu > li {
  flex: 1 1 auto;
  margin-bottom: 2rem;
  text-align: center;
}
@screen md {
  .footer-menu > li {
    margin-bottom: 3rem;
    text-align: left;
  }
}
@screen lg {
  .footer-menu > li {
    flex: 0 1 auto;
  }
}

.footer-main-link {
  @apply text-white text-sm font-bold;
  display: inline-block;
  text-transform: uppercase;
}
@screen md {
  .footer-main-link {
    margin-bottom: 0.625rem;
  }
}
.footer-main-link:hover {
  text-decoration: underline;
}

.footer-inner-links {
  @apply mr-gutter;
  display: none;
}
@screen md {
  .footer-inner-links {
    display: block;
  }
}
@screen lg {
  .footer-inner-links {
    width: 175px;
  }
}
@screen xl {
  .footer-inner-links {
    width: 165px;
  }
}
@screen lg {
  .footer-inner-links.lg\:wider {
    width: 260px;
  }
}
@screen xl {
  .footer-inner-links.xl\:wider {
    width: 260px;
  }
}
.footer-inner-links a {
  @apply text-white text-xs font-medium leading-loose;
  opacity: 0.7;
  text-transform: uppercase;
}
.footer-inner-links a:hover {
  text-decoration: underline;
}

.footer-socials {
  display: flex;
  justify-content: center;
}
.footer-socials a {
  transition: opacity 0.3s ease-in-out;
  opacity: 0.8;
}
.footer-socials a:hover {
  opacity: 1;
}
.footer-socials a:not(:last-child) {
  margin-right: 2rem;
}

.footer-search-form {
  @apply bg-primary;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 1rem;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 27px;
}
.footer-search-form:focus-within {
  @apply shadow-input-white;
}
.footer-search-form img {
  opacity: 0.7;
}
.footer-search-form input {
  @apply bg-transparent text-white;
  width: 100%;
  height: 100%;
  margin-left: 1rem;
}
.footer-search-form input:focus {
  @apply border-transparent outline-none;
}
.footer-search-form input::placeholder {
  @apply text-white-a5;
  opacity: 1;
}

.hero-scroll-down {
  @apply text-secondary text-xs font-medium;
  display: block;
  width: 50px;
  margin: 30px auto 0;
  text-align: center;
  text-transform: uppercase;
}
@screen md {
  .hero-scroll-down {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-top: 0;
    margin-left: -25px;
  }
}
@screen lg {
  .hero-scroll-down {
    bottom: 30px;
  }
}
.hero-scroll-down::before {
  content: "Scroll Down";
  display: block;
  margin-bottom: 1rem;
}
.hero-scroll-down::after {
  content: "";
  display: block;
  height: 18px;
  animation: bounce 3s ease infinite normal;
  background-image: url("../images/icons/scrolldown.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.inner-hero {
  @apply bg-primary text-white;
  display: flex;
  position: relative;
}

.inner-hero-container {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 340px;
  padding-top: 88px;
  padding-bottom: 32px;
  text-align: center;
}
@screen sm {
  .inner-hero-container {
    min-height: 400px;
    padding-top: 104px;
  }
}
@screen md {
  .inner-hero-container {
    @apply py-28;
  }
}
@screen xl {
  .inner-hero-container {
    @apply py-32;
  }
}
@screen sm {
  .inner-hero-container.-long {
    min-height: 700px;
  }
}

.inner-hero-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.remove-hero-background-color {
  background-color: unset;
}
.remove-hero-background-color .inner-hero-img {
  opacity: 0.8;
}

.hero-logo-wrapper {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
}

.hero-logo {
  max-width: 200px;
  padding: 1rem 0.5rem;
  transform: translateY(50%);
  border: 1px solid theme("colors.primary");
  border-radius: 2px;
  background-color: theme("colors.white");
}

.hero-tabs {
  @apply px-gutter-1/2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@screen md {
  .hero-tabs {
    @apply px-gutter;
  }
}
.hero-tabs ul {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
}
.hero-tabs li {
  flex: 1;
}
.hero-tabs a {
  @apply text-white text-base;
  display: inline-block;
  width: 100%;
  padding: 5px 1rem;
  transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-bottom: 5px solid transparent;
  opacity: 0.8;
  text-transform: uppercase;
  white-space: nowrap;
}
@screen md {
  .hero-tabs a {
    padding: 5px 0.5rem;
    border-bottom-width: 10px;
  }
}
@screen lg {
  .hero-tabs a {
    @apply text-lg tracking-wide;
    padding: 5px 1rem;
  }
}
.hero-tabs a:hover {
  border-color: theme("colors.white");
  opacity: 1;
}
.hero-tabs a.active {
  @apply font-bold;
  border-color: theme("colors.white");
  opacity: 1;
}

.charidy-hero {
  min-height: 300px;
}
@screen md {
  .charidy-hero {
    min-height: 260px;
  }
}
@screen lg {
  .charidy-hero {
    min-height: 360px;
  }
}

@screen md {
  .charidy-header {
    min-height: 140px;
  }
}
@screen lg {
  .charidy-header {
    min-height: 150px;
  }
}

.special-video-class .embedded-video video, .special-video-class .embed-code-online-center video, .special-video-class .women_in_contemporary_judaism .pretty-content figure video, .women_in_contemporary_judaism .pretty-content .special-video-class figure video, .special-video-class .un_antisemitism .stream-section-content figure video, .un_antisemitism .stream-section-content .special-video-class figure video {
  max-width: 460px;
}
@screen lg {
  .special-video-class .embedded-video video, .special-video-class .embed-code-online-center video, .special-video-class .women_in_contemporary_judaism .pretty-content figure video, .women_in_contemporary_judaism .pretty-content .special-video-class figure video, .special-video-class .un_antisemitism .stream-section-content figure video, .un_antisemitism .stream-section-content .special-video-class figure video {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: auto;
  }
}
.special-video-class .embedded-video, .special-video-class .embed-code-online-center, .special-video-class .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .special-video-class figure, .special-video-class .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .special-video-class figure {
  padding-bottom: 69%;
}
.special-video-class .special-video-container {
  display: flex;
  justify-content: center;
}

.social-share-items-dark {
  @apply mt-2 flex gap-4 p-1.5 pl-4 items-center mx-auto;
  @apply text-white font-primary font-semi-bold uppercase text-sm text-opacity-70;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 64px;
  width: fit-content;
}

.event-full-background-image {
  height: 50vh;
}

@screen md {
  .image-plus-text-item {
    display: flex;
  }
}
.image-plus-text-item:not(:last-child) {
  margin-bottom: 2rem;
}
@screen sm {
  .image-plus-text-item:not(:last-child) {
    margin-bottom: 3rem;
  }
}
@screen md {
  .image-plus-text-item:not(:last-child) {
    margin-bottom: 5rem;
  }
  .image-plus-text-item:not(:last-child).-jdcorps {
    margin-bottom: 4rem;
  }
}
@screen md {
  .image-plus-text-item:nth-child(odd) .txt-holder {
    margin-left: 1rem;
  }
}
@screen lg {
  .image-plus-text-item:nth-child(odd) .txt-holder {
    margin-left: 3rem;
  }
  .image-plus-text-item:nth-child(odd) .txt-holder.-jdcorps {
    margin-left: 2rem;
  }
}
@screen xl {
  .image-plus-text-item:nth-child(odd) .txt-holder {
    margin-left: 5rem;
  }
  .image-plus-text-item:nth-child(odd) .txt-holder.-jdcorps {
    margin-left: 2rem;
  }
}
@screen md {
  .image-plus-text-item:nth-child(even) .txt-holder {
    order: -1;
    margin-right: 1rem;
  }
}
@screen lg {
  .image-plus-text-item:nth-child(even) .txt-holder {
    margin-right: 3rem;
  }
  .image-plus-text-item:nth-child(even) .txt-holder.-jdcorps {
    margin-right: 2rem;
  }
}
@screen xl {
  .image-plus-text-item:nth-child(even) .txt-holder {
    margin-right: 5rem;
  }
  .image-plus-text-item:nth-child(even) .txt-holder.-jdcorps {
    margin-right: 2rem;
  }
}
.image-plus-text-item .img-holder {
  flex-shrink: 0;
}
@screen md {
  .image-plus-text-item .img-holder {
    width: 45%;
  }
}
@screen xl {
  .image-plus-text-item .img-holder {
    width: 50%;
  }
}
.image-plus-text-item .img-holder img {
  width: 100%;
}
.image-plus-text-item .txt-holder {
  @apply text-gray-dark text-base;
  padding-top: 1rem;
}
@screen md {
  .image-plus-text-item .txt-holder {
    flex: 1;
  }
}
@screen lg {
  .image-plus-text-item .txt-holder {
    @apply text-lg;
  }
}
.image-plus-text-item .summary {
  margin-bottom: 1rem;
}
@screen md {
  .image-plus-text-item .summary {
    margin-bottom: 1.5rem;
  }
}
@screen lg {
  .image-plus-text-item .summary {
    margin-bottom: 2rem;
  }
}

.anchor {
  position: relative;
  top: -180px;
}
@screen md {
  .anchor {
    top: -140px;
  }
}
@screen lg {
  .anchor {
    top: -160px;
  }
}

.home-issue-item {
  @apply text-white;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
}
.home-issue-item:hover h5,
.home-issue-item:hover .summary,
.home-issue-item:hover a {
  opacity: 1;
}
.home-issue-item h5 {
  margin-bottom: 0.5rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
  text-transform: uppercase;
}
@screen sm {
  .home-issue-item h5 {
    min-height: 50px;
  }
}
.home-issue-item .summary {
  @apply text-sm;
  margin-bottom: 0.25rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.4;
}
@screen sm {
  .home-issue-item .summary {
    min-height: 60px;
  }
}
@screen lg {
  .home-issue-item .summary {
    min-height: 40px;
  }
}
.home-issue-item a {
  @apply text-sm font-bold;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  text-transform: uppercase;
  opacity: 0.4;
}
.home-issue-item a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.home-issue-item a span {
  margin-right: 0.75rem;
}

@screen md {
  .about-section {
    display: flex;
    flex-wrap: wrap;
  }
}
.about-section::after {
  @apply bg-primary;
  content: "";
  display: block;
  order: 2;
  width: 100%;
  height: 1px;
  margin: 3rem auto;
  transition: width 1.5s ease;
}
@screen lg {
  .about-section::after {
    margin: 4rem auto;
  }
}
.about-section.aos-init::after {
  width: 1%;
}
.about-section.aos-animate::after {
  width: 100%;
}
.about-section:last-child::after {
  display: none;
}
@screen md {
  .about-section:nth-child(odd) .about-section-col:first-child {
    order: 1;
    padding-left: 0.75rem;
  }
}
@screen lg {
  .about-section:nth-child(odd) .about-section-col:first-child {
    padding-left: 2.75rem;
  }
}
@screen md {
  .about-section:nth-child(odd) .about-section-col:last-child {
    padding-right: 0.75rem;
  }
}
@screen lg {
  .about-section:nth-child(odd) .about-section-col:last-child {
    padding-right: 2.75rem;
  }
}
@screen md {
  .about-section:nth-child(even) .about-section-col:first-child {
    padding-right: 0.75rem;
  }
}
@screen lg {
  .about-section:nth-child(even) .about-section-col:first-child {
    padding-right: 2.75rem;
  }
}
@screen md {
  .about-section:nth-child(even) .about-section-col:last-child {
    padding-left: 0.75rem;
  }
}
@screen lg {
  .about-section:nth-child(even) .about-section-col:last-child {
    padding-left: 2.75rem;
  }
}
.about-section p {
  @apply text-sm font-medium leading-relaxed;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}
@screen lg {
  .about-section p {
    @apply text-lg;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

@screen md {
  .about-section-intro {
    column-gap: 5rem;
    column-count: 2;
  }
}

@screen md {
  .about-section-col {
    width: 50%;
  }
}
.about-section-col:first-child {
  margin-bottom: 1rem;
}
@screen md {
  .about-section-col:first-child {
    margin-bottom: 0;
  }
}
.about-section-col img {
  width: 100%;
}
.about-section-col h3,
.about-section-col h4 {
  @apply font-bold;
}
.about-section-col h3 {
  @apply text-xl;
  text-transform: uppercase;
}
@screen lg {
  .about-section-col h3 {
    @apply text-10;
  }
}
.about-section-col h4 {
  @apply text-lg;
}
@screen lg {
  .about-section-col h4 {
    @apply text-7;
  }
}

.communities {
  @apply bg-primary;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url("../images/world-map.svg");
  background-repeat: no-repeat;
  background-position: 40% 160px;
  background-size: 250% auto;
}
@screen sm {
  .communities {
    padding-top: 4rem;
    background-size: 190% auto;
  }
}
@screen md {
  .communities {
    background-position: center;
    background-size: contain;
  }
}
@screen lg {
  .communities {
    padding-top: 6.5rem;
    padding-bottom: 2rem;
    background-image: none;
  }
}
.communities .hero-scroll-down {
  position: static;
  margin: auto auto 0;
}

.region-dropdowns {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}
@screen lg {
  .region-dropdowns {
    flex-direction: row;
    justify-content: space-between;
  }
}

.region-dropdown.open .dropdown-trigger {
  @apply rounded-b-none bg-white text-primary;
}
.region-dropdown.open .dropdown-trigger:focus {
  box-shadow: none;
}
.region-dropdown.open .dropdown-trigger::after {
  border-top-color: theme("colors.primary-a5");
  opacity: 1;
}

.region-dropdown-toggle {
  @apply rounded-sm text-white-a8 text-xl font-medium;
  width: 280px;
  height: 42px;
  padding-right: 1.5rem;
  padding-left: 0.75rem;
  transition: all 0.3s ease-in-out;
  text-align: left;
  white-space: nowrap;
}
@screen lg {
  .region-dropdown-toggle {
    @apply text-lg;
    width: 100%;
    height: 35px;
  }
}
@screen xl {
  .region-dropdown-toggle {
    height: 42px;
    padding-right: 2.5rem;
  }
}
@screen xl {
  .region-dropdown-toggle:hover {
    @apply bg-white text-primary;
  }
  .region-dropdown-toggle:hover::after {
    opacity: 1;
  }
}
.region-dropdown-toggle:focus {
  @apply shadow-input-white outline-none;
}
.region-dropdown-toggle::after {
  right: 7px;
  border-top: 5px solid theme("colors.white-a8");
}
@screen xl {
  .region-dropdown-toggle::after {
    right: 14px;
    border-top-color: theme("colors.primary-a5");
    opacity: 0;
  }
}

.searchable-input-container {
  @apply flex items-center text-lg font-normal;
  padding: 0.75rem 1.25rem 0.75rem 1rem;
}
.searchable-input-container .searchable-input {
  @apply bg-transparent ml-2;
  box-shadow: none;
}
.searchable-input-container .searchable-input:focus {
  outline: none;
}
.searchable-input-container .searchable-input::placeholder {
  overflow: visible;
}

.drag-map {
  cursor: grabbing;
  user-select: none;
}
.drag-map .map-container {
  transition: none;
}

.map-container {
  max-width: theme("screens.xxl");
  margin: auto;
  transform: translate(0, 0) scale(1, 1);
  transform-origin: center;
  transition: all 0.5s ease-in-out;
  filter: blur(0);
}

.map-svg {
  display: block;
  width: 100%;
  height: auto;
  transform: translateZ(0);
}
.map-svg .land {
  @apply fill-blue stroke-white-a5;
  transition: fill 0.3s ease-in-out;
  stroke-width: 0.5;
  cursor: pointer;
  pointer-events: none;
}
@screen lg {
  .map-svg .land {
    pointer-events: auto;
  }
}
.map-svg .land:hover, .map-svg .land.-active, .map-svg .land.has-body.-active, .map-svg .land.has-body:hover {
  @apply fill-white-a8;
}
.map-svg .land.non-affiliated {
  @apply fill-primary;
}
.map-svg .land.non-affiliated:not(.has-body) {
  cursor: not-allowed;
}

.country-popover {
  @apply bg-white text-primary shadow-lg;
  @apply fixed z-50;
  width: 350px;
  top: 0;
  left: 0;
  padding: 1.25rem 1.875rem;
  overflow: scroll;
}
@screen xl {
  .country-popover {
    width: 400px;
  }
}
.country-popover.-right {
  left: auto;
  right: 0;
}
.country-popover::before {
  content: "";
  position: absolute;
  top: 10px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  left: -9px;
  border-right: 10px solid theme("colors.white");
}
.country-popover.-right::before, .country-popover[x-placement=left]::before {
  left: unset;
  right: -18px;
  border-left: 10px solid theme("colors.white");
  border-right: 8px solid transparent;
}

.country-popover-content {
  @apply text-base;
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  overflow: hidden;
}
@screen xl {
  .country-popover-content {
    @apply text-lg;
  }
}

.zoom-btns {
  @apply rounded bg-white-a8;
  position: relative;
}
.zoom-btns::before {
  @apply bg-primary-a2;
  content: "";
  position: absolute;
  top: 2rem;
  left: 0.25rem;
  width: 24px;
  height: 1px;
}

.zoom-btn {
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-bottom: 1px;
  padding: 0;
}
.zoom-btn:hover {
  @apply bg-white;
}
.zoom-btn:focus {
  @apply shadow-input-white;
}
.zoom-btn::before, .zoom-btn::after {
  @apply bg-primary;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}
.zoom-btn::before {
  width: 14px;
  height: 2px;
  margin-top: -1px;
  margin-left: -7px;
}

.zoom-btn-in {
  @apply rounded-t rounded-b-none;
}
.zoom-btn-in::after {
  width: 2px;
  height: 14px;
  margin-top: -7px;
  margin-left: -1px;
}

.zoom-btn-out {
  @apply rounded-t-none rounded-b;
}

.country-container {
  height: 400px;
}
@screen md {
  .country-container {
    height: 600px;
  }
}
@screen lg {
  .country-container {
    height: 800px;
  }
}

.about-title h2 {
  @apply font-medium;
  @apply text-2xl;
  @apply uppercase;
}

.community-style .modal-container {
  @apply w-full;
  background: transparent !important;
}
.community-style .modal-close-button {
  @apply ml-auto mb-3 mr-5;
  z-index: 999999;
  position: relative !important;
}

.events-section {
  position: relative;
  z-index: 2;
}
@screen sm {
  .events-section {
    background-image: url("../images/events-bg.svg");
    background-repeat: no-repeat;
    background-position: center 105px;
    background-size: 100% auto;
  }
}
@screen md {
  .events-section {
    background-position: center 70px;
  }
}
@screen lg {
  .events-section {
    background-position: center 85px;
  }
}
@screen xl {
  .events-section {
    background-position: center 100px;
  }
}

@screen lg {
  .events-wrapper .swiper-nav-btn {
    margin-top: -1px;
  }
}
@screen xxl {
  .events-wrapper .swiper-nav-btn:focus {
    @apply shadow-btn-primary;
  }
  .events-wrapper .swiper-nav-btn svg {
    @apply fill-primary;
  }
}
@screen sm {
  .events-wrapper .swiper-container {
    height: 469px;
    padding-top: 12px;
  }
}
@screen md {
  .events-wrapper .swiper-container {
    height: 591px;
    padding-top: 24px;
  }
}
@screen lg {
  .events-wrapper .swiper-container {
    height: 572px;
    padding-top: 24px;
  }
}
@screen xl {
  .events-wrapper .swiper-container {
    height: 724px;
    padding-top: 69px;
  }
}

.events-slide {
  box-sizing: border-box;
}
.events-slide .events-box {
  @apply rounded bg-primary text-white;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 2rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
  transition: all 0.5s linear;
}
@screen xl {
  .events-slide .events-box:hover {
    transform: translateY(-24px);
  }
}
.events-slide .events-box img {
  @apply rounded-t;
  width: 100%;
}
.events-slide .events-box .content {
  @apply rounded-b bg-primary;
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  width: 100%;
  margin-top: 0px;
}
.events-slide .events-box .content::before {
  content: "";
  position: absolute;
  top: -130px;
  right: 0;
  left: 0;
  height: 160px;
  background: linear-gradient(180deg, theme("colors.primary-a0") 0%, theme("colors.primary") 100px, theme("colors.primary") 100%);
}
@screen sm {
  .events-slide .events-box .content {
    position: absolute;
    bottom: 30px;
    left: 0;
    transition: bottom 0.5s linear;
  }
}
@screen xl {
  .events-slide .events-box .content {
    bottom: 44px;
  }
}
@screen sm {
  .events-slide .events-box {
    width: 263px;
    height: 444px;
  }
}
@screen md {
  .events-slide .events-box {
    width: 344px;
    height: 543px;
  }
}
@screen lg {
  .events-slide .events-box {
    width: 297px;
    height: 524px;
  }
}
@screen xl {
  .events-slide .events-box {
    width: 360px;
    height: 610px;
  }
}
.events-slide .events-box h4 {
  @apply text-lg font-bold;
  position: relative;
  z-index: 1;
  margin-bottom: auto;
  padding: 0 1rem;
}
@screen sm {
  .events-slide .events-box h4 {
    width: 263px;
    margin: 0 auto 0.5rem;
  }
}
@screen md {
  .events-slide .events-box h4 {
    width: 344px;
  }
}
@screen lg {
  .events-slide .events-box h4 {
    width: 297px;
  }
}
@screen xl {
  .events-slide .events-box h4 {
    @apply text-xl;
    padding: 0 2rem;
    width: 360px;
  }
}
.events-slide .events-box .date {
  @apply text-xs font-medium;
  display: block;
  position: relative;
  z-index: 1;
  margin-top: 0.5rem;
  margin-bottom: 2.25rem;
  opacity: 0.8;
}
@screen lg {
  .events-slide .events-box .date {
    @apply text-sm;
  }
}
@screen xl {
  .events-slide .events-box .date {
    margin-top: 1rem;
  }
}
@screen sm {
  .events-slide.swiper-slide-active .events-box {
    width: 273px;
    height: 469px;
    transform: translate(-5px, -12px);
  }
}
@screen md {
  .events-slide.swiper-slide-active .events-box {
    width: 354px;
    height: 591px;
    transform: translate(-5px, -24px);
  }
}
@screen lg {
  .events-slide.swiper-slide-active .events-box {
    width: 307px;
    height: 572px;
    transform: translate(-5px, -24px);
  }
}
@screen xl {
  .events-slide.swiper-slide-active .events-box {
    width: 380px;
    height: 700px;
    transform: translate(-10px, -45px);
  }
}
@screen xl {
  .events-slide.swiper-slide-active .events-box:hover {
    transform: translate(-10px, -69px);
  }
}
@screen sm {
  .events-slide.swiper-slide-active .events-box .content {
    bottom: 43px;
  }
}
@screen md {
  .events-slide.swiper-slide-active .events-box .content {
    bottom: 54px;
  }
}
@screen xl {
  .events-slide.swiper-slide-active .events-box .content {
    bottom: 89px;
  }
}

.event-material-document:hover .btn-link::after {
  right: 100%;
}

.offices-section-wrapper {
  @apply bg-primary text-white;
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow: hidden;
  background-image: url("../images/worldLow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 55%;
}
@screen sm {
  .offices-section-wrapper {
    background-position: center 100px;
    background-size: auto 85%;
  }
}
@screen md {
  .offices-section-wrapper {
    padding-top: 3.75rem;
    padding-bottom: 4rem;
  }
}
@screen lg {
  .offices-section-wrapper {
    background-image: none;
    padding-bottom: 2.5rem;
  }
}
@screen lg {
  .offices-section-wrapper .container-half.-right {
    @apply pl-gutter;
  }
}
@screen xl {
  .offices-section-wrapper .container-half.-right {
    padding-left: 2.5rem;
  }
}
.offices-section-wrapper .btn {
  width: 100%;
}
@screen sm {
  .offices-section-wrapper .btn {
    width: auto;
  }
}

.offices-section {
  @apply px-gutter-1/2;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: theme("screens.sm");
  margin: 0 auto;
}
@screen md {
  .offices-section {
    @apply px-gutter;
    max-width: theme("screens.md");
  }
}
@screen lg {
  .offices-section {
    padding: 0;
    background-image: none;
  }
}

@screen lg {
  .offices-map {
    position: absolute;
    top: -100px;
    left: 50%;
    width: 1009px;
    height: 651px;
    margin-left: -430px;
    background-image: url("../images/worldLow.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }
}

.office-mark {
  @apply bg-white text-primary text-sm font-bold leading-none;
  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 50%;
  cursor: pointer;
}
@screen lg {
  .office-mark {
    display: block;
  }
}
.office-mark:hover {
  @apply bg-secondary;
}
.office-mark:focus {
  outline: none;
}
.office-mark.active {
  @apply bg-secondary;
}
.office-mark.active::before, .office-mark.active::after {
  z-index: 999;
  opacity: 1;
}
.office-mark::before, .office-mark::after {
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.office-mark::before {
  @apply rounded-4 bg-white;
  content: attr(data-city);
  display: flex;
  top: -36px;
  left: 50%;
  align-items: center;
  height: 28px;
  padding: 0 1rem;
  transform: translateX(-50%);
  white-space: nowrap;
}
.office-mark::after {
  content: "";
  top: -8px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border-top: 5px solid theme("colors.white");
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.offices-follow-links {
  display: flex;
}
.offices-follow-links a {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.offices-follow-links a:hover {
  @apply opacity-icon;
}
.offices-follow-links a + a {
  margin-left: 1rem;
}

.programs-carousel {
  margin: 0 -19px;
}
.programs-carousel .swiper-container {
  padding: 14px 19px;
}
.programs-carousel .swiper-button-prev {
  left: 31px;
}
@screen xxl {
  .programs-carousel .swiper-button-prev {
    left: -53px;
  }
}
.programs-carousel .swiper-button-next {
  right: 31px;
}
@screen xxl {
  .programs-carousel .swiper-button-next {
    right: -53px;
  }
}

.program {
  @apply bg-primary text-white;
  position: relative;
  transform-origin: center center;
  transition: all 0.3s ease-in-out;
}
@screen sm {
  .program::before {
    @apply bg-primary-a9;
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
}
@screen sm {
  .program:hover {
    z-index: 1;
    transform: scale(1.10525);
    border-radius: 2px;
  }
  .program:hover img {
    border-radius: 2px;
    opacity: 1;
  }
}
.program img {
  width: 100%;
  transition: opacity 0.3s ease-in-out;
}
@screen sm {
  .program img {
    opacity: 0.4;
  }
}
.program h4 {
  @apply font-bold;
  max-width: 280px;
  margin: 0 auto 0.75rem;
}
@screen sm {
  .program h4 {
    @apply text-lg;
  }
}
@screen md {
  .program h4 {
    @apply text-xl;
  }
}
@screen lg {
  .program h4 {
    @apply text-lg;
  }
}
@screen xl {
  .program h4 {
    @apply text-xl;
    margin-bottom: 1.5rem;
    transform: translateY(1.5rem);
    transition: all 0.3s ease-in-out;
  }
}
@screen xl {
  .program .btn {
    transform: translateY(1.5rem);
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
}
.program a::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@screen xxl {
  .programs-swiper-nav-btn:focus {
    @apply shadow-btn-primary;
  }
  .programs-swiper-nav-btn svg {
    @apply fill-primary;
  }
}

.contact-form-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
}
.contact-form-wrapper.-dark-theme {
  @apply bg-blue;
  background-image: url("../images/contact-bg-dark.svg");
}
.contact-form-wrapper.-dark-theme .form-label {
  @apply text-white;
}
.contact-form-wrapper.-dark-theme .custom-control-label-checkbox {
  @apply text-white;
}
.contact-form-wrapper.-dark-theme .custom-control-label-checkbox::before {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zm0 2H2v14h14V2z'/%3E%3C/svg%3E");
}
.contact-form-wrapper.-dark-theme .custom-control-label-checkbox::after {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM7 14l9-9-1.41-1.42L7 11.17 3.41 7.59 2 9l5 5z'/%3E%3C/svg%3E");
}
.contact-form-wrapper.-light-theme {
  @apply bg-gray-300;
  background-image: url("../images/contact-bg-light.svg");
}
@screen md {
  .contact-form-wrapper {
    padding-top: 3.5rem;
    padding-bottom: 4rem;
  }
}
@screen lg {
  .contact-form-wrapper .container-half.-left {
    @apply pr-gutter;
  }
}
@screen lg {
  .contact-form-wrapper .form {
    margin: initial;
  }
}
.contact-form-wrapper .btn[type=submit] {
  margin-top: 2rem;
}
@screen sm {
  .contact-form-wrapper .btn[type=submit] {
    margin-top: 0;
  }
}

.article-content {
  @apply text-gray-dark;
  word-break: break-word;
}
.article-content img,
.article-content svg,
.article-content video,
.article-content canvas,
.article-content audio,
.article-content iframe,
.article-content embed,
.article-content object {
  display: inline-block;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  @apply text-primary;
  margin-bottom: 0.5em;
}
.article-content p {
  margin-bottom: 1em;
}
@screen md {
  .article-content p {
    margin-bottom: 1.5em;
  }
}
@screen lg {
  .article-content p {
    margin-bottom: 2em;
  }
}
.article-content hr {
  @apply my-4 border-t border-gray-light;
}
.article-content a {
  @apply font-bold;
  display: contents;
  color: #0257D5;
  text-decoration: underline;
}
.article-content a:hover {
  text-decoration: none;
}
.article-content ol,
.article-content ul {
  margin-bottom: 2em;
  padding-left: 2.5em;
}
.article-content ol {
  list-style: decimal;
}
.article-content ul {
  list-style: disc;
}
.article-content blockquote {
  position: relative;
  margin: 1em 2rem;
  padding: 0.75rem 0 0.75rem 1rem;
}
.article-content blockquote::before {
  @apply bg-gray-light;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.25rem;
}
.article-content blockquote p {
  margin-bottom: 0;
}
.article-content blockquote p + p {
  margin-top: 1em;
}
@screen md {
  .article-content blockquote p + p {
    margin-bottom: 1.5em;
  }
}
@screen lg {
  .article-content blockquote p + p {
    margin-bottom: 2em;
  }
}
.article-content iframe {
  max-width: 100%;
}
.article-content figure {
  margin-bottom: 1.5rem;
}
@screen md {
  .article-content figure {
    margin-bottom: 2.5rem;
  }
}
.article-content figure img {
  margin-bottom: 0;
}
.article-content figcaption {
  @apply font-bold;
  margin-top: 1.125rem;
}
.article-content table,
.article-content tr,
.article-content td {
  @apply border border-gray-light;
}
.article-content table {
  max-width: 100%;
  border-collapse: collapse;
}
.article-content td {
  padding: 0.25em 0.5em;
}
.article-content b,
.article-content strong {
  @apply font-bold;
}
.article-content big {
  font-size: 125%;
}
.article-content small {
  font-size: 80%;
}
.article-content em {
  font-style: italic;
}
.article-content cite {
  font-style: italic;
}
.article-content u {
  text-decoration: underline;
}
.article-content ins {
  text-decoration: underline;
}
.article-content s {
  text-decoration: line-through;
}
.article-content del {
  text-decoration: line-through;
}
.article-content sub,
.article-content sup {
  top: 0;
  margin-bottom: 0;
  font-size: 75%;
  line-height: 1em;
}
.article-content sub {
  vertical-align: sub;
}
.article-content sup {
  vertical-align: top;
}
.article-content q::before {
  content: open-quote;
}
.article-content q::after {
  content: close-quote;
}
.article-content address {
  margin-bottom: 1em;
  font-style: italic;
}
.article-content tt {
  font-family: monospace;
}
.article-content pre {
  @apply border border-gray-light bg-light rounded text-sm;
  max-height: 600px;
  margin: 1em 0;
  padding: 0.75rem 0.5rem;
  overflow: auto;
}
.article-content code {
  @apply border border-gray-light bg-light rounded;
  display: inline-block;
  padding: 0 0.25em;
}
.article-content var {
  font-style: italic;
}

.article-share {
  padding: 1rem 0;
  border: 2px solid theme("colors.primary");
  border-right: 0;
  border-left: 0;
}
.article-share span {
  @apply text-xs;
}
@screen sm {
  .article-share span {
    @apply text-sm;
  }
}
.article-share a {
  margin: 0 0.5rem;
}
.article-share svg {
  width: 24px;
  height: auto;
}
@screen sm {
  .article-share svg {
    width: 32px;
  }
}

.about-content-tabs {
  display: flex;
  overflow-x: auto;
}
.about-content-tabs a {
  @apply text-primary text-sm;
  display: inline-block;
  padding: 0 0.5rem;
  transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-bottom: 3px solid transparent;
  line-height: 3;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}
@screen xl {
  .about-content-tabs a {
    @apply text-base;
    padding: 0 1.25rem;
  }
}
.about-content-tabs a:hover {
  border-color: theme("colors.primary");
}
.about-content-tabs a.active {
  @apply font-bold;
  border-color: theme("colors.primary");
}

.leadership-list {
  @apply -mx-gutter-1/2;
  display: flex;
  flex-wrap: wrap;
}
.leadership-list > div {
  width: 50%;
  margin-bottom: 2.25rem;
}
@screen sm {
  .leadership-list > div {
    width: 33.3333333333%;
  }
}
@screen md {
  .leadership-list > div {
    width: 25%;
  }
}
@screen lg {
  .leadership-list > div {
    width: 20%;
  }
}

.leadership-link {
  @apply p-gutter-1/2 text-gray-dark text-sm;
  display: block;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@screen lg {
  .leadership-link:hover {
    @apply shadow-lg-hover;
    transform: translateY(-1rem);
  }
  .leadership-link:hover img {
    filter: grayscale(0);
  }
}
@screen sm {
  .leadership-link {
    @apply text-base;
  }
}
@screen lg {
  .leadership-link {
    @apply text-lg;
  }
}
.leadership-link img {
  margin-bottom: 1.25rem;
  transition: filter 0.3s ease-in-out;
}
@screen lg {
  .leadership-link img {
    filter: grayscale(1);
  }
}

@media screen and (max-width: 365px) {
  .about-bio-photo {
    @apply w-full;
  }
}

.affiliate-box {
  @apply w-full p-gutter-1/2;
}
@screen sm {
  .affiliate-box {
    @apply w-1/2;
  }
}
@screen lg {
  .affiliate-box {
    @apply w-1/3;
  }
}
.affiliate-box .affiliate-box-content {
  @apply flex flex-wrap px-gutter-1/2 bg-white shadow-lg text-primary;
  height: 100%;
  padding-bottom: 2rem;
  transition: all 0.3s ease-in-out;
}
@screen md {
  .affiliate-box .affiliate-box-content {
    @apply px-gutter;
    padding-bottom: 3rem;
  }
}
.affiliate-box .affiliate-box-content:hover {
  @apply shadow-lg-hover;
  transform: translateY(-20px);
}
.affiliate-box .affiliate-box-content h4 {
  @apply my-8 text-lg font-bold uppercase;
  width: 100%;
  text-align: center;
}
@screen md {
  .affiliate-box .affiliate-box-content h4 {
    @apply text-xl;
  }
}
.affiliate-box .affiliate-box-content .affiliate-logo {
  @apply flex items-center justify-center border-b border-primary;
  width: 100%;
  height: 121px;
  padding: 1.5rem 0;
}
@screen sm {
  .affiliate-box .affiliate-box-content .affiliate-logo {
    height: 161px;
  }
}
@screen md {
  .affiliate-box .affiliate-box-content .affiliate-logo {
    height: 221px;
  }
}
.affiliate-box .affiliate-box-content .affiliate-logo img {
  max-height: 100%;
}

.affiliate-org-box {
  @apply flex flex-col gap-y-4 items-center cursor-pointer;
  transition: all 0.3s ease-in-out;
}
.affiliate-org-box:hover {
  transform: translateY(-10px);
}
.affiliate-org-box:hover .affiliate-org-img {
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.16));
}
.affiliate-org-box:hover .affiliate-org-name {
  color: #295ba5 !important;
}

.affiliate-org-img {
  @apply bg-white p-8;
  width: 75%;
}
@screen sm {
  .affiliate-org-img {
    width: 100%;
    padding: 16px;
  }
}

.affiliate-org-name {
  @apply text-sm text-black font-suisse-intl font-medium text-center;
  width: 75%;
}
@screen sm {
  .affiliate-org-name {
    width: 100%;
  }
}

.community-section a h5,
.community-section a h3 {
  @apply font-bold no-underline;
  color: #08284f;
}
.community-section a h5:hover,
.community-section a h3:hover {
  text-decoration: underline;
}

.affiliate-logo-dimensions {
  @apply rounded;
  width: 360px;
  height: 300px;
  border: 1px solid #d3d3db;
}
@screen sm {
  .affiliate-logo-dimensions {
    width: 260px;
    height: 200px;
  }
}

.leader-logo {
  @apply rounded;
  border: 1px solid #d3d3db;
  width: 135px;
  height: 177px;
}

.big-margin {
  border: 30px solid #f8f8fa;
  background: #f8f8fa;
}

.communities-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
}
.communities-video iframe,
.communities-video video {
  border: 16px solid #021934;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.event-hero-overlay {
  @apply absolute;
  background: linear-gradient(0deg, #001227 0%, rgba(0, 18, 39, 0) 89.1%);
  top: 50%;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.our-president-news-swiper-nav-btn {
  top: 0;
  margin-top: calc(33.3327586207% - 12px);
}
@screen sm {
  .our-president-news-swiper-nav-btn {
    top: 87px;
    margin-top: -12px;
  }
}
@screen md {
  .our-president-news-swiper-nav-btn {
    top: 115px;
  }
}
@screen lg {
  .our-president-news-swiper-nav-btn {
    top: 97px;
  }
}
@screen xl {
  .our-president-news-swiper-nav-btn {
    top: 90px;
  }
}
@screen xxl {
  .our-president-news-swiper-nav-btn:focus {
    @apply shadow-btn-primary;
  }
  .our-president-news-swiper-nav-btn svg {
    @apply fill-primary;
  }
}

.our-president-news-box {
  @apply block;
}
.our-president-news-box:hover .title {
  @apply underline;
}
.our-president-news-box .date {
  @apply text-gray text-sm;
  @apply mt-4 block;
}
.our-president-news-box .title {
  @apply text-primary;
  @apply mt-2;
}

.news-author-img {
  max-height: 68px;
  min-width: 68px;
  max-width: 68px;
}

.live-blog .live-label {
  color: rgb(204, 0, 0);
}
.live-blog .timeline-point {
  left: -16px;
  top: 7px;
}
@screen lg {
  .live-blog .timeline-point {
    left: -8px;
    top: 3px;
  }
}
.live-blog .kaminari-public-pagination .pagination {
  @apply flex items-center justify-center;
}
.live-blog .kaminari-public-pagination .pagination .active a {
  @apply text-primary;
}
.live-blog .kaminari-public-pagination .pagination a {
  @apply px-2 text-blue;
}
.live-blog .kaminari-public-pagination .pagination a:hover {
  @apply text-primary;
}

.videos-swiper-nav-btn {
  top: 0;
  margin-top: calc(32.4427480916% - 12px);
}
@screen sm {
  .videos-swiper-nav-btn {
    top: 85px;
    margin-top: -12px;
  }
}
@screen md {
  .videos-swiper-nav-btn {
    top: 111px;
  }
}
@screen lg {
  .videos-swiper-nav-btn {
    top: 95px;
  }
}
@screen xl {
  .videos-swiper-nav-btn {
    top: 85px;
  }
}
@screen xxl {
  .videos-swiper-nav-btn:focus {
    @apply shadow-btn-primary;
  }
  .videos-swiper-nav-btn svg {
    @apply fill-primary;
  }
}

.video-box {
  display: block;
}
.video-box:hover .play-btn {
  @apply bg-white;
}
.video-box:hover .play-btn::after {
  border-left-color: theme("colors.primary");
}
.video-box .play-btn {
  position: absolute;
  bottom: 0.75rem;
  left: 0.75rem;
}
.video-box .title {
  @apply text-primary;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.video-box .date {
  @apply text-gray text-base;
  display: block;
}

.play-btn {
  @apply border-2 border-white rounded bg-black-a2;
  width: 36px;
  height: 36px;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.play-btn::after {
  border-color: transparent transparent transparent theme("colors.white");
}

.list-video-hero {
  @apply py-8 bg-primary text-white;
  position: relative;
}
@screen md {
  .list-video-hero {
    min-height: 520px;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}

.list-video-hero-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
@screen lg {
  .list-video-hero-img {
    width: calc(50% + 145px);
  }
}
@screen xl {
  .list-video-hero-img {
    width: calc(50% + 277px);
  }
}
.list-video-hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list-video-hero-gradient-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.list-video-hero-gradient-wrapper .list-video-hero-gradient {
  height: 100%;
  background: linear-gradient(270deg, theme("colors.primary-a2") 0%, theme("colors.primary") 100%);
}
@screen lg {
  .list-video-hero-gradient-wrapper .list-video-hero-gradient {
    background: linear-gradient(270deg, transparent 0%, theme("colors.primary") 100%);
  }
}

.video-categories a {
  @apply text-primary text-base;
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 0 2rem 0 0.75rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
  text-transform: uppercase;
}
.video-categories a:focus, .video-categories a:hover {
  opacity: 1;
  text-decoration: none;
}
.video-categories a.active {
  @apply font-bold;
  padding-left: 1.75rem;
  opacity: 1;
}
.video-categories a.active::before, .video-categories a.active::after {
  display: block;
}
.video-categories a::before {
  content: "";
  @apply bg-blue;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.25rem;
}
.video-categories a::after {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.625rem;
  background-image: url("../images/icons/arrow-category.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.video-search-form {
  position: relative;
  margin-bottom: 2rem;
}
@screen md {
  .video-search-form {
    margin-bottom: 0;
  }
}
.video-search-form svg {
  position: absolute;
  top: 50%;
  left: 1rem;
  margin-top: -0.75rem;
}
.video-search-form input {
  padding-left: 3.5rem;
}
.video-search-form input:focus {
  @apply bg-light;
}

.video-player {
  height: 240px;
  overflow: hidden;
  border-radius: 0 !important;
  vertical-align: top;
}
@screen sm {
  .video-player {
    height: 310px;
  }
}
@screen md {
  .video-player {
    height: 400px;
  }
}
@screen lg {
  .video-player {
    height: 520px;
  }
}

.video-iframe {
  position: relative;
  overflow: hidden;
}
.video-iframe::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56.25%;
}
.video-iframe iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.featured-issue-wrapper {
  background: linear-gradient(to bottom, theme("colors.primary") 0%, theme("colors.primary") 50%, transparent 50%, transparent 100%);
}

.issue-hero-img-bg::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 18.4210526%;
}
.issue-hero-img-bg::after {
  @apply bg-primary;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.issue-hero-img-space-hack::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 18.4210526%;
}

.featured-issue {
  @apply text-white;
  display: flex;
  align-items: center;
  padding: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}
@screen sm {
  .featured-issue {
    min-height: 320px;
  }
}
@screen lg {
  .featured-issue {
    min-height: 420px;
  }
}
.featured-issue > div {
  max-width: 594px;
  margin: 0 auto;
}

@screen md {
  .list-issue-item .txt-holder {
    padding-top: 0;
  }
}
.list-issue-item .summary {
  @apply leading-relaxed;
}

.issue-key-facts ul,
.issue-key-facts ol {
  counter-reset: fact;
}
.issue-key-facts li {
  display: flex;
  margin-bottom: 1rem;
  @apply text-sm font-medium;
}
@screen md {
  .issue-key-facts li {
    @apply text-base;
  }
}
@screen xl {
  .issue-key-facts li {
    @apply text-lg;
  }
}
@screen lg {
  .issue-key-facts li {
    margin-bottom: 2rem;
  }
}
.issue-key-facts li::before {
  @apply border border-white rounded-full text-sm font-medium;
  content: counter(fact);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  padding-left: 1px;
  counter-increment: fact;
}
@screen xl {
  .issue-key-facts li::before {
    margin-right: 1rem;
  }
}

.issue-about-section h1,
.issue-about-section h2,
.issue-about-section h3,
.issue-about-section h4 {
  @apply text-primary font-medium capitalize;
}
.issue-about-section .block-button {
  justify-content: flex-start;
  align-items: flex-start;
}
.issue-about-section .block-button .btn-outline-primary {
  color: #08284f !important;
}
.issue-about-section .block-button .btn-outline-primary:hover {
  color: white !important;
}

.focus-area-item {
  aspect-ratio: 1.5/1;
}
.focus-area-item:hover .focus-area-item--read-more {
  max-height: 2rem;
  margin-top: 1rem;
}
.focus-area-item:hover .focus-area-item--gradient {
  opacity: 1;
}

.focus-area-item--read-more {
  max-height: 0;
  overflow: hidden;
  transition: margin 140ms, max-height 140ms;
}

.focus-area-item--gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #08284f 100%);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 140ms;
}

.focus-area-hover-state:hover {
  @apply font-bold;
}

.featured-campaign {
  @apply bg-dark;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 260px;
  text-align: center;
}
@screen sm {
  .featured-campaign {
    min-height: 410px;
  }
}
@screen md {
  .featured-campaign {
    min-height: 535px;
  }
}
@screen lg {
  .featured-campaign {
    min-height: 340px;
  }
}
@screen xl {
  .featured-campaign {
    min-height: 420px;
  }
}
.featured-campaign img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
}
.featured-campaign .content {
  position: relative;
  z-index: 1;
  padding: 1rem;
}
@screen sm {
  .featured-campaign .content {
    padding: 2rem;
  }
}
.featured-campaign .title {
  @apply text-white;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
@screen sm {
  .featured-campaign .title {
    margin-bottom: 3.25rem;
  }
}
.featured-campaign .title a:hover {
  text-decoration: underline;
}

.campaign-box {
  @apply bg-white shadow-md;
  border-radius: 0.125rem;
}
@screen md {
  .campaign-box {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.campaign-box img {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.campaign-box .content {
  padding: 1.5rem 1rem;
}
@screen sm {
  .campaign-box .content {
    padding: 2rem 1.5rem;
  }
}
@screen md {
  .campaign-box .content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
.campaign-box .title {
  @apply font-bold;
}
@screen md {
  .campaign-box .title {
    margin-bottom: auto;
  }
}
.campaign-box .btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@screen md {
  .campaign-box .btn {
    margin-top: 2rem;
  }
}
.campaign-box svg {
  fill: theme("colors.gray-dark");
}

.campaigns-menu-link {
  @apply text-primary text-base;
  display: inline-block;
  position: relative;
  margin-bottom: -1px;
  padding: 0.5rem 1.5rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
  text-transform: uppercase;
}
.campaigns-menu-link:hover {
  opacity: 1;
}
.campaigns-menu-link:hover::after {
  @apply bg-blue;
}
.campaigns-menu-link.active {
  @apply font-bold;
  opacity: 1;
}
.campaigns-menu-link.active::after {
  @apply bg-blue;
}
.campaigns-menu-link::after {
  @apply bg-transparent;
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: background-color 0.3s ease-in-out;
}

.campaign-hero-img::before {
  padding-top: 75%;
}

.facts-categories-wrapper {
  margin-bottom: 2rem;
  background: linear-gradient(to bottom, theme("colors.primary") 0%, theme("colors.primary") 58px, theme("colors.white") 58px, theme("colors.white") 100%);
}
@screen sm {
  .facts-categories-wrapper {
    background: linear-gradient(to bottom, theme("colors.primary") 0%, theme("colors.primary") 70px, theme("colors.white") 70px, theme("colors.white") 100%);
  }
}
@screen md {
  .facts-categories-wrapper {
    background: linear-gradient(to bottom, theme("colors.primary") 0%, theme("colors.primary") 80px, theme("colors.white") 80px, theme("colors.white") 100%);
  }
}
.facts-categories-wrapper ul {
  @apply -mx-gutter-1/2;
  overflow-x: scroll;
}
@screen sm {
  .facts-categories-wrapper ul {
    margin: 0;
  }
}
.facts-categories-wrapper li {
  padding: 0 0.5rem;
}
@screen sm {
  .facts-categories-wrapper li {
    padding: 0;
  }
}
@screen xl {
  .facts-categories-wrapper li:not(:last-child) {
    margin-right: 7.75rem;
  }
}

.facts-category-link {
  @apply text-dark-a7 font-bold;
  display: block;
  width: 100px;
  transition: color 0.3s ease-in-out;
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
}
@screen sm {
  .facts-category-link {
    @apply text-xs;
    width: 120px;
  }
}
@screen md {
  .facts-category-link {
    width: 140px;
  }
}
.facts-category-link:focus {
  text-decoration: none;
}
.facts-category-link:hover, .facts-category-link.active {
  @apply text-blue;
}
.facts-category-link:hover svg .facts-icon-stroke, .facts-category-link.active svg .facts-icon-stroke {
  stroke: theme("colors.blue");
}
.facts-category-link:hover svg .facts-icon-fill, .facts-category-link.active svg .facts-icon-fill {
  fill: theme("colors.blue");
}
.facts-category-link svg {
  display: block;
  max-width: 100%;
  height: auto;
}
.facts-category-link span {
  @apply bg-white;
  display: block;
  padding: 0.5rem 1rem 0;
}
.facts-category-link .facts-icon-bg {
  transition: fill 0.3s ease-in-out;
}
.facts-category-link .facts-icon-stroke {
  transition: stroke 0.3s ease-in-out;
}
.facts-category-link .facts-icon-fill {
  transition: fill 0.3s ease-in-out;
}
.facts-category-link .facts-icon-opacity {
  transition: opacity 0.3s ease-in-out;
}
.facts-category-link .facts-icon-stroke.facts-icon-fill {
  transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
}

.fact-box {
  @apply rounded shadow-md;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}
.fact-box:hover {
  transform: translateY(-10px);
}
.fact-box:hover .overlay {
  opacity: 1;
}
.fact-box:hover img {
  opacity: 0.2;
}
.fact-box .image {
  @apply rounded-t bg-dark;
  position: relative;
  overflow: hidden;
}
.fact-box .image::before {
  padding-top: 83.3333333333%;
}
.fact-box img {
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.fact-box .overlay {
  @apply text-white;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.fact-box .btn {
  height: 32px;
}
.fact-box .btn:hover svg {
  @apply fill-primary;
}
.fact-box .btn:hover .triangle::after {
  border-color: transparent transparent transparent theme("colors.primary");
}
.fact-box .btn svg {
  @apply fill-white;
  transition: fill 0.3s ease-in-out;
}
.fact-box .triangle {
  width: 12px;
  height: 12px;
}
.fact-box .triangle::after {
  border-width: 5px 0 5px 8.7px;
  border-color: transparent transparent transparent theme("colors.white");
}
.fact-box .annotation {
  @apply rounded-b bg-white text-primary text-sm font-medium;
  flex: 1;
  padding: 1.5rem 1.25rem;
}
.fact-box .annotation a {
  text-decoration: underline;
}

.fact-modal {
  @apply pt-16 pb-12 px-gutter-1/2 bg-white-a96;
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
@screen md {
  .fact-modal {
    @apply px-gutter;
  }
}
.fact-modal .close-icon {
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
}
@screen lg {
  .fact-modal .close-icon {
    top: 32px;
    right: 32px;
  }
}
.fact-modal .close-icon::before, .fact-modal .close-icon::after {
  @apply bg-primary;
  width: 38px;
  margin-left: -19px;
}

.fact-modal-content {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}
.fact-modal-content .media {
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px theme("colors.black-a2");
}
.fact-modal-content .media.video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.fact-modal-content img {
  width: 100%;
}
.fact-modal-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fact-modal-content .annotation {
  @apply text-primary text-base font-bold;
}
.fact-modal-content .annotation a {
  text-decoration: underline;
}

.fact-share-btn {
  @apply rounded-sm;
  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  transition: opacity 0.3s ease-in-out;
}
@screen sm {
  .fact-share-btn {
    max-width: 160px;
  }
}
.fact-share-btn:not(:last-child) {
  margin-right: 1rem;
}
.fact-share-btn:hover {
  opacity: 0.6;
}
.fact-share-btn.facebook {
  @apply bg-facebook;
}
.fact-share-btn.twitter {
  @apply bg-twitter;
}
.fact-share-btn.mail {
  @apply bg-mail;
}

.publications-header {
  @apply py-8 bg-primary text-white;
  position: relative;
}
@screen md {
  .publications-header {
    min-height: 360px;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}

.publications-search-form {
  @apply pl-6;
  max-height: 72px;
}
.publications-search-form button {
  @apply h-full px-10 py-3 rounded-r-md;
  min-height: 72px;
}
.publications-search-form button span {
  @apply text-xl font-primary font-medium;
}
.publications-search-form input::placeholder {
  @apply font-medium text-xl;
}

.publications-grid .publictaions-col {
  @apply rounded-lg;
  min-height: 264px;
}

.publications-dropdown-content {
  @apply hidden absolute z-10 bg-white;
  transform-origin: top center;
  animation: slideDown 300ms ease-in-out forwards;
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.publications-dropdown-content.show {
  @apply flex flex-col w-full p-4;
}

.dropdown-toggler {
  @apply cursor-pointer w-full flex items-center pr-4;
  @apply mt-2 border-gray-light border rounded-md text-sm;
  min-height: 40px;
  max-height: 42px;
}
.dropdown-toggler::after {
  right: 7px;
  border-top: 5px solid theme("colors.white-a8");
}
@screen xl {
  .dropdown-toggler::after {
    right: 14px;
    border-top-color: theme("colors.primary-a5");
    opacity: 0;
  }
}

.dropdown-search-container {
  @apply py-1 px-2 border border-gray-light rounded-sm;
  min-height: 24px;
}

.dropdown-item {
  @apply text-sm p-5 font-medium font-primary my-2;
  @apply text-primary opacity-80 rounded-md flex items-center;
  max-height: 32px;
}
.dropdown-item:hover {
  @apply text-white opacity-100 cursor-pointer;
  background-color: #0F5DA9;
}

.publications-searchable-dropdown .btn-arrow {
  @apply ml-auto;
}
.publications-searchable-dropdown .btn-arrow.rotated {
  transform: rotate(180deg);
}

.download-pdf {
  position: absolute;
  @apply top-0 left-0 w-full h-full;
  @apply opacity-0;
  @apply transition-opacity;
  @apply flex items-center justify-center;
  @apply bg-black bg-opacity-40;
  @apply text-white;
}
.download-pdf svg {
  @apply transform scale-0;
  @apply transition-transform;
}
.download-pdf:hover {
  @apply opacity-100;
}
.download-pdf:hover svg {
  @apply scale-100;
}

@tailwind utilities;
.rotate-rows > div:nth-child(even) {
  flex-direction: row-reverse !important;
}

.video-frame {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.video-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.jdcorps-resource {
  height: 0;
  overflow: hidden;
  padding-top: 69.5652173913%;
  background: white;
  position: relative;
}
.jdcorps-resource a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
.jdcorps-resource a img:not(.play-button) {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.jdcorps-resource .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  cursor: pointer;
  opacity: 0.75;
}
.jdcorps-resource:hover .play-button {
  opacity: 1;
}

.jdcorps-program-session {
  display: grid;
}
@screen md {
  .jdcorps-program-session {
    grid-template-columns: 7.5rem 1fr 14rem;
  }
}

.jdcorps-leadership-linked-item {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@screen md {
  .jdcorps-leadership-linked-item:hover {
    @apply shadow-lg-hover;
    transform: translateY(-1rem);
  }
}

.statements-category-filter li {
  @apply text-primary text-sm uppercase rounded-full border border-primary px-3 py-1;
  @apply transition-all ease-out;
  @apply cursor-pointer mr-4 mb-4;
}
@screen md {
  .statements-category-filter li {
    @apply text-base;
  }
}
.statements-category-filter li:hover {
  @apply bg-primary text-white;
}
.statements-category-filter li.active {
  @apply bg-primary text-white;
}

.jdcorps-diplomacy-cols {
  @apply flex flex-col;
}
@screen lg {
  .jdcorps-diplomacy-cols {
    display: grid;
    grid-template-columns: 75% 25%;
  }
}

.jdcorps-diplomacy-search-form {
  width: 264px;
}
@screen lg {
  .jdcorps-diplomacy-search-form {
    width: auto;
  }
}
.jdcorps-diplomacy-search-form .search-bottom {
  bottom: 12px;
}
.jdcorps-diplomacy-search-form .form-background {
  background-color: #E5E5E5;
}
.jdcorps-diplomacy-search-form input::placeholder {
  color: #08284F;
  opacity: 0.5;
}

.fellows {
  @apply -m-4;
}
.fellows img {
  @apply p-4 w-full;
}
@screen sm {
  .fellows {
    @apply flex flex-wrap;
  }
  .fellows > div {
    @apply w-1/2;
  }
}
@screen md {
  .fellows > div {
    @apply w-1/3;
  }
}

.fellowship-resource {
  background-color: rgba(0, 0, 0, 0.05);
  @apply py-4 px-3;
}
@screen md {
  .fellowship-resource {
    @apply py-6;
  }
}
.fellowship-resource:hover {
  background-color: rgba(0, 0, 0, 0);
  @apply border border-primary;
}
.fellowship-resource:hover .download-resource-icons span:first-child {
  transform: scale(0.8);
  opacity: 0;
}
.fellowship-resource:hover .download-resource-icons span:last-child {
  transform: translateY(0);
  transition-delay: 0.2s;
  opacity: 1;
}

.fellowship-events-carousel .swiper-slide img {
  height: 260px;
}
@screen lg {
  .fellowship-events-carousel .swiper-slide img {
    height: 400px;
  }
}

.fellowship-events .swiper-button-next, .fellowship-events .swiper-button-prev {
  top: 40%;
}
@screen xxl {
  .fellowship-events .swiper-nav-btn:focus {
    @apply shadow-btn-primary;
  }
  .fellowship-events .swiper-nav-btn svg {
    @apply fill-primary;
  }
}

.theodor-herzl-award-hero {
  background-image: url("../images/theodor_herzl_award/logo-bg-white.svg");
  background-repeat: no-repeat;
  background-position: -444px 222px;
}

.theodor-herzl-award-recipients {
  position: relative;
}
.theodor-herzl-award-recipients::before {
  content: " ";
  position: absolute;
  top: 222px;
  right: -444px;
  width: 888px;
  height: 1483px;
  background-image: url("../images/theodor_herzl_award/logo-bg-gold.svg");
  background-repeat: no-repeat;
  z-index: 0;
}

.theodor-herzl-award-cta-box {
  background: linear-gradient(135deg, #213d61, #213d61 50%, #29476e 50%, #29476e);
}
@screen md {
  .theodor-herzl-award-cta-box {
    background: linear-gradient(135deg, #213d61, #213d61 50%, #29476e 50%, #29476e);
  }
}
@screen lg {
  .theodor-herzl-award-cta-box {
    background: linear-gradient(139.5deg, #213d61, #213d61 50%, #29476e 50%, #29476e);
  }
}
@screen xl {
  .theodor-herzl-award-cta-box {
    background: linear-gradient(145.5deg, #213d61, #213d61 50%, #29476e 50%, #29476e);
  }
}

.podcast-text {
  color: #85858A;
}

.podcast-time {
  color: #919194;
}

.podcast-show-sm-img {
  min-width: 80px;
  min-height: 80px;
}

.episode-list-item {
  background-color: #F8F8FA;
}
.episode-list-item img {
  max-width: 118px;
  max-height: 118px;
}

.see-all-btn {
  background-color: #F8F8FA;
  color: #295BA5;
}

.podcasts-list h1 {
  font-size: 40px;
}

.pretty-content {
  @apply leading-relaxed;
}
.pretty-content a {
  text-decoration: underline;
}
.pretty-content h1,
.pretty-content h2,
.pretty-content h3,
.pretty-content h4,
.pretty-content h5,
.pretty-content h6 {
  margin-bottom: 0.5em;
}
.pretty-content p {
  margin-bottom: 1em;
}
@screen md {
  .pretty-content p {
    margin-bottom: 1.5em;
  }
}
@screen lg {
  .pretty-content p {
    margin-bottom: 2em;
  }
}
.pretty-content ul,
.pretty-content ol {
  margin: 1.5rem 0;
  padding-left: 1.75rem;
}
.pretty-content ul li + li,
.pretty-content ol li + li {
  margin-top: 0.5rem;
}
.pretty-content ul {
  list-style: circle;
}
.pretty-content ol {
  list-style: decimal;
}
.pretty-content blockquote {
  margin: 2.5rem 0;
  border-left: 1px solid;
  padding-left: 2rem;
  font-style: italic;
}

.hyperlink-color a {
  color: #0257D5;
  text-decoration: underline;
}
.hyperlink-color a:hover {
  @apply no-underline;
}

.halle-body {
  @apply text-lg leading-loose;
}
.halle-body li {
  @apply mb-6 pl-8;
  @apply relative;
}
.halle-body li::before {
  content: "";
  @apply bg-blue;
  @apply w-2 h-2 inline-block;
  @apply rounded-full;
  @apply absolute left-0;
  @apply mt-3;
}

.halle-video {
  @apply relative;
  @apply inline-block overflow-hidden;
}
.halle-video svg {
  @apply absolute;
  width: 4rem;
  height: 4rem;
  top: 50%;
  left: 50%;
  margin-left: -2rem;
  margin-top: -2rem;
  z-index: 10;
}
.halle-video img {
  transition: transform 200ms;
}
.halle-video:hover img {
  transform: scale(1.1);
}
.halle-video:hover svg {
  transform: scale(1);
}

.halle-rich-text p {
  @apply mb-4;
}

.halle-separator {
  border-top: 1px solid #CCD3DC;
}
@screen md {
  .halle-separator {
    width: 80%;
    margin: 0 auto;
  }
}

.halle-stream {
  @apply relative;
  top: -2rem;
}
@screen md {
  .halle-stream {
    top: -8rem;
  }
}

.on-campus-page .on-campus--resource p {
  @apply text-gray-dark text-lg leading-relaxed mb-4;
}
@screen sm {
  .on-campus-page .on-campus--resource p {
    @apply text-base;
  }
}
@screen md {
  .on-campus-page .on-campus--resource p {
    @apply text-lg;
  }
}

.speakers-bureau {
  @apply z-10;
}
.speakers-bureau .content p + p {
  @apply mt-8;
}
.speakers-bureau .stat {
  @apply rounded-full;
  @apply bg-primary;
  @apply flex flex-col justify-center items-center;
  border: 2px solid #fae000;
}
.speakers-bureau .stat-number {
  font-weight: 300;
  line-height: 1;
}
.speakers-bureau .stat-label {
  @apply uppercase;
  font-weight: 500;
}
.speakers-bureau .stats-wrap {
  padding-top: 36rem;
}
@screen lg {
  .speakers-bureau .stats-wrap {
    padding-top: 0;
  }
}
.speakers-bureau .stat-sm {
  position: absolute;
  width: 5.5rem;
  height: 5.5rem;
  left: -3.9rem;
  top: -1.5rem;
  z-index: -1;
}
.speakers-bureau .stat-sm .stat-number {
  font-size: 1.875rem;
}
.speakers-bureau .stat-sm .stat-label {
  font-size: 0.5625rem;
}
.speakers-bureau .stat-md {
  width: 10.375rem;
  height: 10.375rem;
}
.speakers-bureau .stat-md .stat-number {
  font-size: 3.75rem;
}
.speakers-bureau .stat-md .stat-label {
  font-size: 0.875rem;
}
.speakers-bureau .stat-lg {
  width: 22.625rem;
  height: 22.625rem;
}
.speakers-bureau .stat-lg .stat-number {
  font-size: 8.125rem;
}
.speakers-bureau .stat-lg .stat-label {
  font-size: 1.5rem;
}
.speakers-bureau .stat-globe-wrap {
  @apply absolute right-0 bottom-0 h-auto;
  @apply hidden;
  width: 30%;
  max-width: 24rem;
}
@screen lg {
  .speakers-bureau .stat-globe-wrap {
    @apply block;
  }
}
@screen xxl {
  .speakers-bureau .stat-globe-wrap {
    max-width: 36rem;
  }
}
.speakers-bureau .stat-lg-wrap {
  @apply absolute;
  z-index: 9;
  bottom: 46%;
  right: 22%;
}
@screen xl {
  .speakers-bureau .stat-lg-wrap {
    right: 30%;
  }
}
@screen xxl {
  .speakers-bureau .stat-lg-wrap {
    right: 42%;
    bottom: 52%;
  }
}
.speakers-bureau .stat-md-wrap {
  @apply absolute;
  right: -2.5rem;
  top: -6.5rem;
  z-index: -1;
}
@screen lg {
  .speakers-bureau .stat-md-wrap {
    right: -4.25rem;
    top: -4.75rem;
  }
}
.speakers-bureau .stats-globe {
  @apply relative z-10 max-w-none w-full;
}

.banner-star {
  @apply absolute bg-gray-darker z-50;
  right: 45%;
  bottom: -10px;
}
@screen sm {
  .banner-star {
    right: 50%;
  }
}
@screen lg {
  .banner-star {
    right: 45%;
  }
}

.un_antisemitism .separator {
  border-top: 1px solid #ccd3dc;
  height: 0;
}
.un_antisemitism .stream-section {
  @apply relative;
  top: -2rem;
}
@screen md {
  .un_antisemitism .stream-section {
    top: -4rem;
  }
}
@screen lg {
  .un_antisemitism .stream-section {
    top: -7rem;
  }
}
.un_antisemitism .stream-section-content {
  @apply mx-auto mt-12 mb-6;
  @apply text-lg text-gray-dark leading-relaxed;
}
@screen md {
  .un_antisemitism .stream-section-content {
    @apply w-2/3;
  }
}
.un_antisemitism .speakers .swiper-wrapper {
  @apply items-stretch;
}
.un_antisemitism .speakers .swiper-slide {
  @apply h-auto;
}
.un_antisemitism .speakers .swiper-nav-btn {
  top: 30%;
}
.un_antisemitism .speaker-bio-details {
  background: #071e3b;
  @apply pt-16 pb-24 mb-8;
  @apply text-white;
  @apply font-medium;
}
.un_antisemitism .speakers-section {
  background: #f4f4f4;
}

.webtalks-page h2,
.webtalks-page h3,
.webtalks-page p,
.webtalks-page .upcoming--body {
  color: #333;
}
.webtalks-page .inner-hero {
  min-height: 400px;
}

@screen sm {
  .jal-video {
    @apply grid grid-cols-2 gap-8;
  }
  .jal-video.-reverse .jal-video-video {
    @apply col-start-2 row-start-1;
  }
  .jal-video.-reverse .jal-video-description {
    @apply col-start-1 row-start-1;
  }
}
.jal-video + .jal-video {
  @apply mt-16;
}
.jal-video .jal-video-description h3 {
  @apply text-lg leading-casual text-primary font-medium;
  @apply mb-4;
}
@screen sm {
  .jal-video .jal-video-description h3 {
    @apply text-xl;
  }
}
@screen lg {
  .jal-video .jal-video-description h3 {
    @apply text-2xl;
  }
}
.jal-video .jal-video-description .pretty-content {
  @apply text-base leading-relaxed text-gray-dark;
}
@screen lg {
  .jal-video .jal-video-description .pretty-content {
    @apply text-lg;
  }
}

.jal-video-video {
  @apply mb-4;
}
@screen sm {
  .jal-video-video {
    @apply mb-0;
  }
}
.jal-video-video > img {
  @apply w-full h-full;
  object-fit: cover;
}

.jal-hero-gradient {
  @apply absolute top-0 left-0 right-0;
  background: linear-gradient(180deg, #09284f 0%, rgba(7, 30, 59, 0) 100%);
  height: 25rem;
}

.jal-section > .container {
  @apply pt-8 pb-16;
}
@screen sm {
  .jal-section > .container {
    @apply pt-12 pb-20;
  }
}
@screen lg {
  .jal-section > .container {
    @apply pt-16 pb-24;
  }
}
.jal-section h2 {
  @apply text-xl font-medium text-primary;
  @apply mb-12;
}
@screen sm {
  .jal-section h2 {
    @apply text-2xl;
  }
}
@screen lg {
  .jal-section h2 {
    @apply text-3xl;
  }
}

.jal2-section1 .pretty-content {
  @apply text-lg;
}

.women_in_contemporary_judaism .stream-section {
  @apply relative;
  top: -2rem;
}
@screen md {
  .women_in_contemporary_judaism .stream-section {
    top: -4rem;
  }
}
@screen lg {
  .women_in_contemporary_judaism .stream-section {
    top: -7rem;
  }
}
.women_in_contemporary_judaism .stream-section-content {
  @apply mx-auto mt-12 mb-6;
}
.women_in_contemporary_judaism .pretty-content {
  @apply text-lg text-gray-dark leading-relaxed;
}
.women_in_contemporary_judaism .subscribe-box {
  @apply p-1 pl-6 flex flex-col;
}
@screen sm {
  .women_in_contemporary_judaism .subscribe-box {
    @apply bg-white flex-row overflow-hidden rounded-full;
  }
}
.women_in_contemporary_judaism .subscribe-box input {
  @apply bg-white rounded-full;
  @apply text-black text-base outline-none flex-grow;
  @apply px-6 py-3 mb-3;
}
@screen sm {
  .women_in_contemporary_judaism .subscribe-box input {
    @apply rounded-none;
    @apply p-0;
    @apply m-0;
  }
}
.women_in_contemporary_judaism .speakers .swiper-wrapper {
  @apply items-stretch;
}
.women_in_contemporary_judaism .speakers .swiper-slide {
  @apply h-auto;
}
.women_in_contemporary_judaism .speakers .swiper-nav-btn {
  top: 30%;
}
.women_in_contemporary_judaism .speaker-bio-details {
  @apply bg-primary-dark;
  @apply pt-16 pb-24 mb-8;
  @apply text-white;
  @apply font-medium;
}

.ihra .article-content a {
  @apply text-primary;
}
.ihra .article-content blockquote {
  margin: 2.5rem 0;
  border-left: 1px solid;
  padding-left: 2rem;
  font-style: italic;
}
.ihra .article-content blockquote::before {
  display: none;
}
.ihra .reveal-button {
  @apply text-white underline capitalize text-base font-medium outline-none pt-2;
}
@screen sm {
  .ihra .reveal-button {
    @apply text-lg pt-4;
  }
}

.ihra-inner-hero {
  min-height: 300px;
}
@screen md {
  .ihra-inner-hero {
    min-height: 350px;
  }
}
@screen lg {
  .ihra-inner-hero {
    min-height: 400px;
  }
}
@screen xl {
  .ihra-inner-hero {
    min-height: 450px;
  }
}

.ihra-next-pages:hover .btn-link::after {
  height: 4px;
}
.ihra-next-pages .page-link:hover .arrow-left {
  animation: slide 1.5s infinite;
}
@keyframes slide {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-10px, 0);
  }
}
.ihra-next-pages .page-link:hover .arrow-right {
  animation: slide2 1.5s infinite;
}
@keyframes slide2 {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 0);
  }
}

.ihra-new-page h1 {
  @apply text-2xl text-center;
}
@screen md {
  .ihra-new-page h1 {
    @apply text-3xl;
  }
}
@screen lg {
  .ihra-new-page h1 {
    font-size: 68px;
  }
}
.ihra-new-page h4 {
  @apply text-lg;
}
@screen md {
  .ihra-new-page h4 {
    @apply text-xl;
  }
}
@screen lg {
  .ihra-new-page h4 {
    font-size: 27px;
  }
}

.wjc-nominations-hero {
  min-height: 500px;
}
.wjc-nominations-hero.inner-hero {
  align-items: center;
}
@screen xl {
  .wjc-nominations-hero {
    max-height: 700px;
  }
}
.wjc-nominations-hero .assembly-logo {
  width: 160px;
}
@screen sm {
  .wjc-nominations-hero .assembly-logo {
    width: 220px;
  }
}
@screen lg {
  .wjc-nominations-hero .assembly-logo {
    width: 264px;
  }
}
.wjc-nominations-hero.-bio {
  min-height: unset;
  max-height: unset;
  height: auto;
}
.wjc-nominations-hero.-bio .inner-hero-container {
  @apply pb-10;
}

.wjc-nominations-topics ul {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.wjc-nominations-topics ul button {
  @apply w-full;
  @apply text-base text-left text-primary opacity-70 uppercase;
  @apply pl-6 py-3;
  @apply flex justify-between items-center outline-none;
}
.wjc-nominations-topics ul button svg {
  width: 10%;
  @apply pr-3;
}
.wjc-nominations-topics ul button:hover {
  @apply underline;
}
.wjc-nominations-topics li {
  @apply border-l-4 border-transparent outline-none;
}
.wjc-nominations-topics li svg {
  @apply opacity-0;
}
.wjc-nominations-topics li.active {
  @apply border-l-4 border-blue outline-none;
}
.wjc-nominations-topics li.active svg {
  @apply opacity-100;
}
.wjc-nominations-topics li.active button {
  @apply text-primary text-opacity-100 font-bold no-underline;
}

.wjc-nominations-aside-title {
  @apply absolute w-full bg-white -top-16;
}

.wjc-nominations-link {
  @apply p-gutter-1/2;
  display: block;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.wjc-nominations-link:hover {
  @apply shadow-lg-hover;
  transform: translateY(-1rem);
}
.wjc-nominations-link:hover img {
  filter: grayscale(0);
}
.wjc-nominations-link img {
  transition: filter 0.3s ease-in-out;
  filter: grayscale(1);
  margin-bottom: 0.5rem;
}

.nominations-bio-logo svg {
  width: 170px;
}

@screen sm {
  .wjc-nominations-bio {
    min-height: 15rem;
  }
}

.pledge-hero-section .inner-hero-container {
  display: block;
  min-height: 800px;
}
@screen sm {
  .pledge-hero-section .inner-hero-container {
    min-height: 800px;
  }
}
@screen md {
  .pledge-hero-section .inner-hero-container {
    min-height: 1000px;
  }
}
@screen lg {
  .pledge-hero-section .inner-hero-container {
    min-height: 1100px;
  }
}

.pledge-hero-gradient {
  @apply absolute bottom-0 left-0 right-0;
  height: 35rem;
  background: linear-gradient(180deg, rgba(8, 40, 79, 0) 0%, #08284f 60%, #08284f 100%);
}

.pledge-about-reveal-content .reveal-button {
  @apply underline uppercase;
}

.country-wrapper {
  @apply border border-transparent outline-none;
  padding: 2px;
}
.country-wrapper:hover {
  @apply bg-blue-a05;
  @apply border border-blue-a5;
  @apply rounded;
}

.countries-group-items {
  @apply grid grid-cols-1 gap-4;
}
@screen md {
  .countries-group-items {
    @apply grid-cols-2 gap-6;
  }
}
@screen lg {
  .countries-group-items {
    @apply grid-cols-3;
  }
}
@screen xl {
  .countries-group-items {
    @apply grid-cols-4;
  }
}
@screen xxl {
  .countries-group-items {
    @apply grid-cols-5;
  }
}

.selected-triangle-up {
  position: absolute;
  bottom: 0;
  visibility: hidden;
  width: 100%;
  height: 0;
  padding-left: 10%;
}

.selected-triangle-up:after {
  position: relative;
  top: 4px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: 40px;
  @apply border-b border-primary;
  border-bottom: 18px solid;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
}

.unhrc-hero {
  min-height: 600px;
}
@media screen and (max-width: 400px) {
  .unhrc-hero {
    min-height: 750px;
  }
}
@screen sm {
  .unhrc-hero {
    min-height: 500px;
  }
}
@screen lg {
  .unhrc-hero {
    max-height: 600px;
  }
}
@screen xl {
  .unhrc-hero {
    max-height: 700px;
  }
}

.session-btn {
  @apply text-left py-4 pr-3 pl-5;
}
.session-btn h1 {
  @apply font-bold font-primary text-base text-white;
}
.session-btn .session-date {
  @apply font-primary font-medium text-xs;
}
.session-btn svg {
  @apply hidden;
}
.session-btn.active {
  @apply border-l-4 border-white bg-left;
  @apply bg-white bg-opacity-20;
}
.session-btn.active svg {
  @apply block ml-auto;
}

.pa-hero .assembly-logo {
  max-height: 70px;
}
@screen lg {
  .pa-hero .assembly-logo {
    max-height: 105px;
  }
}
.pa-hero img {
  object-position: 30% 30%;
}
@screen sm {
  .pa-hero img {
    object-position: center;
  }
}
.pa-hero .assembly-header-cta {
  width: 16.5rem;
}

.assembly-register-box {
  @apply absolute flex flex-col justify-center items-start rounded;
  @apply px-6 py-6;
  width: 90%;
  right: 1rem;
  top: -8rem;
  background: rgb(49, 93, 156);
  background: linear-gradient(142deg, rgb(49, 93, 156) 50%, rgb(40, 82, 142) 50%);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}
@media screen and (min-width: 400px) {
  .assembly-register-box {
    width: 80%;
  }
}
@media screen and (min-width: 500px) {
  .assembly-register-box {
    width: 60%;
    right: 2rem;
  }
}
@screen sm {
  .assembly-register-box {
    width: 50%;
    top: -4rem;
    @apply px-8 py-10;
    right: 2rem;
  }
}
@screen md {
  .assembly-register-box {
    width: 40%;
  }
}
@screen lg {
  .assembly-register-box {
    width: 30%;
    top: -13rem;
    @apply px-8 py-12;
  }
}

.secca-hero .assembly-logo {
  max-height: 100px;
}
@screen lg {
  .secca-hero .assembly-logo {
    min-height: 170px;
  }
}
.secca-hero img {
  object-position: 30% 30%;
}
@screen sm {
  .secca-hero img {
    object-position: center;
  }
}

.global-review-gradient {
  @apply absolute bottom-0 left-0 right-0;
  height: 15rem;
  background: linear-gradient(0deg, #02152d 0%, rgba(29, 37, 68, 0) 100%);
}

.global-review-resource {
  background-color: rgba(255, 255, 255, 0.05);
  @apply py-4 px-3;
}
.global-review-resource:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.global-review-resource:hover h4 {
  @apply text-white text-opacity-100;
}
.global-review-resource:hover .download-resource-icons span:first-child {
  transform: scale(0.8);
  opacity: 0;
}
.global-review-resource:hover .download-resource-icons span:last-child {
  transform: translateY(0);
  transition-delay: 0.2s;
  opacity: 1;
}

.download-resource-icons {
  @apply relative flex justify-center;
  transition: background-color 200ms ease-in-out;
}
.download-resource-icons span {
  transition: all ease-in-out 200ms;
}
.download-resource-icons span:last-child {
  @apply absolute top-0 right-0 bottom-0 left-0;
  @apply flex items-center justify-center;
  transform: translateY(-20%);
  opacity: 0;
  transition-delay: 0;
}

.table-facts li > a {
  @apply w-full;
  @apply text-base text-left text-primary text-opacity-70;
  @apply px-6 py-3;
  @apply flex justify-between items-center;
}
.table-facts li > a:hover {
  @apply text-primary text-opacity-100 no-underline;
}
.table-facts li {
  @apply border-l-4 border-transparent outline-none;
}
.table-facts li svg {
  @apply opacity-0;
}
.table-facts li.active {
  @apply border-l-4 border-blue outline-none bg-light;
}
.table-facts li.active a {
  @apply text-primary text-opacity-100 no-underline;
}
.table-facts li.active svg {
  @apply opacity-100;
}
.table-facts.irgc-facts li.active {
  @apply border-l-4;
  border-color: #58000b;
  background-color: #fbf7f7;
}
.table-facts.irgc-facts li.active span {
  @apply no-underline font-bold;
}
.table-facts.arrow-hover-active-state svg {
  @apply hidden pl-0 pr-2;
  min-width: 16px;
}
.table-facts.arrow-hover-active-state a:not(:last-of-type) {
  @apply text-base font-medium text-opacity-100 pl-3 py-2;
}
.table-facts.arrow-hover-active-state a:not(:last-of-type):hover {
  @apply text-primary text-opacity-100 no-underline font-bold;
}
.table-facts.arrow-hover-active-state a:not(:last-of-type):hover svg {
  @apply opacity-100 block;
}
.table-facts.arrow-hover-active-state .affiliate {
  @apply pl-0 pb-0 ml-0 pb-0;
}
.table-facts.arrow-hover-active-state .fact {
  @apply pb-0 pr-0;
}
.table-facts.arrow-hover-active-state .active,
.table-facts.arrow-hover-active-state li.active {
  @apply border-none border-transparent;
}
.table-facts.arrow-hover-active-state .active a,
.table-facts.arrow-hover-active-state li.active a {
  @apply text-primary text-opacity-100 no-underline font-bold;
}
.table-facts.arrow-hover-active-state .active svg,
.table-facts.arrow-hover-active-state li.active svg {
  @apply opacity-100 block;
}
.table-facts.arrow-hover-active-state .table-facts-aside-nav {
  top: 5rem;
}

.table-facts-aside-nav {
  @apply sticky;
  top: 1rem;
}

.table-facts-shadow {
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
}

.community-learn-more-section {
  @apply sticky flex flex-col;
  @apply items-center justify-center bg-cover bg-center mt-2;
  top: 44rem;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
}

.table-facts-share-facts-link {
  @apply transition-all duration-200 ease-in-out;
  margin: auto;
}
@media screen and (max-width: 450px) {
  .table-facts-share-facts-link {
    @apply w-full;
  }
}
@screen md {
  .table-facts-share-facts-link {
    width: 60%;
  }
}
@screen lg {
  .table-facts-share-facts-link {
    @apply w-full;
  }
}

.table-facts-share-image-side {
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
@screen lg {
  .table-facts-share-image-side {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 12px;
  }
}

.table-facts-share-text-side {
  overflow: hidden;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
@screen lg {
  .table-facts-share-text-side {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 12px;
  }
}

.durban-hero {
  min-height: 800px;
}
@media screen and (max-width: 420px) {
  .durban-hero {
    min-height: 1000px;
  }
}
@screen sm {
  .durban-hero {
    min-height: 700px;
  }
}
@screen xl {
  .durban-hero {
    min-height: 800px;
  }
}

.unoct-stream-section {
  position: relative;
  bottom: -1rem;
}

.unoct-banner svg {
  width: 100%;
}
@screen md {
  .unoct-banner svg {
    width: auto;
  }
}

.unoct-sponsors-grid {
  @apply grid items-center gap-10;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
@screen md {
  .unoct-sponsors-grid {
    @apply w-2/3 m-auto gap-16;
  }
}
.unoct-sponsors-grid div:first-child {
  grid-column: 1/3;
  grid-row: 1;
}

@media screen and (min-width: 400px) {
  .calendar-banner {
    background-image: url("../../assets/images/years-banner.svg");
    background-repeat: no-repeat;
    background-position: right;
  }
}
.row-gray {
  background-color: #f0f0f0;
}

.dark-cyan-blue {
  color: #2d3748;
}

.jdcrops-review-hero {
  background-image: url(../../assets/images/jdcorps/jdcorps-review-2020.png);
  background-repeat: no-repeat;
  background-position: 105% 105%;
  background-size: contain;
}
@screen lg {
  .jdcrops-review-hero {
    background-size: auto;
  }
}

.jdcorps-review-content blockquote {
  @apply py-6 text-lg font-bold text-primary italic;
  @apply border-l-0 pl-0;
  @apply border-b border-primary relative;
}
@screen lg {
  .jdcorps-review-content blockquote {
    @apply text-xl;
  }
}
.jdcorps-review-content blockquote::before {
  content: "";
  background-image: url(../../assets/images/jdcorps/blockquotes.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: -1.5rem;
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.expectations-grid {
  @apply flex flex-wrap justify-center items-center;
}
@screen sm {
  .expectations-grid {
    @apply justify-between;
  }
}
@screen md {
  .expectations-grid {
    @apply justify-center;
  }
}
.expectations-grid > div {
  height: 320px;
  width: 320px;
  margin-bottom: 30px;
}
@screen sm {
  .expectations-grid > div {
    height: 300px;
    width: 250px;
    margin-right: 10px;
  }
}
@screen md {
  .expectations-grid > div {
    margin-right: 30px;
    height: 320px;
    width: 320px;
  }
}
@screen lg {
  .expectations-grid > div {
    height: 350px;
    width: 350px;
  }
}

.sessions-grid {
  min-height: 200px;
  position: relative;
}
@screen md {
  .sessions-grid {
    min-height: 325px;
  }
}

.sessions-grid::after {
  content: "";
  background-image: url("../../assets/images/youth_assembly/arrow.svg");
  width: 30px;
  height: 30px;
  right: -30px;
  bottom: 45%;
  position: absolute;
}

.sessions-grid:last-child::after {
  display: none;
}

.ab80-bg-size {
  height: 700px;
}
@screen sm {
  .ab80-bg-size {
    height: 1000px;
  }
}

.ab80-header {
  margin-top: -700px;
}
@screen sm {
  .ab80-header {
    margin-top: -1000px;
  }
}

.ab-80-swiper-left-arrow {
  left: -60px;
}

.ab-80-swiper-right-arrow {
  right: -60px;
}

.anniversary-gradient {
  @apply absolute bottom-0 left-0 right-0;
  height: 12rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.anniversary-footer {
  background: #edecec;
}
.anniversary-footer p {
  color: #4a5169;
  @apply text-opacity-70;
}
.anniversary-footer .footer-border {
  @apply border-t;
  border-color: #4a5169;
}

.anniversary-main {
  @apply bg-light;
}
.anniversary-main .theme-content {
  color: #4a5169;
}
.anniversary-main .up-next-section {
  color: #283965;
}
.anniversary-main .up-next-section div {
  background-color: #f1f1f1;
  border-top: 4px solid #283965;
}
@screen sm {
  .anniversary-main .up-next-section div {
    width: fit-content;
  }
}
.anniversary-main .up-next-section h6 {
  @apply text-opacity-70;
  color: #283965;
}

/* The sidebar menu */
.anniversary-sidebar-nav {
  @apply bg-light;
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 64px;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}
.anniversary-sidebar-nav .sidebar-link {
  @apply text-sm font-suisse-works w-full no-underline;
  padding: 8px 16px 8px 24px;
  color: #1d2544;
  transition: 0.3s;
}

.sidebar-link h5 {
  @apply font-bold text-sm;
}

.sidebar-link:hover {
  background-color: #28528e;
  color: white;
}

.active-theme {
  background-color: #28528e;
  color: white !important;
}
.active-theme svg {
  visibility: visible;
}

/* Animation when the page content goes on right */
.anniversary-main,
.anniversary-footer {
  transition: margin-left 0.5s;
}

/* Animation for on hamburger button */
.anniversary-side-navigation.open .toggle-mobile-nav span:nth-child(1) {
  top: 10px;
  left: 50%;
  width: 0%;
}
.anniversary-side-navigation.open .toggle-mobile-nav span:nth-child(2) {
  transform: rotate(45deg);
}
.anniversary-side-navigation.open .toggle-mobile-nav span:nth-child(3) {
  transform: rotate(-45deg);
}
.anniversary-side-navigation.open .toggle-mobile-nav span:nth-child(4) {
  top: 10px;
  left: 50%;
  width: 0%;
}

.more-ways .inner-hero-img {
  position: absolute;
  bottom: 0;
  opacity: 1;
}
.more-ways .inner-hero-container {
  min-height: 280px;
  padding-bottom: 0;
}
.more-ways .more-ways-subtitle {
  @apply text-white text-lg text-left tracking-wide;
  @apply font-semi-medium mt-4;
}
.more-ways .more-ways-title {
  @apply text-white text-opacity-80 text-xl;
  @apply text-left mb-4;
}
@screen sm {
  .more-ways .more-ways-title {
    @apply text-2xl;
  }
}
@screen lg {
  .more-ways .more-ways-title {
    @apply text-3xl;
  }
}

.more-ways-item {
  position: relative;
  top: 0;
  transition: top ease 0.2s;
}
.more-ways-item:hover {
  top: -1rem;
}

.uae-reflecting .uae-story img {
  @apply w-full;
}
@screen md {
  .uae-reflecting .uae-story {
    @apply grid grid-cols-2 gap-8;
  }
  .uae-reflecting .uae-story.-reverse .uae-story-image {
    @apply col-start-2 row-start-1;
  }
  .uae-reflecting .uae-story.-reverse .uae-story-description {
    @apply col-start-1 row-start-1;
  }
}
.uae-reflecting .uae-story + .uae-story {
  @apply mt-16;
}
.uae-reflecting .pretty-content {
  @apply text-base leading-relaxed text-gray-dark;
}
@screen lg {
  .uae-reflecting .pretty-content {
    @apply text-lg;
  }
}

.grid-stories .story img {
  @apply w-full;
}
@screen md {
  .grid-stories .story {
    @apply grid grid-cols-2 gap-8;
  }
  .grid-stories .story.-reverse .story-image {
    @apply col-start-2 row-start-1;
  }
  .grid-stories .story.-reverse .story-description {
    @apply col-start-1 row-start-1;
  }
}
.grid-stories .story + .story {
  @apply mt-16;
}
.grid-stories .pretty-content {
  @apply text-base leading-relaxed text-gray-dark;
}
@screen lg {
  .grid-stories .pretty-content {
    @apply text-lg;
  }
}

.social-share-image .hidden-share-text {
  display: none;
  @apply uppercase text-white text-base;
}
.social-share-image:hover {
  transition: all ease-in-out 150ms;
}
.social-share-image:hover img {
  filter: brightness(0.5);
}
.social-share-image:hover .hidden-share-text {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply p-3 rounded-sm;
  @apply bg-blue text-white;
}
.social-share-image:hover .hidden-share-text:hover {
  @apply bg-blue-hover;
}

.wjc-download-file:hover .bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-8px);
  }
}

.irgc-page-form {
  @apply bg-white rounded;
  width: auto;
  height: 382px;
  @apply p-4;
}
@screen md {
  .irgc-page-form {
    max-width: 342px;
    height: 382px;
    @apply p-4;
  }
}
.irgc-page-form .forms-form {
  padding: 0;
  margin: 0;
  @apply text-sm;
}
.irgc-page-form .form-control:not(textarea) {
  @apply h-10;
}
.irgc-page-form .form-label {
  @apply text-sm font-medium text-black;
}
.irgc-page-form .btn-primary {
  background-color: #58000b;
  position: relative;
  left: 130px;
  bottom: 89px;
}
@screen md {
  .irgc-page-form .btn-primary {
    left: 154px;
    bottom: 89px;
  }
}
.irgc-page-form .paragraph-block {
  @apply text-black text-base font-medium opacity-70 pb-6;
}
.irgc-page-form input[type=submit] {
  width: 130px;
  height: 38px;
}
@screen xl {
  .irgc-page-form input[type=submit] {
    width: 154px;
    height: 40px;
  }
}
.irgc-page-form input[type=checkbox] {
  @apply mr-2;
  accent-color: #58000b;
}
.irgc-page-form input[type=checkbox]:hover {
  accent-color: #58000b;
}

.irgc-page-center-map-footer-button {
  @apply relative mb-8;
}
@screen lg {
  .irgc-page-center-map-footer-button {
    @apply absolute;
    top: 17%;
  }
}

.irgc-page-header-title-typography {
  font-weight: 800;
  font-size: 56px;
  letter-spacing: -1px;
  line-height: 76px;
}

.irgc-page-table-content table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}
.irgc-page-table-content td,
.irgc-page-table-content th {
  text-align: left;
  padding: 8px;
}
.irgc-page-table-content tr {
  border-bottom: 1px solid #dddddd;
  border-left: none;
}
.irgc-page-table-content tr:nth-child(7) {
  background-color: #f5e8ea;
}

.irgc-page-table-first-row {
  background-color: #f9fafb;
  font-color: #667085;
}

.irgc-map-title {
  @apply font-medium text-3xl;
  margin-bottom: -50px;
  margin-top: unset;
}
@screen lg {
  .irgc-map-title {
    @apply text-4xxl w-1/2 mx-auto;
    margin-bottom: -75px;
  }
}

.irgc-line-height .header-block {
  line-height: 62px;
}

.sticky-sidebar-links {
  @apply sticky;
  height: min-content;
  top: 8rem;
}

.irgc-page-spacing {
  margin-bottom: 0px;
}
@screen lg {
  .irgc-page-spacing {
    margin-bottom: 108px;
  }
}

@screen lg {
  .irgc-military-map {
    background-image: url("../images/mapRed.svg");
    background-repeat: round;
  }
}

.irgc-content ol,
.irgc-content ul {
  margin-bottom: 1em;
  padding-left: 2.5em;
}
.irgc-content ol {
  list-style: decimal;
}
.irgc-content ul {
  list-style: disc;
}
.irgc-content p {
  margin-bottom: 1em;
}
.irgc-content h1,
.irgc-content h2,
.irgc-content h3,
.irgc-content h4,
.irgc-content h5,
.irgc-content h6 {
  @apply text-primary;
  margin-bottom: 0.5em;
}
.irgc-content a {
  @apply text-primary font-bold;
  text-decoration: underline;
}
.irgc-content img {
  @apply w-full;
}

.irgc-back-btn {
  @apply text-base px-4 mb-8;
  text-transform: initial;
  height: 38px;
}
.irgc-back-btn .black-arrow {
  @apply hidden;
}
.irgc-back-btn .white-arrow {
  @apply block;
}
.irgc-back-btn:hover {
  @apply text-black;
}
.irgc-back-btn:hover .white-arrow {
  @apply hidden;
}
.irgc-back-btn:hover .black-arrow {
  @apply block;
}

.irgc-map-contents-style {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.irgc-page-map-container {
  @apply bg-black text-white;
  min-height: 100px;
}
@screen lg {
  .irgc-page-map-container {
    min-height: 800px;
  }
}
@screen xl {
  .irgc-page-map-container {
    min-height: 900px;
  }
}
@screen xxl {
  .irgc-page-map-container {
    min-height: 950px;
  }
}

.irgc-map {
  padding-top: 11rem;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: none;
}
@screen md {
  .irgc-map {
    height: 400px;
  }
}
@screen lg {
  .irgc-map .container-half.-right {
    @apply pl-gutter;
  }
}
@screen xl {
  .irgc-map .container-half.-right {
    padding-left: 2.5rem;
  }
}
.irgc-map .btn {
  width: 100%;
}
@screen sm {
  .irgc-map .btn {
    width: auto;
  }
}

.irgc-page-map-office-info {
  @apply text-sm;
  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px theme("colors.black-a7");
}
@screen sm {
  .irgc-page-map-office-info {
    @apply px-gutter-1/2;
    width: 50%;
  }
}
@screen md {
  .irgc-page-map-office-info {
    @apply px-gutter text-lg;
    margin-bottom: 3rem;
  }
}
@screen lg {
  .irgc-page-map-office-info {
    width: 100%;
    margin-top: auto;
    text-shadow: none;
  }
}
@screen xl {
  .irgc-page-map-office-info {
    padding-left: 2.5rem;
  }
}
.irgc-page-map-office-info.active {
  display: block;
}
.irgc-page-map-office-info a {
  @apply text-white;
}
.irgc-page-map-office-info a:hover {
  text-decoration: underline;
}

.islamic-revolutionary-page-map {
  @apply hidden;
  max-width: 1800px;
}
@screen lg {
  .islamic-revolutionary-page-map {
    @apply block relative w-full mx-auto;
    background: black;
  }
  .islamic-revolutionary-page-map .igrc-map-svg {
    @apply mx-auto;
    width: 100%;
  }
}

.igrc-page-mark {
  @apply bg-white;
  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0px 6px rgba(255, 255, 255, 0.5);
}
@screen lg {
  .igrc-page-mark {
    display: block;
  }
}
.igrc-page-mark.hoverable-igrc-mark:hover {
  width: 17px;
  height: 17px;
}
.igrc-page-mark:focus {
  outline: none;
}
.igrc-page-mark.active {
  width: 17px;
  height: 17px;
}

.ihra-misconceptions-page h1 {
  @apply text-2xl text-center;
}
@screen md {
  .ihra-misconceptions-page h1 {
    @apply text-3xl;
  }
}
@screen lg {
  .ihra-misconceptions-page h1 {
    font-size: 68px;
  }
}
.ihra-misconceptions-page h4 {
  @apply text-lg;
}
@screen md {
  .ihra-misconceptions-page h4 {
    @apply text-xl;
  }
}
@screen lg {
  .ihra-misconceptions-page h4 {
    font-size: 27px;
  }
}

.other-ways-to-donate .inner-hero-img {
  position: absolute;
  bottom: 0;
  opacity: 1;
}
.other-ways-to-donate .inner-hero-container {
  min-height: 290px;
  padding-bottom: 0;
}
.other-ways-to-donate .other-ways-to-donate-subtitle {
  @apply text-white text-lg text-left tracking-wide;
  @apply font-semi-medium whitespace-pre-line;
  line-height: 32px;
}
.other-ways-to-donate .other-ways-to-donate-title {
  @apply text-white text-opacity-80 text-xl;
  @apply text-left;
}
@screen sm {
  .other-ways-to-donate .other-ways-to-donate-title {
    @apply text-2xl;
  }
}
@screen lg {
  .other-ways-to-donate .other-ways-to-donate-title {
    @apply text-3xl;
  }
}

.other-ways-to-donate-item {
  position: relative;
  top: 0;
  transition: top ease 0.2s;
}
.other-ways-to-donate-item:hover {
  top: -1rem;
}

@screen lg {
  .other-ways-to-donate-picture-size {
    width: 358px;
  }
}

.other-ways-to-donate-size-between-column {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.other-ways-to-donate-picture-width {
  height: 268px;
}

.israel-101-description-size {
  font-size: 32px;
  line-height: 40px;
}

.button-outline {
  font-size: 14px;
  margin-left: 149px;
}

.diplomacy-summit .diplomacy-summit img {
  @apply w-full;
}
@screen md {
  .diplomacy-summit .diplomacy-summit {
    @apply grid grid-cols-2 gap-8;
  }
  .diplomacy-summit .diplomacy-summit.-reverse .diplomacy-summit-image {
    @apply col-start-2 row-start-1;
  }
  .diplomacy-summit .diplomacy-summit.-reverse .diplomacy-summit-description {
    @apply col-start-1 row-start-1;
  }
}
.diplomacy-summit .diplomacy-summit + .diplomacy-summit {
  @apply mt-16;
}
.diplomacy-summit .pretty-content {
  @apply text-base leading-relaxed text-gray-dark;
}
@screen lg {
  .diplomacy-summit .pretty-content {
    @apply text-lg;
  }
}

.hezbollah-page .hazbollah-top-part-section {
  @apply relative pt-40 pb-10 overflow-hidden;
  @apply bg-cover bg-center;
}
.hezbollah-page .hezbollah-page-form {
  @apply bg-white rounded p-6;
}
.hezbollah-page .hezbollah-page-form .forms-form {
  background: unset;
  padding: 0;
}
.hezbollah-page .hezbollah-page-form .btn-primary {
  background-color: black;
  position: absolute;
  right: 53px;
  bottom: 42px;
}
.hezbollah-page .hezbollah-page-form .paragraph-block {
  @apply text-black leading-loose;
}
.hezbollah-page .hezbollah-page-form input[type=submit] {
  @apply px-10;
}
.hezbollah-page .hezbollah-page-form input[type=checkbox] {
  @apply mr-2;
  accent-color: black;
}
.hezbollah-page .hezbollah-page-form input[type=checkbox]:hover {
  accent-color: black;
}
.hezbollah-page .hezbollah-page-header-title-typography {
  @apply text-white;
  font-weight: 800;
  font-size: 56px;
  letter-spacing: -1px;
  line-height: 76px;
}
.hezbollah-page .hezbollah-page-table-content table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}
.hezbollah-page .hezbollah-page-table-content td,
.hezbollah-page .hezbollah-page-table-content th {
  text-align: left;
  padding: 8px;
}
.hezbollah-page .hezbollah-page-table-content tr {
  border-bottom: 1px solid #dddddd;
  border-left: none;
}
.hezbollah-page .hezbollah-page-table-content tr:nth-child(7) {
  background-color: #f5e8ea;
}
.hezbollah-page .hezbollah-page-table-first-row {
  background-color: #f9fafb;
  font-color: #667085;
}
.hezbollah-page .hezbollah-line-height .header-block {
  line-height: 62px;
}
.hezbollah-page .sticky-sidebar-links {
  @apply sticky;
  height: min-content;
  top: 8rem;
}
.hezbollah-page .hezbollah-page-spacing {
  margin-bottom: 0px;
}
@screen lg {
  .hezbollah-page .hezbollah-page-spacing {
    margin-bottom: 108px;
  }
}
.hezbollah-page .hezbollah-card {
  @apply h-64 p-3 bg-cover bg-center w-full;
  @apply flex items-center justify-center text-center;
}
.hezbollah-page .hezbollah-card .hezbollah-card-link {
  @apply text-white uppercase;
  @apply flex flex-col;
}
@screen lg {
  .hezbollah-page .hezbollah-military-map {
    background-image: url("../images/mapGreen.svg");
    background-repeat: round;
  }
}
.hezbollah-page .hezbollah-page-center-map-footer-button {
  @apply relative mb-8;
}
@screen lg {
  .hezbollah-page .hezbollah-page-center-map-footer-button {
    @apply absolute;
    top: 61%;
  }
}
.hezbollah-page .hezbollah-map-title {
  @apply font-medium text-3xl mb-6;
}
@screen lg {
  .hezbollah-page .hezbollah-map-title {
    @apply text-4xxl w-1/2 flex mx-auto;
  }
}
.hezbollah-page .irgc-page-map {
  padding-top: 10rem;
}
.hezbollah-page .hezbollah-content ol,
.hezbollah-page .hezbollah-content ul {
  margin-bottom: 1em;
  padding-left: 2.5em;
}
.hezbollah-page .hezbollah-content ol {
  list-style: decimal;
}
.hezbollah-page .hezbollah-content ul {
  list-style: disc;
}
.hezbollah-page .hezbollah-content p {
  margin-bottom: 1em;
}
.hezbollah-page .hezbollah-content h1,
.hezbollah-page .hezbollah-content h2,
.hezbollah-page .hezbollah-content h3,
.hezbollah-page .hezbollah-content h4,
.hezbollah-page .hezbollah-content h5,
.hezbollah-page .hezbollah-content h6 {
  @apply text-primary;
  margin-bottom: 0.5em;
}
.hezbollah-page .hezbollah-content a {
  @apply text-primary font-bold;
  text-decoration: underline;
}
.hezbollah-page .hezbollah-content img {
  @apply w-full;
}
.hezbollah-page .sidebar-link-container {
  @apply h-64 p-3 bg-cover bg-center;
  @apply flex items-center justify-center;
}
.hezbollah-page .sidebar-link-container .sidebar-link {
  @apply text-white font-bold uppercase;
  @apply flex flex-col items-center;
  line-height: 50px;
}
.hezbollah-page .sidebar-link-container .sidebar-link:hover {
  background-color: initial;
}
.hezbollah-page .link-small-text {
  @apply font-bold;
  font-size: 20px;
}
.hezbollah-page .link-large-text {
  @apply text-2xl;
  font-weight: 800;
}
@screen lg {
  .hezbollah-page .link-large-text {
    @apply text-3xl;
  }
}
.hezbollah-page .table-facts-aside-nav li.active {
  border-color: #999900;
}
.hezbollah-page .items-top-bottom p {
  @apply text-base;
  line-height: 4px;
  @apply pretty-content;
  @apply mb-4;
}
@screen lg {
  .hezbollah-page .items-top-bottom p {
    @apply text-lg;
  }
}
.hezbollah-page .items-top-bottom h1, .hezbollah-page .items-top-bottom h2 {
  @apply text-xl;
  @apply font-bold;
  @apply mt-2;
  @apply pb-4;
}
@screen lg {
  .hezbollah-page .items-top-bottom h1, .hezbollah-page .items-top-bottom h2 {
    @apply text-3xl;
  }
}

.irgc-101-form {
  @apply bg-white rounded px-8 pt-6 pb-4;
}
@screen md {
  .irgc-101-form {
    width: 500px;
  }
}
@screen lg {
  .irgc-101-form {
    width: unset;
  }
}
@screen xl {
  .irgc-101-form {
    @apply pb-1;
  }
}
.irgc-101-form .forms-form {
  background: unset;
  padding: 0;
}
.irgc-101-form fieldset:nth-child(3) {
  display: inline-block;
  width: 50%;
}
.irgc-101-form fieldset:nth-child(3) + div {
  display: inline-flex;
  width: 50%;
  justify-content: flex-end;
}
.irgc-101-form .btn-primary {
  background-color: #295ba5;
}
.irgc-101-form .paragraph-block {
  @apply text-black text-opacity-70 leading-6 font-medium;
}
.irgc-101-form input[type=text] {
  @apply h-10 text-base font-medium pt-0;
}
.irgc-101-form input[type=email] {
  @apply mb-3 h-10 text-base font-medium pt-0;
}
.irgc-101-form input[type=submit] {
  @apply px-14 mt-0 h-10 text-base;
}
.irgc-101-form .forms-field-label {
  @apply text-sm font-medium text-black mb-0;
}
.irgc-101-form .items-baseline {
  align-items: center !important;
}
.irgc-101-form input[type=checkbox] {
  @apply mr-2;
  height: 18px;
  width: 18px;
  accent-color: #295ba5;
}
.irgc-101-form input[type=checkbox]:hover {
  accent-color: #295ba5;
}

.numbers-section-container {
  background-color: #213D61;
}
.numbers-section-container .container-size {
  height: 370px;
}

.combat-antisemitism .main-font {
  font-family: "Suisse Works";
}
.combat-antisemitism .global-title {
  font-size: 48px;
  line-height: 62px;
}
@screen md {
  .combat-antisemitism .global-title {
    font-size: 72px;
    line-height: 93px;
  }
}
.combat-antisemitism .main-title {
  font-size: 32px;
  line-height: 44px;
}
@screen md {
  .combat-antisemitism .main-title {
    font-size: 48px;
    line-height: 62px;
  }
}
.combat-antisemitism .subtitle {
  font-size: 24px;
  line-height: 32px;
}
@screen md {
  .combat-antisemitism .subtitle {
    font-size: 32px;
    line-height: 44px;
  }
}
.combat-antisemitism .text-styles {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #021934;
}
.combat-antisemitism .map-main-title {
  font-size: 48px;
  line-height: 62px;
}
.combat-antisemitism button:focus {
  outline: none;
}
.combat-antisemitism .selected-group {
  @apply text-white;
  @apply font-semi-medium;
  @apply text-lg;
  @apply text-center;
  @apply capitalize;
  @apply p-4;
}
.combat-antisemitism .selected-group:focus {
  @apply bg-white;
  @apply text-black;
  @apply font-semi-medium;
  @apply text-lg;
  @apply text-center;
  @apply capitalize;
  @apply p-4;
}
.combat-antisemitism .raise:hover,
.combat-antisemitism .raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.7em);
  transition: 400ms;
}

.resource-svg-icon {
  min-width: 12px;
  max-width: 12px;
}

.our-strategy .strategy-active {
  background: #F2F9FF !important;
  opacity: 1 !important;
}

.embed-section-online-center {
  height: 120px;
}
@screen sm {
  .embed-section-online-center {
    height: 150px;
  }
}
@screen md {
  .embed-section-online-center {
    height: 250px;
  }
}
@screen lg {
  .embed-section-online-center {
    height: 250px;
  }
}
@screen xl {
  .embed-section-online-center {
    height: 300px;
  }
}

.white-section-under-embed-online-center {
  height: 120px;
}
@screen sm {
  .white-section-under-embed-online-center {
    height: 150px;
  }
}
@screen md {
  .white-section-under-embed-online-center {
    height: 150px;
  }
}
@screen lg {
  .white-section-under-embed-online-center {
    height: 150px;
  }
}
@screen xl {
  .white-section-under-embed-online-center {
    height: 200px;
  }
}

.next-gen-impact .number {
  color: #2559a8;
  font-size: 80px;
}
@media (min-width: 1200px) {
  .next-gen-impact .number {
    font-size: 80px;
  }
}
.next-gen-impact .show-more-member-link {
  @apply p-gutter-1/2;
  display: block;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.next-gen-impact .show-more-member-link:hover {
  @apply shadow-lg-hover;
  transform: translateY(-1rem);
}
.next-gen-impact .show-more-member-link img {
  transition: filter 0.3s ease-in-out;
  margin-bottom: 0.5rem;
}

.blogpost-button-position {
  top: 85%;
  width: 64px;
  height: 64px;
}
@screen xl {
  .blogpost-button-position {
    top: 90%;
  }
}

.next-inc-grid-icon {
  width: 260px;
  height: 260px;
}
@screen md {
  .next-inc-grid-icon {
    width: 320px;
    height: 320px;
  }
}
@screen lg {
  .next-inc-grid-icon {
    width: 340px;
    height: 340px;
  }
}

.next-gen-tag {
  @apply flex px-4 py-1 text-lg font-bold rounded-xl;
  width: fit-content;
  color: #F9F9F4;
  border-radius: 40px;
  background: #1D4678;
}

.doves-members {
  width: 264px;
  justify-self: center;
}
@screen sm {
  .doves-members {
    justify-self: unset;
  }
}
.doves-members img {
  height: 350px;
}

@screen lg {
  .doves-members-grid .middle-one {
    justify-self: center;
  }
}
@screen lg {
  .doves-members-grid .last-one {
    justify-self: end;
  }
}

.speaker-circle {
  width: 264px;
  height: 264px;
  object-fit: cover;
}

.hamas-article-body figure {
  padding-bottom: 56.25%;
  position: relative;
}
.hamas-article-body iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mena-layout .tooltip-button {
  color: #ed8f0a !important;
}
.mena-layout .image-block {
  @apply mt-6;
}

.mena-page-title {
  color: #ed8f0a;
}
@screen xl {
  .mena-page-title {
    font-size: 70px;
  }
}
@screen xxl {
  .mena-page-title {
    font-size: 80px;
  }
}

.mena-list-title {
  color: #ed8f0a;
  @apply text-xl font-medium font-fondamento;
}
@screen xl {
  .mena-list-title {
    @apply text-10;
  }
}

.mena-header.header {
  @apply bg-transparent shadow-none z-50;
}

@screen xl {
  .mena-bio-video-wrapper {
    width: 340px;
  }
  .mena-bio-video-wrapper > iframe {
    width: 100%;
  }
}

.mena-bio-image {
  width: 320px;
}
@screen sm {
  .mena-bio-image {
    width: 340px;
  }
  .mena-bio-image img {
    width: 340px;
  }
}
@screen lg {
  .mena-bio-image {
    width: 280px;
  }
  .mena-bio-image img {
    width: 280px;
  }
}
@screen xl {
  .mena-bio-image {
    width: 340px;
  }
  .mena-bio-image img {
    width: 340px;
  }
}

.country-jews-image {
  width: 244px;
  height: 324px;
}

.image-book-effect-bio {
  right: 2rem;
  height: 340px;
  width: 200px;
  background: #f3a148;
  z-index: 0;
  top: -0.4rem;
  transform: rotate(-3.4deg);
}
@screen sm {
  .image-book-effect-bio {
    right: -1rem;
    height: 440px;
    width: 340px;
    background: #f3a148;
    z-index: 0;
    top: -0.4rem;
    left: 1.5rem;
    transform: rotate(-3.4deg);
  }
}
@screen lg {
  .image-book-effect-bio {
    right: -1rem;
    width: 260px;
    height: 360px;
    left: auto;
  }
}
@screen xl {
  .image-book-effect-bio {
    height: 440px;
    width: 340px;
  }
}

.article-content.mena-bio-content .image-block-image {
  width: 100%;
}
.article-content.mena-bio-content .image-block-caption {
  text-align: left;
}

.country-name {
  @apply text-white;
  @apply absolute;
  @apply font-fondamento;
  @apply font-semi-medium;
  @apply text-xl;
  bottom: 16px;
  left: 24px;
}

.image-book-effect {
  height: 320px;
  width: 234px;
  background: #f3a148;
  z-index: 0;
  top: -0.4rem;
  left: 1.5rem;
  transform: rotate(-3.4deg);
}
@screen sm {
  .image-book-effect {
    right: 0rem;
  }
}
@screen lg {
  .image-book-effect {
    right: 1.5rem;
  }
}
@screen xl {
  .image-book-effect {
    right: 0rem;
  }
}

h2 {
  @apply text-xl font-semi-medium mt-8;
}
@screen sm {
  h2 {
    @apply text-7 mt-10;
  }
}
@screen md {
  h2 {
    @apply text-2xl;
  }
}
@screen lg {
  h2 {
    @apply text-10 mt-12;
  }
}

.mena-h2-typography h2 {
  @apply font-suisse-works font-semi-medium text-2xl;
  line-height: 40px;
  color: #ed8f0a !important;
}

.podcasts-title-container {
  min-height: 190px;
}
@screen lg {
  .podcasts-title-container {
    min-height: 290px;
  }
}

@screen lg {
  .podcasts-grid {
    margin-top: 145px;
  }
}
.podcasts-grid .col-span-9 {
  @apply pt-38;
}
@screen lg {
  .podcasts-grid .col-span-9 {
    @apply pt-0;
  }
}
.podcasts-grid .col-span-3 {
  @apply pb-5;
}
@screen lg {
  .podcasts-grid .col-span-3 {
    @apply pb-0;
  }
}

.podcast-main-img {
  max-height: 264px;
  max-width: 264px;
}

.podcasts-list h1 {
  font-size: 40px;
}

.podcast-item {
  padding: 24px 80px 40px 24px;
  background-color: #F8F8FA;
}
.podcast-item .podcast-image img {
  min-height: 118px;
  min-width: 118px;
}
.podcast-item .podcast-text {
  color: #85858A;
}
.podcast-item .podcast-time {
  color: #919194;
}

.podcast-component,
.player-row {
  background-color: #F8F8FA;
}
@screen md {
  .podcast-component,
  .player-row {
    max-height: 180px;
  }
}

.podcast-share-item {
  @apply border rounded-lg p-3 mt-3;
  border-color: #A6A6A6;
}

.podcast-dark-text {
  color: #0D0D0D !important;
}

.podcast-bottom-border {
  @apply border-b pb-1;
  border-color: #08284F;
  width: fit-content;
}
.podcast-bottom-border:hover {
  @apply border-none;
}

.podcast-second-dark-text {
  color: #08284F !important;
}

.article-content.kristallnacht h1,
.article-content.kristallnacht h2,
.article-content.kristallnacht h3,
.article-content.kristallnacht h4,
.article-content.kristallnacht h5,
.article-content.kristallnacht h6 {
  @apply text-white;
}

.kristallnacht-widget {
  aspect-ratio: 362/371;
  @apply mt-4 col-span-12;
}
@media (min-width: 370px) {
  .kristallnacht-widget {
    @apply col-span-10 col-start-2;
  }
}
@media (min-width: 480px) {
  .kristallnacht-widget {
    @apply col-span-8 col-start-3;
  }
}
@screen md {
  .kristallnacht-widget {
    @apply mt-0 col-span-6 col-start-4;
  }
}
@screen lg {
  .kristallnacht-widget {
    @apply col-span-4 col-start-9;
  }
}

.widget-button {
  @apply mt-12 h-auto py-2 px-4;
  background-color: #011429;
}
@screen sm {
  .widget-button {
    @apply px-6;
  }
}
@screen lg {
  .widget-button {
    @apply px-4;
  }
}
@screen xl {
  .widget-button {
    @apply px-6;
  }
}
.widget-button:hover {
  @apply bg-black;
}

.kristallnacht-hero-video .embedded-video, .kristallnacht-hero-video .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .kristallnacht-hero-video figure, .kristallnacht-hero-video .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .kristallnacht-hero-video figure, .kristallnacht-hero-video .embed-code-online-center {
  @apply rounded;
}
@screen md {
  .kristallnacht-hero-video .embedded-video, .kristallnacht-hero-video .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .kristallnacht-hero-video figure, .kristallnacht-hero-video .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .kristallnacht-hero-video figure, .kristallnacht-hero-video .embed-code-online-center {
    padding-bottom: 65%;
  }
}

.conspiracy-myths-site {
  @apply relative;
}
.conspiracy-myths-site .dropdown-navigation {
  @apply hidden;
}
@screen md {
  .conspiracy-myths-site .dropdown-navigation {
    @apply block;
  }
}
.conspiracy-myths-site .mobile-dropdown-navigation {
  @apply block;
}
@screen md {
  .conspiracy-myths-site .mobile-dropdown-navigation {
    @apply hidden;
  }
}
.conspiracy-myths-site .mobile-dropdown-navigation-content {
  @apply hidden absolute z-10;
  background-color: #f18f6d;
  transform-origin: top center;
  animation: slideDown 300ms ease-in-out forwards;
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.conspiracy-myths-site .mobile-dropdown-navigation-content.show {
  @apply flex flex-col w-full;
}

.conspiracy-footer-socials {
  display: flex;
  justify-content: center;
}
.conspiracy-footer-socials a {
  transition: opacity 0.3s ease-in-out;
  opacity: 0.5;
  flex-shrink: 0;
  margin-top: 20px;
}
.conspiracy-footer-socials a:hover {
  opacity: 1;
}
@screen md {
  .conspiracy-footer-socials a {
    margin-top: 0px;
  }
}
.conspiracy-footer-socials a:not(:first-child) {
  margin-left: 20px;
}

.learn-more-section {
  padding: 40px 15px;
  background-color: #f1ebeb;
}
@screen sm {
  .learn-more-section {
    padding: 60px 30px 80px;
  }
}

.centered-heading {
  margin-top: 0px;
  margin-bottom: 24px;
  line-height: 56px;
  text-align: center;
  font-weight: bold;
  color: #333;
}
@screen sm {
  .centered-heading {
    margin-bottom: 60px;
  }
}

.link-block {
  width: 100%;
  text-decoration: none;
}

.link-block img {
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 16px;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.image-16 {
  width: 100%;
}

.div-block {
  margin-top: 12px;
  padding-top: 0px;
  padding-left: 0px;
  margin-bottom: 38px;
}
@screen md {
  .div-block {
    margin-bottom: 8px;
  }
}

.text-block-6 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.footer-wrapper {
  background-color: #f1ebeb;
  padding: 40px 30px;
}

.footer-container {
  width: 100%;
  max-width: 728px;
  margin-right: auto;
  margin-left: auto;
}
@screen md {
  .footer-container {
    max-width: 1140px;
  }
}

.conspiracy-footer-logo {
  opacity: 0.2;
  flex-shrink: 0;
  display: inline-block;
  margin-bottom: 16px;
}
@screen md {
  .conspiracy-footer-logo {
    margin-bottom: 0px;
  }
}

.conspiracy-introduction p {
  opacity: 80%;
  color: #333333;
  @apply text-base leading-relaxed;
}
@screen md {
  .conspiracy-introduction p {
    font-size: 20px;
    @apply leading-loose;
  }
}
@screen lg {
  .conspiracy-introduction .embedded-video, .conspiracy-introduction .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .conspiracy-introduction figure, .conspiracy-introduction .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .conspiracy-introduction figure, .conspiracy-introduction .embed-code-online-center {
    padding-bottom: 90%;
  }
}
@screen xl {
  .conspiracy-introduction .embedded-video, .conspiracy-introduction .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .conspiracy-introduction figure, .conspiracy-introduction .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .conspiracy-introduction figure, .conspiracy-introduction .embed-code-online-center {
    padding-bottom: 75%;
  }
}
.conspiracy-introduction h1,
.conspiracy-introduction h2,
.conspiracy-introduction h3,
.conspiracy-introduction h4,
.conspiracy-introduction h5 {
  @apply font-semi-bold text-black;
}

.btn-conspiracy-myths {
  padding: 15px 20px;
  background-color: #f8c0ad;
  transition: background-color 200ms ease;
  @apply font-suisse-works text-black normal-case font-medium text-base rounded-none;
}
.btn-conspiracy-myths:hover {
  background-color: #f18f6d;
}

.listed-myth .listed-myth-description {
  @apply hidden;
}
@screen lg {
  .listed-myth .listed-myth-description {
    @apply block;
  }
}
@screen lg {
  .listed-myth .listed-myth-description-reversed {
    @apply hidden;
  }
}

.listed-myth.-reverse .listed-myth-description {
  @apply hidden;
}
.listed-myth.-reverse .listed-myth-description-reversed {
  @apply block;
}

.myths-hero-logo {
  display: block;
  flex-shrink: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.myths-hero-logo:hover {
  @apply opacity-icon;
}

.iframe-style .iframe-block {
  margin-bottom: 40px;
}

.anti-zionism-body {
  @apply font-suisse-works mt-12;
}
.anti-zionism-body p {
  @apply text-base leading-relaxed text-black;
}
@screen md {
  .anti-zionism-body p {
    font-size: 20px;
    @apply leading-loose;
  }
}
.anti-zionism-body h1,
.anti-zionism-body h2,
.anti-zionism-body h3,
.anti-zionism-body h4,
.anti-zionism-body h5 {
  @apply font-bold;
  color: #333333;
}

.container-neg-margin {
  margin-top: -6rem;
}
@screen lg {
  .container-neg-margin {
    margin-top: -11rem;
  }
}

.section-neg-margin {
  margin-top: -50px;
}

.author-image-style {
  width: 48px;
  height: 48px;
}

.learn-more-image-container {
  height: 602px;
}

.hover-effect {
  transition-duration: 0.6s;
}
.hover-effect:hover {
  @apply shadow-lg-hover;
  transform: translateY(-20px);
  transition-duration: 0.6s;
}

@screen md {
  .holocaust-memorial .holocaust-hero-video .embedded-video, .holocaust-memorial .holocaust-hero-video .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .holocaust-memorial .holocaust-hero-video figure, .holocaust-memorial .holocaust-hero-video .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .holocaust-memorial .holocaust-hero-video figure, .holocaust-memorial .holocaust-hero-video .embed-code-online-center {
    padding-bottom: 69%;
  }
}
@screen md {
  .holocaust-memorial .entry-video-even .embedded-video, .holocaust-memorial .entry-video-even .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .holocaust-memorial .entry-video-even figure, .holocaust-memorial .entry-video-even .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .holocaust-memorial .entry-video-even figure, .holocaust-memorial .entry-video-even .embed-code-online-center,
  .holocaust-memorial .entry-video-odd .embedded-video,
  .holocaust-memorial .entry-video-odd .un_antisemitism .stream-section-content figure,
  .un_antisemitism .stream-section-content .holocaust-memorial .entry-video-odd figure,
  .holocaust-memorial .entry-video-odd .women_in_contemporary_judaism .pretty-content figure,
  .women_in_contemporary_judaism .pretty-content .holocaust-memorial .entry-video-odd figure,
  .holocaust-memorial .entry-video-odd .embed-code-online-center {
    padding-bottom: 177%;
  }
}
.holocaust-memorial .grid-entry .entry-video-odd {
  @apply hidden;
}
.holocaust-memorial .grid-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .holocaust-memorial .grid-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen lg {
  .holocaust-memorial .grid-entry .entry-summary {
    @apply col-span-7 col-start-6;
  }
}
@screen xl {
  .holocaust-memorial .grid-entry .entry-summary {
    @apply col-span-7 col-start-6;
  }
}
@screen md {
  .holocaust-memorial .grid-entry.-reverse-entry .entry-video-even {
    @apply hidden;
  }
}
.holocaust-memorial .grid-entry.-reverse-entry .entry-video-odd {
  @apply hidden;
}
@screen md {
  .holocaust-memorial .grid-entry.-reverse-entry .entry-video-odd {
    @apply block;
  }
}
@screen md {
  .holocaust-memorial .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen lg {
  .holocaust-memorial .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-7 col-start-1;
  }
}

@screen md {
  .story-page-video .embedded-video, .story-page-video .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .story-page-video figure, .story-page-video .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .story-page-video figure, .story-page-video .embed-code-online-center {
    padding-bottom: 130%;
  }
}

.description-styles {
  font-weight: 400;
  font-family: "Suisse Works";
}

@screen md {
  .hero-text-width {
    width: 558px;
  }
}

.section-title {
  @apply text-xl;
}
@screen sm {
  .section-title {
    @apply text-2xl;
  }
}
@screen lg {
  .section-title {
    font-size: 40px;
    line-height: 52px;
  }
}

.video-size {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
}
.video-size iframe,
.video-size video {
  border: none;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hero-video-size {
  @apply w-full;
}
.hero-video-size iframe,
.hero-video-size video {
  @apply w-full;
  min-height: 360px;
}

.israel-label {
  @apply bg-white text-primary p-2 font-600 text-base;
  transition: background-color 0.3s ease-in-out;
}

.israel-section-wrap {
  @apply absolute flex flex-row items-center;
}
.israel-section-wrap svg line {
  transition: stroke 0.3s ease-in-out;
}
.israel-section-wrap svg circle {
  transition: fill 0.3s ease-in-out;
}
.israel-section-wrap:hover svg line {
  stroke: #fee500;
}
.israel-section-wrap:hover svg circle {
  fill: #fee500;
}
.israel-section-wrap:hover .israel-label {
  @apply bg-yellow;
}

.north {
  position: relative;
  bottom: 30rem;
  left: -2.5rem;
}
@screen md {
  .north {
    bottom: 38.5rem;
    left: unset;
  }
}
@screen lg {
  .north {
    bottom: 38rem;
    left: -1.8rem;
  }
}

.tel-aviv {
  position: relative;
  bottom: 24rem;
  left: -1rem;
}
@screen sm {
  .tel-aviv {
    bottom: 24rem;
    left: -2.3rem;
  }
}
@screen md {
  .tel-aviv {
    bottom: 31rem;
    left: -0.8rem;
  }
}

.jerusalem {
  position: relative;
  bottom: 20rem;
  left: 4.3rem;
}
@screen md {
  .jerusalem {
    bottom: 26.8rem;
    left: 6rem;
  }
}

.south {
  position: relative;
  bottom: 10rem;
  left: 4.3rem;
}
@screen md {
  .south {
    bottom: 13rem;
    left: 6rem;
  }
}

.logo-size {
  min-width: 263px;
  height: 111px;
  @apply object-cover;
}

.title-font-weight {
  font-weight: 600;
}

.section-title {
  @apply text-xl;
}
@screen sm {
  .section-title {
    @apply text-2xl;
  }
}
@screen lg {
  .section-title {
    font-size: 40px;
    line-height: 52px;
  }
}

.about-content-font-weight {
  font-weight: 450;
  font-family: "Suisse Works";
}

.map-image-size {
  width: 558px;
  height: 380px;
  @apply object-cover;
}

.about-section-size {
  height: 360px;
  @apply w-full;
  @apply object-cover;
}

.video-size-ihra-campaign .embedded-video, .video-size-ihra-campaign .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .video-size-ihra-campaign figure, .video-size-ihra-campaign .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .video-size-ihra-campaign figure, .video-size-ihra-campaign .embed-code-online-center {
  @apply min-w-full;
  min-height: 288px;
}

.video-size-ihra-campaign.ihra-campaign {
  @apply w-auto mx-7;
  @apply mb-8;
  align-items: flex-end;
  shape-outside: inset(calc(100% - 390px) 0 0);
}
@screen lg {
  .video-size-ihra-campaign.ihra-campaign {
    @apply float-right;
    @apply mb-0;
    margin-right: 90px;
    width: 55%;
  }
}

@screen md {
  .caoc-video .embedded-video, .caoc-video .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .caoc-video figure, .caoc-video .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .caoc-video figure, .caoc-video .embed-code-online-center {
    padding-bottom: 67%;
  }
}

.advisory-member-circle {
  width: 68px;
  height: 68px;
  object-fit: cover;
}

.lead-member-circle {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
@screen md {
  .lead-member-circle {
    width: 166px;
    height: 166px;
  }
}

.resource-section .caoc-date,
.resource-section .caoc-annotation {
  @apply text-white text-opacity-60;
}
.resource-section h5 {
  @apply text-white;
}
.resource-section .swiper-button-prev svg,
.resource-section .swiper-button-next svg {
  @apply fill-white;
}

.other-actions-section .caoc-date,
.other-actions-section .caoc-annotation {
  @apply text-blue-0C2B46 text-opacity-60;
}
.other-actions-section .swiper-button-prev svg,
.other-actions-section .swiper-button-next svg {
  fill: #0c2b46;
}

.actions-section .swiper-button-prev svg,
.actions-section .swiper-button-next svg {
  fill: white;
}

.elevate-content {
  @apply leading-relaxed;
}
.elevate-content a {
  text-decoration: underline;
}
.elevate-content p {
  margin-bottom: 1.5rem;
}
.elevate-content p:first-child {
  margin-top: 0;
}
.elevate-content ul,
.elevate-content ol {
  margin: 1.5rem 0;
  padding-left: 1.75rem;
}
.elevate-content ul li + li,
.elevate-content ol li + li {
  margin-top: 0.5rem;
}
.elevate-content ul {
  list-style: circle;
}
.elevate-content ol {
  list-style: decimal;
}
.elevate-content blockquote {
  margin: 2.5rem 0;
  border-left: 1px solid;
  padding-left: 2rem;
  font-style: italic;
}

.anniversary-carousel .swiper-wrapper {
  max-height: 424px;
}
.anniversary-carousel .swiper-slide img {
  min-height: 260px;
}
@screen lg {
  .anniversary-carousel .swiper-slide img {
    min-height: 424px;
  }
}

.anniversary-history .grid-entry .entry-video-odd {
  @apply hidden;
}
.anniversary-history .grid-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .anniversary-history .grid-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen lg {
  .anniversary-history .grid-entry .entry-summary {
    @apply col-span-7 col-start-7;
  }
}
@screen xl {
  .anniversary-history .grid-entry .entry-summary {
    @apply col-span-6 col-start-6;
  }
}
@screen md {
  .anniversary-history .grid-entry.-reverse-entry .entry-video-even {
    @apply hidden;
  }
}
.anniversary-history .grid-entry.-reverse-entry .entry-video-odd {
  @apply hidden;
}
@screen md {
  .anniversary-history .grid-entry.-reverse-entry .entry-video-odd {
    @apply block;
  }
}
@screen md {
  .anniversary-history .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen lg {
  .anniversary-history .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen xl {
  .anniversary-history .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-2;
  }
}

.jya .grid-entry .entry-video-odd {
  @apply hidden;
}
.jya .grid-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .jya .grid-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen lg {
  .jya .grid-entry .entry-summary {
    @apply col-span-7 col-start-7;
  }
}
@screen md {
  .jya .grid-entry.-reverse-entry .entry-video-even {
    @apply hidden;
  }
}
.jya .grid-entry.-reverse-entry .entry-video-odd {
  @apply hidden;
}
@screen md {
  .jya .grid-entry.-reverse-entry .entry-video-odd {
    @apply block;
  }
}
@screen md {
  .jya .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen lg {
  .jya .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}

.jewish-languages .image-rotate {
  transform: rotate(-5.26deg);
}
.jewish-languages .navigation-buttons {
  background-color: #EAEAE7;
  @apply rounded-sm;
  @apply px-10;
  @apply py-3;
}
.jewish-languages .navigation-title {
  color: #295BA5;
  @apply font-600;
  @apply font-suisse-intl;
  @apply text-xs;
}
@screen lg {
  .jewish-languages .navigation-title {
    @apply text-lg;
  }
}
.jewish-languages .video-size {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
}
.jewish-languages .video-size iframe,
.jewish-languages .video-size video {
  border: none;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.jewish-languages .hero-image {
  @apply w-48;
}
@screen md {
  .jewish-languages .hero-image {
    width: 333px;
    height: 459px;
  }
}
@screen lg {
  .jewish-languages .hero-image {
    width: 433px;
    height: 604px;
  }
}
@screen md {
  .jewish-languages .entry-title.-reverse-entry {
    @apply justify-end;
  }
}
.jewish-languages .grid-entry .entry-video-odd {
  @apply hidden;
}
.jewish-languages .grid-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .jewish-languages .grid-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen md {
  .jewish-languages .grid-entry.-reverse-entry .entry-video-even {
    @apply hidden;
  }
}
.jewish-languages .grid-entry.-reverse-entry .entry-video-odd {
  @apply hidden;
}
@screen md {
  .jewish-languages .grid-entry.-reverse-entry .entry-video-odd {
    @apply block;
  }
}
.jewish-languages .grid-entry.-reverse-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .jewish-languages .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}

.leadership-bridge button:focus {
  outline: none;
  box-shadow: none;
}
.leadership-bridge .origin-section-image {
  width: 162px;
  height: 283px;
}
@screen md {
  .leadership-bridge .origin-section-image {
    width: 362px;
    height: 320px;
  }
}
@screen lg {
  .leadership-bridge .origin-section-image {
    width: 362px;
    height: 483px;
  }
}
.leadership-bridge .wjc-nominations-link {
  @apply p-0;
  max-height: 160px;
}
.leadership-bridge .wjc-nominations-link img {
  @apply mb-0;
  max-height: 160px;
  filter: grayscale(0);
}
.leadership-bridge .wjc-nominations-link:hover img {
  filter: grayscale(0);
}

.bridge-map {
  @apply hidden;
}
@screen lg {
  .bridge-map {
    @apply block relative w-full h-full;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }
  .bridge-map .bridge-map-svg {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.bridge-map-mark {
  background-color: #295BA5;
  display: none;
  position: absolute;
  width: 17px;
  height: 17px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 50%;
  cursor: pointer;
}
@screen lg {
  .bridge-map-mark {
    display: block;
  }
}
.bridge-map-mark.hoverable-bridge-mark:hover {
  @apply bg-white;
  width: 17px;
  height: 17px;
  cursor: pointer;
  box-shadow: 0 0 0px 6px rgb(41, 91, 165);
}
.bridge-map-mark:focus {
  outline: none;
}
.bridge-map-mark.active {
  width: 17px;
  height: 17px;
}
.bridge-map-mark:hover .map-tooltip {
  cursor: pointer;
  opacity: 1;
}

.map-tooltip {
  @apply text-white absolute text-base;
  @apply font-600 font-suisse-intl py-2 pl-2 pr-3;
  width: max-content;
  min-height: 30px;
  background-color: #295BA5;
  text-align: center;
  z-index: 1;
  bottom: 125%; /* Position above the anchor */
  transform: translateX(5%);
  opacity: 0;
  transition: opacity 0.2s;
}
.map-tooltip img {
  max-width: 80px;
  max-height: 40px;
}
.map-tooltip:hover {
  cursor: pointer;
  opacity: 1;
}
.map-tooltip.belgium-tooltip {
  top: 130%;
  bottom: unset;
  transform: translateX(-95%);
}
.map-tooltip.astria-tooltip {
  bottom: 100%;
}
.map-tooltip.italy-tooltip {
  bottom: -325%;
}
.map-tooltip.israel-1-tooltip {
  bottom: -345%;
}
.map-tooltip.argentina-tooltip {
  bottom: -350%;
}

.no-logo-country {
  left: 170%;
}

.bosnia-delegation .bosnia-delegation-carousel .swiper-wrapper {
  max-height: 424px;
}
.bosnia-delegation .bosnia-delegation-carousel .swiper-slide img {
  min-height: 260px;
}
@screen lg {
  .bosnia-delegation .bosnia-delegation-carousel .swiper-slide img {
    min-height: 424px;
  }
}
.bosnia-delegation .bosnia-delegation-history .grid-entry .entry-video-odd {
  @apply hidden;
}
.bosnia-delegation .bosnia-delegation-history .grid-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .bosnia-delegation .bosnia-delegation-history .grid-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen lg {
  .bosnia-delegation .bosnia-delegation-history .grid-entry .entry-summary {
    @apply col-span-7 col-start-7;
  }
}
@screen xl {
  .bosnia-delegation .bosnia-delegation-history .grid-entry .entry-summary {
    @apply col-span-6 col-start-6;
  }
}
@screen md {
  .bosnia-delegation .bosnia-delegation-history .grid-entry.-reverse-entry .entry-video-even {
    @apply hidden;
  }
}
.bosnia-delegation .bosnia-delegation-history .grid-entry.-reverse-entry .entry-video-odd {
  @apply hidden;
}
@screen md {
  .bosnia-delegation .bosnia-delegation-history .grid-entry.-reverse-entry .entry-video-odd {
    @apply block;
  }
}
@screen md {
  .bosnia-delegation .bosnia-delegation-history .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen lg {
  .bosnia-delegation .bosnia-delegation-history .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen xl {
  .bosnia-delegation .bosnia-delegation-history .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-2;
  }
}

.lawfare-report-btn {
  @apply uppercase flex w-full;
  @apply font-semi-bold justify-around text-white bg-red-900;
  @apply py-8 px-12 items-center mt-8 mb-10 text-xl;
  @apply no-underline cursor-pointer font-suisse-intl;
}
.lawfare-report-btn:hover {
  @apply underline;
}

.lawfare-body {
  @apply text-sm font-semi-medium;
  @apply text-blue-0C2B46 font-suisse-intl pretty-content;
}
.lawfare-body :first-child {
  @apply mt-0;
}
@screen lg {
  .lawfare-body {
    @apply text-lg;
  }
}
.lawfare-body.-form input[type=submit] {
  @apply w-full bg-red-900;
}
.lawfare-body.-form .form-label {
  @apply text-lg font-suisse-intl font-medium capitalize;
}
.lawfare-body.-form .forms-field-description {
  @apply text-lg font-semi-medium font-suisse-intl;
  color: #061D37;
}
.lawfare-body.-form input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}
.lawfare-body.-form input[type=file]::before {
  @apply inline-block outline-none whitespace-nowrap cursor-pointer;
  @apply font-medium text-sm;
  content: "Choose file";
  background: #E3E8F3;
  border-radius: 2px 0px 0px 2px;
  padding: 10px 16px;
  -webkit-user-select: none;
  color: #0F5DA9;
  background: rgba(15, 93, 169, 0.2);
}
.lawfare-body.-form input[type=file]::before {
  background: rgba(15, 93, 169, 0.2);
}

.lawfare-services {
  @apply font-semi-medium text-blue-0C2B46;
  @apply font-suisse-intl pretty-content py-10 leading-8;
}
.lawfare-services :first-child {
  @apply mt-0;
}
.lawfare-services h2 {
  @apply text-xl font-600;
}
.lawfare-services p {
  @apply my-8;
}

.lawfare-title {
  @apply text-white text-2xl font-600 mb-2 font-suisse-intl;
}
@screen xl {
  .lawfare-title {
    @apply text-4xxl;
  }
}

.lawfare-subtitle {
  @apply text-white text-2xl font-semi-medium mb-10 font-suisse-intl;
}
@screen lg {
  .lawfare-subtitle {
    font-size: 40px;
  }
}

.lawfare-form-text-transform .form-label:first-letter {
  text-transform: uppercase;
}
.lawfare-form-text-transform .form-label {
  text-transform: lowercase !important;
}

.we-remember-journeys .hero-logo-title {
  color: #F9F871;
  font-family: Avenir Next Condensed;
}
.we-remember-journeys .school-participation-subtitle {
  color: rgba(33, 37, 48, 0.6980392157);
}
.we-remember-journeys .project-activities-width {
  width: 264px;
}
.we-remember-journeys .about-section-width {
  width: 63%;
}
.we-remember-journeys .circle {
  border-radius: 50%;
  text-align: center;
  width: 44px;
  height: 44px;
  line-height: 45px;
}
@screen lg {
  .we-remember-journeys .circle {
    width: 64px;
    height: 64px;
    line-height: 65px;
  }
}
.we-remember-journeys .form-styles {
  @apply text-sm font-semi-medium;
  @apply text-black font-suisse-intl pretty-content;
}
@screen lg {
  .we-remember-journeys .form-styles {
    @apply text-lg;
  }
}
.we-remember-journeys .form-styles.-form input[type=submit] {
  background-color: #F9F871;
  @apply w-full;
  @apply text-black;
}
.we-remember-journeys .form-styles.-form .form-label {
  @apply text-lg font-suisse-intl font-medium capitalize text-black;
}
.we-remember-journeys .form-styles.-form .forms-field-description {
  @apply text-lg font-medium font-suisse-intl;
  color: black;
}
.we-remember-journeys .form-styles.-form input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}
.we-remember-journeys .form-styles.-form input[type=file]::before {
  @apply inline-block outline-none whitespace-nowrap cursor-pointer;
  @apply font-medium text-sm;
  content: "Choose file";
  background: #E3E8F3;
  border-radius: 2px 0px 0px 2px;
  padding: 10px 16px;
  color: #0F5DA9;
  background: rgba(15, 93, 169, 0.2);
}
.we-remember-journeys .form-styles.-form input[type=file]::before {
  background: rgba(15, 93, 169, 0.2);
}
.we-remember-journeys .form-styles.-form .forms-form, .we-remember-journeys .form-styles.-form .forms-success-message {
  padding-top: 1rem !important;
}

@screen lg {
  .journey-dot-bg {
    background-image: url("../images/we_remember_journeys/dots-short.svg");
    background-repeat: repeat-y;
    background-position-x: 130%;
  }
}
@screen xl {
  .journey-dot-bg {
    background-position-x: 100%;
  }
}

.journeys-download-documents .journeys-document-text {
  background-color: white;
  opacity: 0.8;
}
.journeys-download-documents .journeys-document-footer {
  background-color: rgba(255, 255, 255, 0.8);
}
.journeys-download-documents .download-documents-button {
  background-color: #0F5DA9;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  border-radius: none;
}
.journeys-download-documents:hover {
  background-color: white;
  opacity: 1;
  transition: all ease-in-out 200ms;
}
.journeys-download-documents:hover .journeys-document-text {
  background-color: white;
  opacity: 1;
}
.journeys-download-documents:hover .journeys-document-footer {
  background-color: white;
  opacity: 1;
}
.journeys-download-documents:hover .download-documents-button {
  color: white;
  background-color: #212530;
  transition: all;
}

@screen md {
  .slider-height {
    max-height: 440px;
  }
}
@screen lg {
  .slider-height {
    max-height: 540px;
  }
}

@screen lg {
  .container-height {
    max-height: 514px;
  }
}

.fighting-hate .embedded-video, .fighting-hate .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .fighting-hate figure, .fighting-hate .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .fighting-hate figure, .fighting-hate .embed-code-online-center {
  padding-bottom: 59%;
}
.fighting-hate .swiper-button-prev,
.fighting-hate .swiper-button-next {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}
.fighting-hate .swiper-button-prev:hover,
.fighting-hate .swiper-button-next:hover {
  @apply text-yellow-FF0;
  background: rgba(255, 255, 255, 0.3);
}

.disabled_swiper_button {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.instagram-widget {
  max-width: 264px;
}
.instagram-widget .img,
.instagram-widget svg,
.instagram-widget video,
.instagram-widget canvas,
.instagram-widget audio,
.instagram-widget iframe,
.instagram-widget embed,
.instagram-widget object {
  min-width: initial !important;
  width: 264px !important;
  height: 365px !important;
}

.ec-zagreb-bg-image {
  background-size: 45%;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 100px;
}

.ec-zagreb-bg-color {
  background-color: #1c2b59;
}

.ec-zagreb .intro-section {
  max-width: 560px;
}

.ec-zagreb-slide {
  width: 166px;
  height: 93px;
}
.ec-zagreb-slide button {
  margin-bottom: 5px;
  width: 166px;
  height: 93px;
  outline: none;
}
.ec-zagreb-slide button:active {
  @apply border-white border-8;
  outline: none;
}

.ec-zagreb-video {
  @apply w-full h-full relative;
}
.ec-zagreb-video iframe {
  width: 100%;
  height: 100vh;
  max-width: 1150px;
  max-height: 574px;
}

.disabled_swiper_button_ec_zagreb {
  @apply opacity-50;
}

.custom-size-h3 {
  font-size: 40px !important;
  margin-left: -8px;
  border-left: solid 8px #295ba5;
}

.mini-swiper-img {
  width: 340px;
}
@screen sm {
  .mini-swiper-img {
    width: 362px;
  }
}

.modal-image-mini-swiper .mini-swiper-img {
  width: unset !important;
  height: unset !important;
}

.align-buttons-ec-zagreb-page .swiper-button-prev {
  left: 12px;
}
.align-buttons-ec-zagreb-page .swiper-button-next {
  right: 12px;
}

.ec-zagreb-content {
  @apply font-suisse-works;
}
.ec-zagreb-content.zagreb-content-header b {
  font-weight: 700;
  color: #1c2b59;
}
.ec-zagreb-content.zagreb-content-indent b {
  font-weight: 700;
  color: #1c2b59;
}
.ec-zagreb-content .ec-zagreb-content-lower p {
  @apply opacity-80;
}
.ec-zagreb-content .btn {
  @apply text-xl font-primary uppercase font-medium;
  background-color: #1c2b59;
  color: white;
}

.stands-with-israel-navigation {
  @apply relative;
}
.stands-with-israel-navigation .mobile-dropdown-navigation-content {
  @apply hidden absolute z-10;
  background-color: #1C2B59;
  transform-origin: top center;
  animation: slideDown 300ms ease-in-out forwards;
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.stands-with-israel-navigation .mobile-dropdown-navigation-content.show {
  @apply flex flex-col w-full;
}

.ec-jerusalem-bg-color {
  background-color: #04223F;
}

.ec-jerusalem-bg-image {
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
}

.ec-jerusalem-bg-star-image {
  background-repeat: no-repeat;
  background-position-x: 95%;
}

.ec-jerusalem-docs {
  background-color: #F2F5F8;
}

.download-pdf-docs {
  position: absolute;
  @apply top-0 left-0 w-full h-full;
  @apply opacity-0;
  @apply transition-opacity;
  @apply flex items-center justify-center;
  @apply bg-black bg-opacity-40;
  @apply text-white;
}
.download-pdf-docs svg {
  @apply transform scale-0;
  @apply transition-transform;
}
.download-pdf-docs:hover {
  @apply opacity-100;
}
.download-pdf-docs:hover svg {
  @apply scale-100;
}

.doc-description-text {
  @apply font-suisse-intl text-base font-semi-medium leading-6;
  color: rgba(8, 40, 79, 0.7);
}

.hezbollah-guide .table-facts-aside-nav li > a {
  @apply text-black text-opacity-70 font-semi-medium;
}
.hezbollah-guide .table-facts-aside-nav li > a:hover {
  @apply text-black text-opacity-100 no-underline font-bold;
}
.hezbollah-guide .table-facts-aside-nav li.active {
  @apply border-solid border-l-4;
  border-color: #999900;
}
.hezbollah-guide .table-facts-aside-nav li.active > a {
  @apply text-black text-opacity-100 font-bold;
}
.hezbollah-guide .table-facts-aside-nav li:hover {
  @apply text-black text-opacity-100;
}
.hezbollah-guide.arrow-hover-active-state.table-facts svg {
  @apply hidden pl-0 pr-0;
}

.hezbollah-guide-body p {
  @apply text-base text-black font-semi-medium leading-8;
}
@screen md {
  .hezbollah-guide-body p {
    @apply text-lg;
  }
}
.hezbollah-guide-body img {
  @apply my-8 w-full;
}
.hezbollah-guide-body blockquote {
  @apply font-semi-medium leading-casual my-8 text-black text-xl not-italic pl-6 border-l-4 border-solid border-black;
}
@screen md {
  .hezbollah-guide-body blockquote {
    @apply text-2xl;
  }
}
.hezbollah-guide-body ol, .hezbollah-guide-body ul {
  @apply my-8;
}
.hezbollah-guide-body ol li, .hezbollah-guide-body ul li {
  @apply text-black font-semi-medium text-base leading-8;
}
@screen md {
  .hezbollah-guide-body ol li, .hezbollah-guide-body ul li {
    @apply text-lg;
  }
}

.elevate-representatives .elevate-representatives-header {
  @apply font-600 text-white text-2xl font-suisse-intl;
}
@screen lg {
  .elevate-representatives .elevate-representatives-header {
    font-size: 72px;
  }
}
.elevate-representatives .elevate-representatives-subtitle {
  @apply font-450 text-white text-lg font-suisse-works leading-8 text-opacity-80 mt-10;
}
.elevate-representatives .elevate-representatives-subtitle p {
  @apply mt-4;
}

.representatives-bg-image {
  background-size: 45%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100px;
}

.hezbollah-hero-title {
  font-size: 60px;
}
@screen xl {
  .hezbollah-hero-title {
    font-size: 120px;
  }
}
@screen lg {
  .hezbollah-hero-title {
    font-size: 100px;
  }
}
@screen md {
  .hezbollah-hero-title {
    font-size: 80px;
  }
}

.hezbollah-form-submit p {
  @apply text-black text-opacity-70 font-semi-medium text-base;
}
.hezbollah-form-submit .forms-form {
  padding: 0px;
  margin-bottom: 0px;
}
.hezbollah-form-submit .forms-field-wrap {
  position: relative;
}
.hezbollah-form-submit .forms-form input[type=submit] {
  margin-top: 0px;
  background-color: #295ba5;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: absolute;
  right: 0;
  bottom: 24px;
}
.hezbollah-form-submit .forms-form .forms-field-checkbox {
  margin-top: 1rem;
}

.hezbollah-form-submit .forms-form input[type=submit] {
  position: relative;
}
@screen md {
  .hezbollah-form-submit .forms-form input[type=submit] {
    position: absolute;
  }
}

.background-section-image-bg {
  background-image: url("../images/hezbollah-bg-section.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.expansion-section-image-bg {
  background-image: url("../images/hezbollah-bg-section-3.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.targeting-section-image-bg {
  background-image: url("../images/hezbollah-bg-map.svg");
  background-position-y: 6%;
  background-size: contain;
  background-repeat: no-repeat;
}

.grid-entry .entry-video-odd {
  @apply hidden;
}
.grid-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .grid-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen lg {
  .grid-entry .entry-summary {
    @apply col-span-7 col-start-7;
  }
}
@screen xl {
  .grid-entry .entry-summary {
    @apply col-span-6 col-start-6;
  }
}

.hezbollah-background-section .grid-entry.-reverse-entry .entry-video-even {
  display: block !important;
}
.hezbollah-background-section .grid-entry.-reverse-entry .entry-video-odd {
  @apply hidden;
}
.hezbollah-background-section .grid-entry.-reverse-entry .entry-summary {
  @apply col-span-12;
}
@screen md {
  .hezbollah-background-section .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-6 col-start-7;
  }
}
@screen lg {
  .hezbollah-background-section .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-7 col-start-7;
  }
}
@screen xl {
  .hezbollah-background-section .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-5 col-start-8;
  }
}
@screen md {
  .hezbollah-background-section .grid-entry .entry-video-even {
    @apply hidden;
  }
}
.hezbollah-background-section .grid-entry .entry-video-odd {
  @apply hidden;
}
@screen md {
  .hezbollah-background-section .grid-entry .entry-video-odd {
    @apply block;
  }
}
@screen md {
  .hezbollah-background-section .grid-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen lg {
  .hezbollah-background-section .grid-entry .entry-summary {
    @apply col-span-6 col-start-1;
  }
}
@screen xl {
  .hezbollah-background-section .grid-entry .entry-summary {
    @apply col-span-5 col-start-1;
  }
}

.hezbollah-targeting-section .grid-entry {
  background: linear-gradient(#fff, #fff) left/2px 100% no-repeat;
  grid-gap: 16px;
  margin: 5px;
}
@screen lg {
  .hezbollah-targeting-section .grid-entry {
    background: linear-gradient(#fff, #fff) center/2px 100% no-repeat;
  }
}
.hezbollah-targeting-section .grid-entry .entry-summary {
  @apply col-span-2 relative;
  background: transparent;
  position: relative;
  padding-left: 3rem;
  padding-bottom: 1rem;
}
@screen lg {
  .hezbollah-targeting-section .grid-entry .entry-summary {
    @apply col-span-1 col-start-2;
    padding-left: 98px;
    padding-bottom: 0;
  }
}
.hezbollah-targeting-section .grid-entry .entry-summary::after {
  content: "";
  background-image: url("../../assets/images/youth_assembly/white-dot.svg");
  width: 16px;
  height: 16px;
  left: -7px;
  top: -1.5%;
  position: absolute;
}
@screen lg {
  .hezbollah-targeting-section .grid-entry .entry-summary::after {
    right: 100%;
    left: auto;
  }
}
@screen lg {
  .hezbollah-targeting-section .grid-entry.-reverse-entry .entry-summary {
    @apply col-span-1 col-start-1 pr-24 pl-0;
  }
}
@screen lg {
  .hezbollah-targeting-section .grid-entry.-reverse-entry .entry-summary::after {
    content: "";
    background-image: url("../../assets/images/youth_assembly/white-dot.svg");
    width: 16px;
    height: 16px;
    right: -16px;
    top: -1%;
    position: absolute;
  }
}

.universities h3,
.universities h4,
.universities h5 {
  line-height: 40px;
  font-weight: 600;
  color: #08284f;
}
.universities h4,
.universities h5 {
  margin-bottom: 8px;
}
.universities p {
  margin-bottom: 32px;
  @apply font-suisse-works;
}

.university-main-img {
  max-width: 80px;
}
@screen sm {
  .university-main-img {
    max-width: 120px;
  }
}

.uni-side-img {
  width: 48px;
  height: 48px;
}

@screen lg {
  .fellow-box {
    width: 264px;
  }
}

.life-on-campus-navigation {
  @apply relative;
}
.life-on-campus-navigation .mobile-dropdown-navigation-content {
  @apply hidden absolute z-10;
  background-color: #f7f7f7;
  transform-origin: top center;
  animation: slideDown 300ms ease-in-out forwards;
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.life-on-campus-navigation .mobile-dropdown-navigation-content.show {
  @apply flex flex-col w-full;
}

.hamas-101-new-page .hamas-hero-title {
  font-size: 60px;
}
@screen xl {
  .hamas-101-new-page .hamas-hero-title {
    font-size: 120px;
  }
}
@screen lg {
  .hamas-101-new-page .hamas-hero-title {
    font-size: 100px;
  }
}
@screen md {
  .hamas-101-new-page .hamas-hero-title {
    font-size: 80px;
  }
}
@screen lg {
  .hamas-101-new-page .october-7th-video-size .embedded-video, .hamas-101-new-page .october-7th-video-size .un_antisemitism .stream-section-content figure, .un_antisemitism .stream-section-content .hamas-101-new-page .october-7th-video-size figure, .hamas-101-new-page .october-7th-video-size .women_in_contemporary_judaism .pretty-content figure, .women_in_contemporary_judaism .pretty-content .hamas-101-new-page .october-7th-video-size figure, .hamas-101-new-page .october-7th-video-size .embed-code-online-center {
    padding-bottom: 160%;
  }
}
.hamas-101-new-page .hamas-form-submit p {
  @apply text-black text-opacity-70 font-semi-medium text-base;
}
.hamas-101-new-page .hamas-form-submit .forms-form {
  padding: 0px;
  margin-bottom: 0px;
}
.hamas-101-new-page .hamas-form-submit .forms-field-wrap {
  position: relative;
}
.hamas-101-new-page .hamas-form-submit .forms-form input[type=submit] {
  margin-top: 0px;
  background-color: #295ba5;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: absolute;
  right: 0;
  bottom: 24px;
}
.hamas-101-new-page .hamas-form-submit .forms-form .forms-field-checkbox {
  margin-top: 1rem;
}
.hamas-101-new-page .hamas-page-spacing {
  margin-bottom: 0px;
}
@screen lg {
  .hamas-101-new-page .hamas-page-spacing {
    margin-bottom: 108px;
  }
}
.hamas-101-new-page .hamas-top-part-section {
  @apply relative pt-40 pb-10 overflow-hidden;
  @apply bg-cover bg-center;
}
.hamas-101-new-page .about-section-image-bg {
  background-image: url("../images/hamas-about-section2.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.hamas-101-new-page .in-their-own-words-image-bg {
  background-image: url("../images/in-their-words-2.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.hamas-101-new-page .learn-more-page-title {
  width: 213px;
  height: 150px;
}
@screen md {
  .hamas-101-new-page .learn-more-page-title {
    width: 313px;
    height: 213px;
  }
}
@screen lg {
  .hamas-101-new-page .learn-more-page-title {
    width: 458px;
    height: 313px;
  }
}
@screen xl {
  .hamas-101-new-page .learn-more-page-title {
    width: 558px;
    height: 313px;
  }
}

.diplomacy-2024-logo img {
  height: 60px !important;
}

.resize-youtube-video iframe {
  @apply w-full h-full;
}

.hover-tweak-elevate-honors:hover .member {
  color: #295BA5 !important;
}

.elevate-heading-dark-color {
  color: #00213F;
}

.interviews-bg-color {
  background-color: #F2F5F8;
}

.stream-description-custom-styles {
  @apply mx-auto;
  max-width: 568px;
}

.assembly-25 .assembly-25-logo {
  width: 250px;
}
@screen sm {
  .assembly-25 .assembly-25-logo {
    width: 300px;
  }
}
@screen lg {
  .assembly-25 .assembly-25-logo {
    width: 370px;
  }
}
.assembly-25 .positions-section {
  margin-top: -500px;
}
@screen sm {
  .assembly-25 .positions-section {
    margin-top: -450px;
  }
}
@screen xl {
  .assembly-25 .positions-section {
    margin-top: -500px;
  }
}
.assembly-25 .candidate-bio-section {
  margin-top: -50px;
}
@screen sm {
  .assembly-25 .candidate-bio-section {
    margin-top: -100px;
  }
}
.assembly-25 .bio-bg {
  background-size: 100% 700px;
  background-repeat: no-repeat;
}
@screen md {
  .assembly-25 .bio-bg {
    background-size: 100% 900px;
  }
}

.wjc-plenary-assembly-candidate-topics ul {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.wjc-plenary-assembly-candidate-topics ul button {
  @apply w-full;
  @apply text-base text-left text-white;
  @apply px-4 py-3;
  @apply flex justify-between items-center outline-none;
}
.wjc-plenary-assembly-candidate-topics ul button svg {
  width: 10%;
  @apply pr-3;
}
.wjc-plenary-assembly-candidate-topics ul button:hover {
  @apply underline;
}
.wjc-plenary-assembly-candidate-topics li {
  @apply border-l-4 border-transparent outline-none;
}
.wjc-plenary-assembly-candidate-topics li svg {
  @apply opacity-0;
}
.wjc-plenary-assembly-candidate-topics li.active {
  @apply border-l-4 border-white outline-none;
}
.wjc-plenary-assembly-candidate-topics li.active svg {
  @apply opacity-100;
}
.wjc-plenary-assembly-candidate-topics li.active button {
  @apply text-white font-bold no-underline;
}

.wjc-plenary-assembly-candidate-aside-title {
  @apply text-white w-full -top-16;
  background: #102956;
}

.wjc-plenary-assembly-candidate-link {
  @apply p-gutter-1/2;
  display: block;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.wjc-plenary-assembly-candidate-link:hover {
  @apply shadow-lg-hover bg-white;
  transform: translateY(-1rem);
}
.wjc-plenary-assembly-candidate-link:hover p {
  @apply text-primary;
}
.wjc-plenary-assembly-candidate-link:hover img {
  filter: grayscale(0);
}
.wjc-plenary-assembly-candidate-link img {
  transition: filter 0.3s ease-in-out;
  filter: grayscale(1);
  margin-bottom: 0.5rem;
}

@screen sm {
  .wjc-plenary-assembly-candidate-bio {
    min-height: 15rem;
  }
}

.has-state-fix {
  margin: 0 !important;
  padding: 0 !important;
}

.article-content.wjc-plenary-assembly-candidate-bio .block-button {
  @apply flex items-center my-8;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn {
  @apply rounded-7
        bg-transparent
        text-primary text-lg font-medium leading-none tracking-wide;
  display: inline-block;
  min-height: 48px;
  padding: 4px 28px;
  transition: all ease-in-out 200ms;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn:hover {
  text-decoration: none;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn:focus {
  @apply shadow-btn;
  outline: none;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn:not(:disabled) {
  cursor: pointer;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn:not(button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-secondary {
  @apply bg-secondary text-primary;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-secondary:hover {
  @apply bg-secondary-hover;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-secondary:focus {
  @apply shadow-btn-secondary;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-tertiary {
  @apply bg-blue text-white;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-tertiary:hover {
  @apply bg-blue-hover;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-tertiary:focus {
  @apply shadow-btn-tertiary;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-outline-primary {
  @apply border border-solid border-primary bg-transparent text-primary;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-outline-primary:hover {
  @apply bg-primary text-white;
}
.article-content.wjc-plenary-assembly-candidate-bio .block-button .btn-outline-primary:focus {
  @apply shadow-btn-primary;
}

.jdcorps-site .fb-frame > iframe {
  width: 100%;
  height: 350px;
}
@screen md {
  .jdcorps-site .fb-frame > iframe {
    width: 350px;
    height: 400px;
  }
}
@screen lg {
  .jdcorps-site .fb-frame > iframe {
    width: 470px;
    height: 500px;
  }
}