.text-image-block {
  @apply mb-8;

  @screen md {
    @apply grid grid-cols-2 gap-8;

    &.-left {
      .text-image-block-image-wrapper {
        order: -1;
      }
    }
  }

  @screen lg {
    @apply gap-12;
  }

  .text-image-block-text {
    @apply flex flex-col;
    @apply py-4;

    @screen lg {
      @apply py-0;
    }
  }

  .text-image-block-title {
    @apply text-primary font-bold;
    @apply text-xl;

    @screen lg {
      @apply text-2xl;
    }
  }

  .text-image-block-body {
    @apply text-gray-dark text-base;

    @screen lg {
      @apply text-lg;
    }
  }

  .text-image-link {
    @apply text-white mr-auto mt-4;

    width: max-content;
    text-decoration: none;
  }
}

.columns-block {
  @apply mb-4;

  &.-opaque {
    @apply bg-primary-a9;

    .columns-block-col {
      @apply px-4;
    }

    .columns-block-title,
    .columns-block-body {
      @apply text-white;
    }
  }

  @screen lg {
    @apply grid grid-cols-2 gap-4;
  }

  .columns-block-col {
    @apply py-4;
  }

  .columns-block-title {
    @apply text-2xl;
  }
}

.fancy-list-block {
  @apply mb-8;

  @screen md {
    @apply grid grid-cols-2 gap-4;
  }

  @screen lg {
    @apply gap-12;
  }

  .fancy-list-block-title {
    @apply my-auto;
    @apply text-primary font-bold;
    @apply text-xl;

    @screen lg {
      @apply text-2xl;
    }
  }

  .fancy-list-block-list {
    @apply pl-6 my-0;

    li + li {
      @apply mt-3;
    }
  }
}

.list-block {
  @apply pl-6;

  li + li {
    @apply mt-3;
  }
}

.iframe-block {
  &.wf-tune,
  .twitter-tweet.-rendered {
    @apply text-center;

    &.-max-16\% {
      @screen sm {
        @apply w-1/6;
      }
    }
    &.-max-25\% {
      @screen sm {
        @apply w-1/4;
      }
    }

    &.-max-33\% {
      @screen sm {
        @apply w-1/3;
      }
    }
    &.-max-50\% {
      @screen sm {
        @apply w-1/2;
      }
    }
    &.-max-66\% {
      @screen sm {
        @apply w-2/3;
      }
    }
    &.-max-100\% {
      @apply flex-col;
      @apply w-full;
    }

    &.-center {
      @apply mx-auto;
    }

    &.-rigth {
      margin-left: auto;
    }

    &.-wrap {
      @apply mt-4 mb-6;

      &.-left {
        @screen sm {
          @apply mr-4;
          @apply float-left;
        }
      }

      &.-rigth {
        @screen sm {
          @apply ml-4;
          @apply float-right;
        }
      }

      &.-twitter {
        @apply my-3;
      }
    }
  }
}

.iframe-block-caption {
  @apply font-bold;
  @apply mt-2;
}

.delimiter-block {
  @apply my-8;
  @apply border-t border-gray-300;
}

.facebook-video-block {
  margin: 1rem 0;
}

.facebook-video-block-caption {
  @apply font-bold;
  @apply mt-4;
}

.boilerplate-block {
  @apply p-8;
  @apply relative;
  background-color: #f7f7f7;

  .boilerplate-block-title {
    @apply text-lg text-primary font-bold;
    @apply mb-8;
  }

  .boilerplate-block-contact-title {
    @apply text-base text-primary;
    @apply mb-1;

    @screen lg {
      @apply w-3/4;
    }
  }

  .boilerplate-block-body {
    @apply mb-6;

    @screen lg {
      @apply mb-0;
    }
  }

  .boilerplate-block-body,
  .boilerplate-block-contact-body {
    a {
      color: #0257d5;
    }
  }

  &.-one-column {
    .boilerplate-block-columns {
      @screen lg {
        @apply grid-cols-1 gap-0;
      }
    }
  }
}

.boilerplate-block-logo {
  @apply mb-6;

  @screen lg {
    @apply absolute right-0 top-0;
    @apply m-8;
  }
}

.boilerplate-block-columns {
  @apply flex flex-col;

  @screen lg {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

.boilerplate-block-socials {
  @apply pt-6 flex;

  a + a {
    @apply ml-6;
  }
}

.image-block {
  @apply text-center;

  .image-block-caption {
    @apply font-normal text-sm text-gray;
    @apply text-center;
    @apply italic;
    @apply mt-1;
  }

  &.-max-16\% {
    @screen sm {
      @apply flex-col;
      @apply w-1/6;
    }
  }
  &.-max-25\% {
    @screen sm {
      @apply flex-col;
      @apply w-1/4;
    }
  }

  &.-max-33\% {
    @screen sm {
      @apply flex-col;
      @apply w-1/3;
    }
  }
  &.-max-50\% {
    @screen sm {
      @apply flex-col;
      @apply w-1/2;
    }
  }
  &.-max-66\% {
    @screen sm {
      @apply flex-col;
      @apply w-2/3;
    }
  }
  &.-max-100\% {
    @apply flex-col;
    @apply w-full;
  }

  &.-center {
    @apply mx-auto;
  }

  &.-right {
    margin-left: auto;
  }

  &.-wrap {
    @apply mt-4 mb-6;

    &.-left {
      @screen sm {
        @apply mr-4;
        @apply float-left;
      }
    }

    &.-right {
      @screen sm {
        @apply ml-4;
        @apply float-right;
      }
    }
  }
}

.raw-block,
.article-content .raw-block {
  iframe {
    display: block;
    margin: 0 auto;
  }
}

.block-button {
  @apply flex items-center my-4;

  .btn {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .btn-tertiary {
    @apply text-white;
  }
}

.no-link-style {
  text-decoration: none !important;
}

.gallery-block-photo {
  @apply transition-all duration-150;
  @apply border-4 border-transparent;

  &:hover {
    @apply z-10;
    @apply transform scale-110;
    @apply border-white;
    @apply rounded;
    @apply shadow-md;
  }
}

.gallery-block-photo-link {
  @apply block w-full;

  img {
    @apply w-full;
  }
}

.gallery-block-caption {
  display: block;
  margin-top: 4px;
}

.newsletter-block {
  @apply py-8 rounded-sm bg-primary shadow-lg text-white text-center;
  @apply flex flex-col;
  @apply px-3 my-10;
  @apply rounded;

  @media screen and (min-width: 340px) {
    @apply px-6;
  }

  @screen lg {
    @apply grid grid-cols-10 gap-x-8 items-center;
  }

  @screen xl {
    @apply px-12;
  }

  position: relative;
  z-index: 1;
  background-image: url('../images/banner_bg.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .newsletter-block-about-text {
    @screen lg {
      @apply col-span-5 text-left;
    }

    @screen xl {
      @apply col-span-6 col-start-1;
    }

    h4 {
      @apply text-white text-xl;

      @screen lg {
        @apply text-2xl;
      }
    }

    p {
      @apply text-white text-lg font-medium mb-0;
    }
  }

  .newsletter-block-form-wrapper {
    @apply mt-6 mx-auto;

    @screen lg {
      @apply col-span-5 text-left mt-0 mx-0;
    }

    @screen xl {
      @apply col-span-5 col-start-7;
    }
  }

  .newsletter-form {
    @apply border-light rounded-7 bg-light;

    display: flex;
    height: 54px;
    transition: all;
    border-width: 3px;

    @screen md {
      width: 100%;
    }

    &:focus-within {
      @apply border-white bg-white shadow-input-white;
    }

    .newsletter-email {
      @apply rounded-7 bg-light text-gray-dark text-base font-medium;
      @apply border-none;

      width: 100%;
      min-width: 0;
      height: 48px;
      margin-bottom: 1rem;
      padding: 0 1.125rem;
      transition: all;
      outline: none;

      @screen sm {
        height: 100%;
        margin-bottom: 0;
        padding-right: 0.5rem;
        padding-left: 1.375rem;
      }

      @screen lg {
        padding-left: 1.75rem;
      }

      &:focus {
        @apply bg-white shadow-input-white;
        @apply outline-none;

        @screen sm {
          @apply shadow-none;
        }
      }
    }

    .btn {
      @apply px-2 text-white rounded-xl text-base uppercase whitespace-nowrap bg-dark-blue;

      @media screen and (min-width: 375px) {
        @apply text-sm;
      }

      @screen sm {
        @apply rounded-xl text-lg;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }

  &.wf-tune {
    @apply flex flex-col px-5 py-8;

    background-image: url('../images/side_banner_bg.svg');

    .newsletter-form {
      @apply flex-col mx-auto bg-transparent border-transparent;

      .newsletter-email {
        @apply mb-2;
      }

      .btn,
      .newsletter-email {
        @apply text-center;

        min-height: 48px;
      }

      .btn {
        @apply text-primary bg-very-light-blue;
        @apply font-primary text-lg font-bold;
      }

      &:focus-within {
        @apply border-transparent bg-transparent shadow-input-white;
      }
    }

    .newsletter-block-form-wrapper {
      @apply w-full mt-6 mb-10;

      min-height: 80px;
    }

    .newsletter-block-about-text {
      @apply text-center;

      h4 {
        @apply text-white text-xl font-medium;
      }

      p {
        @apply text-white text-lg font-medium mb-0;
      }
    }

    &.-max-16\% {
      @screen sm {
        @apply w-1/6;
      }
    }
    &.-max-25\% {
      @screen sm {
        @apply w-1/4;
      }
    }

    &.-max-33\% {
      @screen sm {
        @apply w-1/3;
      }
    }
    &.-max-50\% {
      @screen sm {
        @apply w-1/2;
      }
    }
    &.-max-66\% {
      @screen sm {
        @apply w-2/3;
      }
    }
    &.-max-100\% {
      @apply flex-col;
      @apply w-full;
    }

    &.-center {
      @apply mx-auto;
    }

    &.-rigth {
      margin-left: auto;
    }

    &.-left {
      margin-right: auto;
    }

    &.-wrap {
      @apply mt-2 mb-2;

      &.-left {
        @screen sm {
          @apply mr-4;
          @apply float-left;
        }
      }

      &.-rigth {
        @screen sm {
          @apply ml-4;
          @apply float-right;
        }
      }
    }
  }
}

.banner-block {
  @apply my-8 p-6;
  @apply bg-cover bg-center;

  @screen md {
    @apply p-12;
  }
}

.footnote-block {
  .footnote-container {
    @apply flex text-base font-normal;
  }

  .footnote-text {
    @apply ml-1;
  }
}
.raw-block,
.nested-list-block {
  ul {
    list-style-type: disc !important;
  }

  ul > li > ul {
    list-style-type: circle !important;
  }

  ul > li > ul > li > ul {
    list-style-type: square !important;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    margin-bottom: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    padding-right: 0.6em;
  }

  li ol > li:before {
    content: counters(item, '.') ' ';
  }
}

.table-block {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin-bottom: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    padding-right: 0.6em;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }
}
